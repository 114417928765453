import { PRODUCT_VERTICAL_ID } from "../../promotion";
import { ImpressionedProduct } from "../analytics";
import { ProductVerticalCreditCard } from "../creditCard";
import { NullableString } from "../helpers";
import { ProductVerticalBase } from "./productVerticalBase";
import { QuickApplyProduct } from "./quickApply";

export enum PRODUCT_VERTICAL {
  LOAN = "Loan",
  CREDIT_CARD = "CreditCard",
  CAR_LOAN = "CarLoan",
  INSURANCE = "Insurance",
  MORTGAGE = "Mortgage",
  BANK_ACCOUNT = "BankAccounts",
  INVESTMENTS = "Investments",
}

export const PRODUCT_VERTICAL_TO_ID_MAPPING: Record<PRODUCT_VERTICAL, PRODUCT_VERTICAL_ID> = {
  [PRODUCT_VERTICAL.LOAN]: PRODUCT_VERTICAL_ID.LOAN,
  [PRODUCT_VERTICAL.CREDIT_CARD]: PRODUCT_VERTICAL_ID.CREDIT_CARD,
  [PRODUCT_VERTICAL.CAR_LOAN]: PRODUCT_VERTICAL_ID.CAR_LOAN,
  [PRODUCT_VERTICAL.INSURANCE]: PRODUCT_VERTICAL_ID.INSURANCE,
  [PRODUCT_VERTICAL.MORTGAGE]: PRODUCT_VERTICAL_ID.MORTGAGE,
  [PRODUCT_VERTICAL.BANK_ACCOUNT]: PRODUCT_VERTICAL_ID.BANK_ACCOUNT,
  [PRODUCT_VERTICAL.INVESTMENTS]: PRODUCT_VERTICAL_ID.INVESTMENTS,
} as const;

export const PRODUCT_VERTICAL_ID_MAP: Record<string, PRODUCT_VERTICAL> = {
  "20cac0eb-875b-45cd-86d7-ccad1139fa68": PRODUCT_VERTICAL.BANK_ACCOUNT,
  "7a881deb-bc3d-4f75-a58a-1546f5ea40fe+carloans": PRODUCT_VERTICAL.CAR_LOAN,
  "7ac89b33-529d-4898-9c8a-18a259be72c4": PRODUCT_VERTICAL.CREDIT_CARD,
  "3261ce48-34b2-4c92-a797-5fbd344f922f": PRODUCT_VERTICAL.INSURANCE,
  "544b471f-efe8-4d7c-9835-5b17c2fcef35": PRODUCT_VERTICAL.INVESTMENTS,
  "7a881deb-bc3d-4f75-a58a-1546f5ea40fe": PRODUCT_VERTICAL.LOAN,
  "ab5ee24e-3edc-4aae-99cf-fcba0bfb06ee": PRODUCT_VERTICAL.MORTGAGE,
} as const;

/**
 * Represents a product that can be applied for (whether via Quick Apply or normally).
 */
export interface ApplicableProduct extends Partial<QuickApplyProduct> {
  /**
   * Whether the product supports Quick Apply.
   */
  isQuickApply: boolean;

  productImpressionId: string;

  /**
   * Whether the member is preselected for the product.
   */
  isPreSelection: boolean;

  /**
   * The custom preselected URL.
   */
  preSelectedLink: NullableString;

  /**
   * The application URL.
   */
  websiteLink: NullableString;
}

/**
 * Represents a product that can be applied for, that does not have a single impressioned application URL.
 */
export type ApplyableProduct = Pick<ProductVerticalCreditCard, "id" | "websiteLink" | "dashboardLink"> &
  Pick<ProductVerticalBase, "productVerticalId">;

/**
 * Represents a product that can be applied for via Quick Apply, that does not have a single impressioned application URL.
 */
export type QuickApplyableProduct = ApplyableProduct &
  Pick<
    ProductVerticalCreditCard,
    "isPreSelection" | "preSelectedLink" | "isQuickApply" | "productReferenceNumber"
  >;

/**
 * Represents a product that can be applied for, with a single impressioned application URL.
 */
export type ImpressionedApplyableProduct = Pick<ProductVerticalCreditCard, "id"> &
  Pick<ProductVerticalBase, "productVerticalId"> & {
    /**
     * The product URL (website, dashboard or preselected), with the CID (original or modified) subbed in.
     */
    impressionedProductLink: NullableString;
  };

/**
 * Represents a product that can be applied for via Quick Apply, with a single impressioned application URL.
 */
export type ImpressionedQuickApplyableProduct = ImpressionedApplyableProduct &
  Pick<ProductVerticalCreditCard, "isQuickApply" | "productReferenceNumber"> & {
    productImpressionId: string;
  };
