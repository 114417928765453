import { useMemo } from "react";

import {
  AnalyticsEventProps,
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TFeatureAnalyticsAttributes,
} from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, DISPLAY_ORIENTATION } from "@bwll/bw-types";

const eventCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: DISPLAY_ORIENTATION.VERTICAL,
};

export const useProductTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      productViewed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(IMPRESSION_EVENT_NAMES.PRODUCT_VIEWED, customAttributes);
      },

      productClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(ON_LINK_CLICK_EVENT_NAMES.PRODUCT_CLICKED, customAttributes);
      },

      buttonClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(ON_LINK_CLICK_EVENT_NAMES.BUTTON_CLICKED, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
