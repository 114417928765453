export const alerts = {
  "maintenanceMode": {
    "title": "Temporairement indisponible",
    "message": "Borrowell effectue actuellement une maintenance planifiée. Veuillez réessayer plus tard.",
    "buttons": {
      "ok": "OK"
    }
  },
  "versionGuard": {
    "title": "Version non supportée",
    "message": "Désolé, cette version de l'application Borrowell n'est plus supportée. Veuillez mettre à jour la version la plus récente à partir de {{storeName}}",
    "buttons": {
      "confirm": "Mise à jour",
      "cancel": "Plus tard"
    }
  },
  "notificationPermissionError": {
    "title": "Les notifications sont bloquées",
    "message": "Veuillez autoriser les notifications dans les réglages de votre appareil.",
    "buttons": {
      "confirm": "Paramètres",
      "cancel": "Annuler"
    }
  },
  "notificationsEnabled": {
    "title": "Les notifications ont été activées",
    // Pending to sync this translation with the English version, it is still using the old text.
    "body": {
      "all":"Vous recevrez désormais des mises à jour sur les promotions et les pointages de crédit!",
      "transactional":"Vous recevrez désormais les mises à jour relatives au pointage de crédit!"
    }
  }
}
