import { COLORS } from "@bwll/bw-styles";
import { INoticeProps, ISmallNoticeProps } from "@bwll/mobile/src/components/notice/types";

import { NullableString } from "../helpers";

export enum Factor {
  AVERAGE_CREDIT_AGE = "AVERAGE_CREDIT_AGE",
  CREDIT_INQUIRIES = "CREDIT_INQUIRIES",
  CREDIT_UTILIZATION = "CREDIT_UTILIZATION",
  DEROGATORY_MARKS = "DEROGATORY_MARKS",
  MISSED_PAYMENTS = "MISSED_PAYMENTS",
  TOTAL_ACCOUNTS = "TOTAL_ACCOUNTS",
}

export enum AverageCreditAgeStatus {
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE",
}

export enum CreditInquiriesStatus {
  NONE = "NONE",
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE",
}

export enum CreditUtilizationStatus {
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE",
  TIP = "TIP",
}

export enum DerogatoryMarksStatus {
  NONE = "NONE",
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE",
}

export enum MissedPaymentsStatus {
  NONE = "NONE",
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
}

export enum TotalAccountsStatus {
  NO_CHANGE = "NO_CHANGE",
  INCREASE = "INCREASE",
  DECREASE = "DECREASE",
  INACTIVE = "INACTIVE",
}

type CreditFactorStatuses =
  | AverageCreditAgeStatus
  | CreditInquiriesStatus
  | CreditUtilizationStatus
  | DerogatoryMarksStatus
  | MissedPaymentsStatus
  | TotalAccountsStatus;

export enum DerogatoryMarksTypes {
  COLLECTION = "COLLECTION",
  BANKRUPTCY = "BANKRUPTCY",
  LEGAL_ITEM = "LEGAL_ITEM",
  MARKS = "MARKS",
}

export enum TotalAccountsTypes {
  REVOLVING_TRADE = "REVOLVING_TRADE",
  INSTALLMENT_LOAN = "INSTALLMENT_LOAN",
  MORTGAGE = "MORTGAGE",
  ACCOUNTS = "ACCOUNTS",
}

export enum MissedPaymentTypes {
  DEFERRED = "DEFERRED",
  // not in use yet
  MISSED = "MISSED",
}

export enum VisualScaleMark {
  NEEDS_WORK = "Needs work",
  FAIR = "Fair",
  GOOD = "Good",
}

export type IVisualScaleCaption = {
  [key in VisualScaleMark]: string;
};

type CreditFactorTypes = DerogatoryMarksTypes | TotalAccountsTypes | MissedPaymentTypes;

export type CreditFactor = {
  factor: Factor;
  total: number;
  update?: {
    status: CreditFactorStatuses;
    type?: CreditFactorTypes;
    amount: number;
    hasSeen: boolean;
  };
  uuid: string;
};

export interface ICreditFactors {
  creditFactors?: EnhancedCreditFactor[];
  impressionId: string;
}

export enum NoticeType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ALERT = "ALERT",
  NEUTRAL = "NEUTRAL",
  DEFAULT = "DEFAULT",
}

export enum EventColors {
  GREEN = "Green",
  GREY = "Grey",
  RED = "Red",
  YELLOW = "Yellow",
}

export const NOTICE_TYPE_TO_EVENT_COLOR = {
  [NoticeType.SUCCESS]: EventColors.GREEN,
  [NoticeType.WARNING]: EventColors.YELLOW,
  [NoticeType.ALERT]: EventColors.RED,
  [NoticeType.NEUTRAL]: EventColors.GREY,
  [NoticeType.DEFAULT]: EventColors.GREY,
};

export const NOTICE_TYPE_TO_COLOR = {
  [NoticeType.SUCCESS]: COLORS.TURQUOISE["050"],
  [NoticeType.WARNING]: COLORS.YELLOW["050"],
  [NoticeType.ALERT]: COLORS.RED["050"],
  [NoticeType.NEUTRAL]: COLORS.NEUTRAL.COOL["050"],
  [NoticeType.DEFAULT]: COLORS.NEUTRAL.COOL["050"],
};

export const NOTICE_TYPE_TO_ICON_COLOR = {
  [NoticeType.SUCCESS]: COLORS.TURQUOISE["900"],
  [NoticeType.WARNING]: COLORS.YELLOW["900"],
  [NoticeType.ALERT]: COLORS.RED["700"],
  [NoticeType.NEUTRAL]: COLORS.NEUTRAL.WARM["800"],
  [NoticeType.DEFAULT]: COLORS.NEUTRAL.WARM["800"],
};

export const NOTICE_TYPE_TO_ICON = {
  [NoticeType.SUCCESS]: "checkmark_circle",
  [NoticeType.WARNING]: "caution",
  [NoticeType.ALERT]: "alert_octagon",
  [NoticeType.NEUTRAL]: "info_hollow",
  [NoticeType.DEFAULT]: "info_hollow",
};

export type CreditFactorUpdateCard = {
  message: string | null;
  dashboardSummaryMessage: string | null;
  noticeType: NoticeType;
};

export type EnhancedCreditFactor = CreditFactor & {
  order: number;
  title: string;
  total: number;
  formattedTotal: number | string;
  tooltip: string;
  tooltipImpact: string;
  impact: string;
  updateCard: CreditFactorUpdateCard;
  mark: VisualScaleMark;
};

export interface ICreditUtilization {
  balance: number;
  creditLimit: number;
  tradeName: string;
  utilization: number;
}

export type ICreditUtilizationDetailsJson = {
  totalCreditUtilization: ICreditUtilization;
  tradesCreditUtilization: ICreditUtilization[];
};

export type ICreditUtilizationDetailsV2 = {
  totalCreditUtilization: ICreditUtilization;
};

export interface ICreditInquiry {
  name: string | null;
  dateReported: string;
}

export type ICreditInquiriesJson = {
  creditInquiries: ICreditInquiry[];
};

export interface ICreditUtilizationDetails extends ICreditUtilizationDetailsJson {
  accountSummary: ICreditFactorListItem[];
}

export enum NODE_TYPES {
  NOTICE = "notice",
  SMALL_NOTICE = "small-notice",
  PLACEHOLDER = "placeholder",
}

export interface INotice extends INoticeProps {
  nodeType: NODE_TYPES.NOTICE;
}

export interface ISmallNotice extends ISmallNoticeProps {
  nodeType: NODE_TYPES.SMALL_NOTICE;
}

export interface ICreditFactorListItem {
  label: string;
  value?: string;
  valueColor?: string;
  caption?: string;
  // Include any new children component type below.
  children?: (INotice | ISmallNotice)[];
}

export interface ICreditFactorList {
  title: string;
  list: ICreditFactorListItem[];
}

export interface CreditFactorsSummaryResponse {
  latestCreditReportDate: NullableString;
  comparisonCreditReportDate: NullableString;
  lastViewedCreditFactorsDate: NullableString;
  newFeaturesHasSeen: {
    accounts: boolean;
    averageCreditAge: boolean;
    creditInquiries: boolean;
    creditUtilization: boolean;
    derogatoryMarks: boolean;
    missedPayments: boolean;
  };
  creditFactors: Omit<CreditFactor, "uuid">[];
}

export const CreditScoreChangeDirection = {
  Up: "Up",
  Down: "Down",
  Neutral: "Neutral",
} as const;

export type CreditScoreChangeDirectionType = keyof typeof CreditScoreChangeDirection;

/**
 * Official Equifax credit score terms
 * https://www.equifax.com/personal/education/credit/score/articles/-/learn/credit-score-ranges/
 */
export const CreditScoreRange = {
  EXCELLENT: "EXCELLENT",
  VERY_GOOD: "VERY_GOOD",
  GOOD: "GOOD",
  FAIR: "FAIR",
  POOR: "POOR",
} as const;

export type CreditScoreRangeType = keyof typeof CreditScoreRange;

export enum CreditUpdateType {
  HEADS_UP = 0,
  RECOGNITION = 1,
  OTHER = 2,
}

export type CreditUpdate = {
  text: string;
  updateType: CreditUpdateType;
  imageUrl?: string;
};

export type CreditUpdatesResponse = {
  numberOfTips: number;
  updates: CreditUpdate[];
};
