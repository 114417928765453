import { useMemo } from "react";

import { REGION_IDS } from "@bwll/bw-types";

import { useIndividualClient } from "./useIndividualClient";

export type { PrimaryAddress } from "@bwll/bw-types/src/types/member";

export const usePrimaryAddress = () => {
  const { data, isLoading, isSuccess } = useIndividualClient();

  const isQuebecMember = useMemo(() => data?.primaryAddress?.regionId === REGION_IDS.QUEBEC, [data]);

  return {
    data: data?.primaryAddress,
    isLoading,
    isSuccess: isSuccess && !!data?.primaryAddress,
    isQuebecMember,
  };
};
