import { useMemo } from "react";

import { useGetQualifiedCreditBuildingProducts } from "@bwll/bw-hooks";
import { CreditBuildingProducts } from "@bwll/bw-types";

interface CreditBuildingProductsEligibilityStatusResult {
  isRentAdvantageEnabled: boolean;
  isCreditBuildingLoanEnabled: boolean;
}

export const useCreditBuildingProductsEligibility = (): CreditBuildingProductsEligibilityStatusResult => {
  const { data: creditBuildingProducts } = useGetQualifiedCreditBuildingProducts({ enabled: true });

  return useMemo(() => {
    const isRentAdvantageEnabled =
      creditBuildingProducts?.includes(CreditBuildingProducts.RentAdvantage) ?? false;
    const isCreditBuildingLoanEnabled =
      creditBuildingProducts?.includes(CreditBuildingProducts.CreditBuildingLoan) ?? false;

    return {
      isRentAdvantageEnabled,
      isCreditBuildingLoanEnabled,
    };
  }, [creditBuildingProducts]);
};
