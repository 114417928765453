import { LocaleKeys } from "../en";
import { accessibility } from "./accessibility";
import { alerts } from "./alerts";
import { appIntro } from "./appIntro";
import { cbl } from "./cbl";
import { components } from "./components";
import { creditCoach } from "./creditCoach";
import { creditFactors } from "./creditFactors";
import { creditReport } from "./creditReport";
import { dashboard } from "./dashboard";
import { eid } from "./eid";
import { enstream } from "./enstream";
import { errorScreen } from "./errorScreen";
import { errors } from "./errors";
import { login } from "./login";
import { marketplace } from "./marketplace";
import { misc } from "./misc";
import { months } from "./months";
import { navbar } from "./navbar";
import { noHit } from "./noHit";
import { preSelectedCard } from "./preSelected";
import { productCatalog } from "./productCatalog";
import { productVerticals } from "./productVerticals";
import { profile } from "./profile";
import { provinces } from "./provinces";
import { rentReporting } from "./rentReporting";
import { settings } from "./settings";
import { signup } from "./signup";
import { sponsoredCard } from "./sponsoredCard";
import { tabBar } from "./tabBar";
import { tooltips } from "./tooltips";

export const locale: LocaleKeys = {
  components,
  errorScreen,
  rentReporting,
  appIntro,
  signup,
  productCatalog,
  provinces,
  months,
  dashboard,
  creditFactors,
  cbl,
  settings,
  alerts,
  tooltips,
  enstream,
  eid,
  noHit,
  accessibility,
  sponsoredCard,
  preSelectedCard,
  errors,
  login,
  creditCoach,
  tabBar,
  productVerticals,
  creditReport,
  navbar,
  marketplace,
  profile,

  ...misc,
};
