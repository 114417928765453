export enum RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE {
  EDUCATIONAL_STEP = "RentReporting_education_step",
  EDUCATIONAL_STEP_VIEWED = "RentReporting_education_step_viewed",
  EDUCATIONAL_STEP_CLICKED = "RentReporting_education_step_clicked",
  ADDRESS_STEP_VIEWED = "RentReporting_rental_address_viewed",
  ADDRESS_ENTERED = "RentReporting_rental_address_entered",
  TERMS_VIEWED = "RentReporting_lease_terms_viewed",
  TERMS_ENTERED = "RentReporting_lease_terms_entered",
  SUMMARY_STEP_VIEWED = "RentReporting_order_summary_viewed",
  SUMMARY_STEP_CHECKOUT = "RentReporting_order_summary_checkout",
  TRANSACTION_METHOD_STEP_VIEWED = "RentReporting_rent_transaction_method_viewed",
  TRANSACTION_METHOD_BANK_CONNECT_SELECTED = "RentReporting_bank_connect_selected",
  TRANSACTION_METHOD_MANUAL_RENT_SELECTED = "RentReporting_manual_rent_selected",
  CHECKOUT_CONFIRMATION_STEP_VIEWED = "RentReporting_checkout_step_viewed",
  CHECKOUT_CONFIRMATION_STEP_CTA_CLICKED = "RentReporting_checkout_step_continue_clicked",

  // Bank Connection flow steps attributes
  BANK_CONNECT_STEP_VIEWED = "RentReporting_connect_banking_viewed",
  BANK_CONNECTION_SUCCESSFUL = "RentReporting_bank_account_connection_successful",
  BANK_CONNECT_STEP_ERROR = "RentReporting_connect_banking_error",
  SELECT_ACCOUNT = "RentReporting_select_account_selected",
  SELECT_ACCOUNT_ERROR = "RentReporting_select_account_error",
  TRANSACTIONS_VIEWED = "RentReporting_select_transactions_viewed",
  TRANSACTIONS_MX_RETURNED = "RentReporting_mx_transactions_returned",
  TRANSACTIONS_FILTER_RETURNED = "RentReporting_internal_filter_transaction_returned",
  BANK_SELECT_TRANSACTIONS_ERROR = "RentReporting_select_transactions_error",
  TRANSACTIONS_SELECTED = "RentReporting_select_transactions_selected",
  TRANSACTION_SELECT = "RentReporting_select_transaction_step",

  // Deprecated step attributes - currently used on mobile
  SUMMARY_CHECKOUT = "RentReporting Order Summary Checkout",
  SUMMARY_VIEWED = "RentReporting Order Summary viewed",
  TRANSACTION_METHOD_VIEWED = "RentReporting Select Rent Transaction Viewed",
  TRANSACTION_METHOD_CURRENT_STEP_NAME = "RentReporting Select Rent Transaction Step",
  TRANSACTION_METHOD_CTA_CURRENT_STEP_NAME = "RentReporting Connect Banking Step",

  AMOUNT = "amount",
  CHECKOUT = "checkout",
  CHANGE_BANK = "Change bank account",
  CONTINUE = "continue",
  DATE_REPORTED = "dateReported",
  GET_STARTED = "get_started",
  GO_TO_DASHBOARD = "Go_to_RentReporting_dashboard",
  ONBOARDING_FLOW = "Rent Advantage Onboarding",
  RA_EXTERNAL_FLOW = "ra_external_v1",
  RA_INTERNAL_FLOW = "ra_internal_v1",
  RA_NOT_ELIGIBLE = "ra_not_eligible",
  RENT_CONFIRMATION_FLOW = "Rent confirmation",
  RENT_REPORTING = "RentReporting",
  RENT_REPORTING_POPUP_CLICKED = "RentReporting_popup_Clicked",
  RENT_REPORTING_POPUP_DISMISSED = "RentReporting_popup_Dismissed",
  RENT_REPORTING_POPUP_VIEWED = "RentReporting_popup_viewed",
  REPORTING_STATUS = "reportingStatus",
  _REPORTING_STATUS = "reporting_Status",
  SELECT_ACCOUNT_CTA = "Select account",
  SELECT_TRANSACTION = "Select transaction",
  STEP_NUMBER_ZERO = 0,
  STEPS_VIEWED = "RentReporting_steps_viewed",
  TRANSACTION_METHOD_CTA = "Rent Advantage Manual Rent Onboarding",
}

export enum MANUAL_RENT_ONBOARDING_ANALYTICS_ATTRIBUTE {
  RENT_PROOF_STEP_VIEWED = "Manual_rent_choose_file_viewed",
  RENT_PROOF_CHOOSE_FILE_CLICKED = "Manual_rent_choose_file_clicked",
  RENT_PROOF_CHANGE_FILE_CLICKED = "Manual_rent_change_file_clicked",
  CHOOSE_FILE = "CHOOSE FILE",
  CHANGE_FILE = "CHANGE FILE",
  UPLOAD_FILE = "UPLOAD FILE",
  RENT_PROOF_CHECKBOX_CLICKED = "Manual_rent_choose_file_checkbox_checked",
  CHECKBOX_NAME = "Mark Proof of Payment",
  RENT_PROOF_UPLOAD = "Manual_rent_choose_file_upload_file_clicked",
  FILE_SIZE_LIMIT_EXCEEDED = "exceed the maximum size",
  WRONG_FILE_TYPE = "incorrect file type",
  UPLOAD_FAILED = "other errors",
  RENT_PROOF_CHECKBOX_NAME = "Mark Proof of Payment",
  RENT_PROOF_ERROR = "Manual_rent_upload_file_error",
  TRANSACTION_DETAILS_VIEWED = "Manual_rent_transaction_details_viewed",
  TRANSACTION_DETAILS_CONFIRM = "Manual_rent_transaction_details_entered",
  TRANSACTION_DETAILS_CONFIRM_CTA = "Confirm Transaction Details",
  TRANSACTION_COMMENTS_VIEWED = "Manual_rent_add_comments_viewed",
  TRANSACTION_COMMENTS_SUBMIT = "Manual_rent_add_comments_submitted",
  TRANSACTION_COMMENTS_CTA = "Submit Proof of Payment With Comments",
  MANUAL_BUTTON_NAME = "ADD MANUALLY",
  RA_MANUAL_ONBOARDING = "Rent Advantage Manual Onboarding",
}

export enum RENT_CONFIRMATION_ANALYTICS_EVENT_ATTRIBUTE {
  REVIEW_SCREEN_VIEWED = "rent_review_screen_viewed_after_transaction_selection",
  REVIEW_SCREEN_CLICKED = "rent_review_screen_clicked_after_transaction_selection",
  CANT_FIND_RENT = "cannot_find_rent_payment",
  CANT_FIND_RENT_SELECTED = "cannot_find_rent_payment_next_step_selected",
  SELECT_RENT_PAYMENT = "select_your_rent_payment",
  CONFIRM_RENT = "Confirm_rent",
  CHANGE_TRANSACTIONS = "Change",
  SELECT_RENT = "Select_rent",
  TRANSACTION_AMOUNT = "transaction_amount",
  CHANGE_BANK = "Change_bank",
  CONTACT_SUPPORT = "Contact_support",
  MANUALLY_UPLOAD = "manually_upload",
  VIEW_MORE_TRANSACTIONS = "View_more_transactions",
  V1 = "v1.0",
}

export enum MANUAL_RC_ANALYTICS_ATTRIBUTES {
  V1 = "v1.0",
  REQUEST_ID = "request_id",
  RENT_MONTH = "rent_month",
  RENT_YEAR = "rent_year",
  FLOW_NAME = "flow_name",
  IMPRESSION_ID = "Impression_Id",
  MANUALLY_UPLOAD = "manually_upload_selection",
  PROOF_UPLOADED = "proof_of_rent_payment_uploaded",
  CONFIRM_PROOF_SUBMISSION = "confirm_proof_of_payment_submission",
  INPUT_DETAILS = "input_transaction_details",
  SUBMIT_PROOF = "submit_proof_of_payment",
  SUCCESFULL_PROOF_SUBMISSION = "succesfull_proof_of_payment_submission",
  TRANSACTION_DATE = "transaction_date",
  TRANSACTION_AMOUNT = "transaction_amount",
  TRANSACTION_DESCRIPTION = "transaction_description",
  TRANSACTION_COMMENT = "comment",
}

export enum RENT_REPORTING_MX_ANALYTICS_EVENT_ATTRIBUTE {
  MX_BUTTON_NAME = "CONNECT MY BANK ACCOUNT",
  CONNECT_BANKING_STEP = "RentReporting Connect Banking Step",
  BACK_BUTTON_CALL_TO_ACTION = "Track to see if user has any issues with connecting their bank",
  BANK_SELECTED_CALL_TO_ACTION = "Bank_selected",
  CONTINUE_TO_SELECT_BANK = "Continue_to_Select_Bank",
  LOADED = "RentReporting Connect Banking Loaded Step",
  DISCLOSURE = "RentReporting Connect Banking Disclosure Step",
  SEARCH = "RentReporting Connect Banking Search Step",
  SELECTED_INSTITUTION = "RentReporting Connect Banking Institution Step",
  ENTER_CREDENTIALS = "RentReporting bank account credentials entered",
  CREDENTIALS_STEP = "RentReporting Connect Banking Credentials Step",
  VERIFY_CREDENTIALS = "Verify_credentials",
  VERIFY = "verify",
  SUBMIT_MFA = "submit_MFA",
  BACK_TO_SEARCH = "RentReporting Connect Banking Back to Search Step",
  MFA = "RentReporting Connect Banking Mfa Step",
  MFA_METHOD = "RentReporting Connect Banking Mfa Method Step",
  MFA_SUBMITTED = "RentReporting Connect Banking Mfa Submitted Step",
  MFA_LOADING = "RentReporting Connect Banking Mfa Loading Step",
  CONNECTION_SUCCESS = "RentReporting bank account connection successful",
  CONNECT_BANKING_SUCCESS = "RentReporting Connect Banking Success",
  PROCEED_TO_TRANSACTIONS = "RentReporting Connect Banking Proceed to transactions",
  BANKING_CONNECTED = "RentReporting Connect Banking Connected",
  CREDS_USERNAME = "RentAdvantage_username",
  CREDS_PASSWORD = "RentAdvantage_password",
  CREDS_MFA = "RentAdvantage_MFA",
  NAVIGATE_BACK_BUTTON = "Back",
  NAVIGATE_INSTITUTION_BUTTON = "Go_to_institution_website_clicked",
  TIMEOUT_ERROR = "timeout",
}

export enum FIRST_LOGIN_CAROUSEL_ANALYTICS_EVENT_NAME {
  FIRST_LOGIN_CAROUSEL_CARD_VIEWED = "Engagement Card Viewed",
  FIRST_LOGIN_CAROUSEL_CARD_CLICKED = "Engagement Card Clicked",
}

export enum RENT_REPORTING_ANALYTICS_EVENT_NAME {
  RENT_REPORTING_APPLICATION = "Rent Reporting Application",
  MX_CONNECTION_STEP = "MX Connection Step",
  MANUAL_RENT_ONBOARDING = "Manual Rent Onboarding",
  RENT_REPORTING_CARD_VIEWED = "Rent Reporting Card Viewed",
  RENT_REPORTING_CARD_CLICKED = "Rent Reporting Card Clicked",
  MX_ERROR_ENCOUNTERED = "MX Error Encountered",
  RENT_CONFIRMATION_FLOW = "Rent Confirmation Flow",
  MANUAL_RENT_CONFIRMATION_FLOW = "Manual Rent Confirmation Flow",
  MANUAL_RENT_TRANSACTION_DETAILS = "Manual Rent Transaction Details",
  VALIDATION_ERROR_SHOWN = "Validation Error Shown",
}

export enum EXTERNAL_FLOW_RENT_REPORTING_ANALYTICS_EVENT_ATTRIBUTE {
  RA_ELIGIBILITY_EVENT_ACTION = "RA_Pageview_External",
  RA_ELIGIBILITY_EVENT_LABEL = "RA_User_Eligibility_External",
  RA_ELIGIBILITY_EVENT_CATEGORY = "RA_User_Eligibility_Pageview_External",
  RA_USER_ACQUIRED_EVENT_ACTION = "RA_Pageview_External",
  RA_USER_ACQUIRED_EVENT_LABEL = "RA_New_User_Acquired_External",
  RA_USER_ACQUIRED_EVENT_CATEGORY = "RA_New_User_Acquired_Pageview_External",
}
