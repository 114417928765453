import axios from "axios";
import { useQuery } from "react-query";
import { v5 as uuid } from "uuid";

import { useEnvironmentContext } from "@bwll/bw-hooks";
import { useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { IApiHookOptions, MemberData, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

type MemberResponse = MemberData & {
  persistenceKey: string;
};

export const fetchMember = async (
  accessToken: string,
  apiGatewayUrl: string,
  sessionNamespace: string,
): Promise<MemberResponse> => {
  const response = await axios.get<MemberData>(`${apiGatewayUrl}/public/Member`, {
    headers: generateApiHeaders(accessToken),
  });

  return {
    ...response.data,
    persistenceKey: uuid(response.data.creditScoreRequest.individualClientId, sessionNamespace),
  };
};

export const useGetMember = <TTransformReturn = MemberResponse>(
  options: IApiHookOptions<MemberResponse, TTransformReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  // We can assert on the values below because `enabled` will not allow the query
  // to run until they are defined.
  return useQuery(
    generateQueryKey(QUERY_KEYS.MEMBER_CALL, accessToken),
    () => fetchMember(accessToken as string, envConfigs.API_GATEWAY_URL, envConfigs.SESSION_NAMESPACE),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken,
      }),
    },
  );
};

export type Member = ReturnType<typeof useGetMember>;
