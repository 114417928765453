export const productList = {
  sortAndFilters: {
    sort: {
      title: "Sort",
      prefix: "Sort by:",
      approvalChance: "Approval chance (high to low)",
      shortApprovalChance: "Approval chance",
      fees: "Fees (low to high)",
      shortFees: "Fees",
      recommended: "Recommended",
      firstYearValue: "First year value (high to low)",
      shortFirstYearValue: "First year value",
      interestRate: "Interest Rate (low to high)",
      shortInterestRate: "Interest Rate",
      trending: "Trending",
      shortTrending: "Trending",
    },
    filters: {
      title: "Filters",
      bankAccounts: {
        chequing: "Chequing",
        savings: "Savings",
      },
      creditCards: {
        offers: {
          title: "Offers",
          topOffers: "Top Offers",
          trending: "Trending Offers",
        },
        benefits: {
          title: "Benefits",
          cashBack: "Cash Back",
          travel: "Travel",
          rewards: "Rewards",
        },
        features: {
          title: "Features",
          noFee: "No Fee",
          balanceTransfer: "Balance Transfer",
          creditBuilder: "Credit Builder",
        },
        network: {
          title: "Network",
          visa: "Visa",
          mastercard: "Mastercard",
          americanExpress: "American Express",
        },
      },
    },
  },
  adDisclosureLink: "Ad disclosure",
};
