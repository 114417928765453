export const onboarding = {
  steps: {
    landingPage: {
      title: "Build your credit",
      subtitle: "You can build consistent payment behaviour to increase your credit!",
      heading: "Start building credit!",
      description:
        "Credit Builder members who make on-time payments can **see an average credit score increase of 41 points** within 5 months.¹",
      howItWorks: "How it works",
      howDoesItWork: "How does it work?",
      buildCreditYourWay: "Build credit your way",
      whoBenefits: "Who benefits",
      pricingCard: {
        badge: {
          bestValue: "Best value",
          mostPopular: "Most popular",
        },
        month: "/mo",
        biWeekly: " biweekly",
        savings: "${{savings}} savings{{superscript}}",
        fees: "${{fees}} fees{{superscript}}",
        getBack: "Get back ${{totalSavings}}",
        afterMonths: `after 36 months`,
        subtitle: {
          starter: "Start building credit for the lowest price & develop savings",
          plus: "Report a larger credit amount & build more savings",
          pro: "Report the higher credit amount & grow the most savings",
        },
        subtitleBiWeekly: {
          starter: "Report a larger credit amount & build more savings",
          plus: "Report the higher credit amount & grow the most savings",
          pro: "Report the higher credit amount & grow the most savings",
        },
      },
      testimonials: {
        title: "From our members",
        description: "What people are saying about Credit Builder²",
        card1: {
          body: "“I downloaded Borrowell in 2018 and more recently signed-up for Credit Builder in May 2022 and have seen **my credit score increase by 154 points.** The sign-up process was incredibly easy. I did it while I was in bed! In my opinion, this is the easiest thing you can do to build your credit. I consider it to be a low cost investment in my future financial success.”",
          name: "Stephanie",
          location: "Edmonton, AB",
        },
        card2: {
          body: "“**I've been able to increase my credit score by 108 points** in 8 months with Borrowell's Credit Builder and credit factors! I highly recommend this product for anyone who is motivated to improve their score at a low cost and save money at the same time.”",
          name: "Erica",
          location: "Winnipeg, MB",
        },
      },
      introBulletPoint: {
        one: "Achieve your financial goals",
        two: "No hard credit check when you apply",
        three: "$10 monthly payment ($5 savings & $5 program fee for 24 months)¹",
        four: "You can cancel anytime without financial penalty",
      },
      howItWorksBulletPoint: {
        one: " Sign up for Credit Builder{{superscript}}",
        oneTestimonials: "Sign up for Credit Builder³",
        two: " Every month $10 will be automatically withdrawn from your bank account for as long as you’d like{{superscript}}",
        twoPricingTest:
          " Pay $10, $30 or ${{amountPro}} per month for 36 months, get your savings back at the end of the term{{superscript}}",
        twoFeeRestructure:
          " Pay ${{amountStarter}}, ${{amountPlus}} or ${{amountPro}} per month for 36 months, and get your savings back at the end of the term{{superscript}}",
        twoBiWeekly:
          " Pay ${{amountStarter}}, ${{amountPlus}} or ${{amountPro}} every two weeks for 36 months, get your savings back at the end of the term{{superscript}}",
        three: " We report your payment status to Equifax Canada",
        four: " Each on-time payment reported builds your credit history{{superscript}}",
        fourTestimonials: "Each on-time payment reported builds your credit history⁴",
      },
      whoBenefitsBulletPoint: {
        one: "Those who want to build their credit history",
        two: "Those who want to improve their credit to access more products & lower interest rates",
      },
      qualifyTermsMarkdownZeroA:
        "To qualify for Credit Builder you must be a Canadian Citizen or Permanent Resident. You must be the age of majority in your province/territory. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.",
      qualifyTermsMarkdownZeroB:
        "This program will report to Equifax Canada as a Borrowell installment loan of $240 with a 36 month term at a 0% interest rate (29.27% APR).",
      reportingTermsMarkdown:
        "We report all your monthly payments to Equifax Canada. On-time payments can help build credit history and improve your credit score, while missed payments can negatively impact your score.\n\nThis program will report to Equifax Canada as a Borrowell installment loan of ${{starterAmount}} “Starter” ({{aprStarter}}% APR), ${{plusAmount}} “Plus” ({{aprPlus}}% APR) or ${{proAmount}} “Pro” ({{aprPro}}% APR) with a 36-month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.",
      qualifyTermsMarkdownOne:
        "After your 24th payment, you can continue the program with no fees for another 12 months or you can cancel any time without penalty.",
      qualifyTermsMarkdownTwo:
        "We report all payments to Equifax Canada. If you miss a payment, this can negatively impact your score.",
      qualifyTermsMarkdownPricingTestOne:
        "Credit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the monthly payment consists of a program fee and savings that you'll get back. During months 25 to 36,  you’ll pay **$0 in fees** and you'll get back the entire monthly payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.\n\nThis program will report to Equifax Canada as a Borrowell installment loan of $240 “Starter” (29.27% APR), $840 “Plus” (17.84% APR) or ${{totalSavingsPro}} “Pro” ({{aprPro}}% APR) with a 36 month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.\n",
      qualifyTermsMarkdownPricingTestOneBiWeekly:
        "Credit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the biweekly payment consists of a program fee and savings that you'll get back. During months 25 to 36,  you’ll pay **$0 in fees** and you'll get back the entire monthly payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.\n\nThis program will report to Equifax Canada as a Borrowell installment loan of $240 “Starter” (29.27% APR), $840 “Plus” (17.84% APR) or ${{totalSavingsPro}} “Pro” ({{aprPro}}% APR) with a 36 month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.\n",
      qualifyTermsMarkdownPricingTestTwo:
        "To qualify for Credit Builder you must be a Canadian Citizen or a permanent resident and you must be the age of majority in your province or territory of residence. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.\n\nCredit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the monthly payment consists of a program fee and savings that you'll get back. During months 25 to 36,  you’ll pay **$0 in fees** and you'll get back the entire monthly payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.",
      qualifyTermsMarkdownPricingTestTwoBiWeekly:
        "To qualify for Credit Builder you must be a Canadian Citizen or a permanent resident and you must be the age of majority in your province or territory of residence. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.\n\nCredit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the biweekly payment consists of a program fee and savings that you'll get back. During months 25 to 36,  you’ll pay **$0 in fees** and you'll get back the entire monthly payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.",
      qualifyTermsMarkdownPricingTestOneAlt:
        "Based on Credit Builder members with an Equifax Canada ERS 2.0 credit score below 600 who signed up for a Credit Builder account after May 2022 and made on-time payments on their Credit Builder account. Individual results may vary. Other credit score factors, including late payments and activity with your other tradelines can impact your credit score.",
      qualifyTermsMarkdownTestimonialsTwo:
        "Testimonials were gathered from interviews with satisfied Borrowell Credit Builder members who received nominal compensation for their time. Credit scores are influenced by many factors, and your results may vary depending on your own circumstances.",
      qualifyTermsMarkdownPricingTestThree:
        "To qualify for Credit Builder you must be a Canadian Citizen or a permanent resident and you must be the age of majority in your province or territory of residence. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.",
      qualifyTermsMarkdownPricingTestThreeAlt:
        "To qualify for Credit Builder you must be a Canadian Citizen or a permanent resident and you must be the age of majority in your province or territory of residence. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.\n\nCredit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the monthly payment consists of a program fee and savings that you'll get back. During months 25 to 36,  you’ll pay **$0 in fees** and you'll get back the entire monthly payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.\n\nThis program will report to Equifax Canada as a Borrowell installment loan of $240 “Starter” (29.27% APR), $840 “Plus” (17.84% APR) or $1440 “Pro” (15.79% APR) with a 36 month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.",
      qualifyTermsMarkdownPricingTestFour:
        "We report all your monthly payments to Equifax Canada. On-time payments can help build credit history and improve your credit score, while missed payments can negatively impact your score.",
      buttons: {
        nextStep: "GET STARTED",
      },
      biWeekly: {
        first:
          "Based on a study of Borrowell members with an Equifax Canada score (ERS 2.0) under 600 when yhey signed up for Credit Builder after May 2022, made on-time payments on their Credit Builder and had a credit score increase. Individual results may vary. Other credit score factors, including late payments, activity with your other tradelines and credit utilization may impact results.",
        second:
          "To qualify for Credit Builder you must be a Canadian Citizen or a permanent resident and you must be the age of majority in your province or territory of residence. Credit Builder is not available in Saskatchewan, New Brunswick and Quebec.\n\nCredit Builder is not a traditional loan where you get cash upfront. During the first 24 months, the payment consists of a program fee and savings that you'll get back. During months 25 to 36, you’ll pay $0 in fees and you'll get back the entire payment as savings. We take a fee for reporting your payment to Equifax Canada. You can cancel at any time without a cancellation fee or financial penalty.",
        third:
          "We report all your monthly payments to Equifax Canada. On-time payments can help build credit history and improve your credit score, while missed payments can negatively impact your score.\n\nThis program will report to Equifax Canada as a Borrowell installment loan of ${{starterAmount}} “Starter” ({{aprStarter}}% APR), ${{plusAmount}} “Plus” ({{aprPlus}}% APR) or ${{proAmount}} “Pro” ({{aprPro}}% APR) with a 36 month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.",
      },
    },
    confirmPersonDetails: {
      title: "Review Personal Details",
      content:
        "Ensure your personal details match your government issued ID (e.g. passport, driver's license etc.). If you need to update your name & date of birth, please [contact support]().",
      fieldLabels: {
        fullName: "Full Name",
        dob: "Date of Birth (YYYY-MM-DD)",
        address: "Address",
        phone: "Phone Number",
        email: "Email Address",
      },
      terms:
        "By clicking “Confirm”, you are agreeing to a soft credit check with Equifax **which will not affect your credit score**. We will validate the above listed personal information to verify your identity.",
      buttons: {
        nextStep: "CONFIRM",
        cancel: "BACK",
      },
    },
    manualBankConnection: {
      title: "Provide your account details",
      subtitle: "You can find your details on your Pre-authorized debit form, void cheque or online banking",
      institutionNumber: {
        title: "Institution Number",
        subtitle: "3 digits identifying your bank",
        error: "Input the 3 digits identifying your bank",
      },
      transitNumber: {
        title: "Transit Number",
        subtitle: "5 digits identifying your bank branch",
        error: "Input the 5 digits identifying your bank branch",
      },
      accountNumber: {
        title: "Account Number",
        subtitle: "7-12 digits identifying your account",
        error: "Input the 7-12 digits identifying your account",
      },
      buttons: {
        nextStep: "CONFIRM",
        goBack: "BACK",
      },
      addManualBanking: {
        title: "Having issues connecting your bank?",
        subtitle: "[ADD MANUALLY]()",
      },
      confirmationPage: {
        title: "You're almost done!",
        titleNativePAD: "You're almost ready to start building your credit!",
        subtitle:
          "The last step is to confirm your bank agreement which allows us to withdraw your monthly payment.",
        subtitleNativePAD:
          "We're reviewing your banking account details and document. As soon as we're done, you'll be ready to start building your credit.",
        bulletOneTitle: "Check your inbox",
        bulletOneSubtitle: "You’ll receive an email from DocuSign within 1-2 business days.",
        bulletTwoTitle: "Sign your bank agreement",
        bulletThreeTitle: "Congratulations! You're one step closer to building your credit.",
        nextStep: "DONE",
      },
    },
    uploadBankDocument: {
      title: "Upload your bank document",
      subtitle:
        "Choose one of the following documents with your first and last name, financial institution number, transit (branch) number and account number.\n\n[Learn more about acceptable forms of bank documents]()",
      link: "",
      preAuthorizedDebit: {
        title: "Pre-Authorized Debit form",
      },
      voidCheque: {
        title: "Void cheque",
        subtitle: "We require a photo of the front of the cheque",
      },
      screenshot: {
        title: "Screenshot of online banking information",
        subtitle: "We cannot accept a photo of your screen or handwritten information",
      },
      buttons: {
        uploadFile: "UPLOAD FILE",
        confirm: "CONFIRM",
        checkboxLabel: "I confirm that I have read and agree to the terms of this [bank agreement]()",
      },
      uploadFooter: {
        title: "Max. file size: 7MB",
      },
      errors: {
        maxSize: "Your file exceeds the max size of 7MB",
        fileType: "Please upload a .png .jpg or .pdf only",
      },
      fileUploadHeader: {
        loading: "Uploading",
        selected: "File Uploaded",
      },
      fileUploadCard: {
        remove: "[REMOVE]()",
      },
    },
    summaryPage: {
      title: "Credit Builder Summary",
      subtitle: "Please review your Credit Builder details",
      monthlyPaymentTitle: "Monthly Payment Amount",
      yourSavingsTitle: "Your Savings",
      processingFeeTitle: "Program Fee",
      programDurationTitle: "Program Duration",
      programDuration: "{{amount}} months",
      choosePlanTitle: "1. Choose a Plan",
      monthlyPaymentDateTitle: "Monthly Payment Date",
      biWeeklyPaymentDateTitle: "Biweekly Payment Date",
      pricingTestMonthlyPayment: "2. Monthly Payment Date",
      pricingTestBiWeeklyPayment: "2. Biweekly Payment Date",
      recurringDateTitle: "Recurring Date",
      firstPaymentDateTitle: "First Payment Date",
      biWeeklyRecurringPayment: "Recurring payment every two weeks",
      pricingPlans: {
        monthly: {
          amount: "${{plan}}/month",
        },
        biWeekly: {
          amount: "${{plan}}/biweekly",
        },
        subtitle: "${{savings}} savings + ${{fees}} fees",
      },
      tooltips: {
        savings:
          "Whether you complete the term or you cancel early, you’ll receive the savings back into your bank account. You do not receive interest on the savings.",
        processingFee: "We take a fee for reporting your payment status to Equifax",
        pricingTest:
          "Whether you complete the term or you cancel early, you’ll receive the savings back into your bank account. You do not receive interest on the savings. We take a fee for reporting your payments status to the credit bureau",
      },
      cards: {
        tips: {
          title: "Keep in Mind",
          bullet1Monthly: "We recommend setting your monthly payment date to after you get paid.",
          bullet1BiWeekly: "We recommend setting your payment date to after you get paid.",
          bullet2: "Missing a payment can negatively impact your credit.",
          bullet3: "You can cancel at any time without a cancellation fee or financial penalty.",
        },
      },
      footNote:
        "This program will report to Equifax as a Borrowell installment loan of $240 with a 36 month term at a 0% interest rate (29.27% APR). We have a 0% interest rate because we charge a program fee, which is reflected in the APR.",
      footNotePricingTest:
        "This program will report to Equifax Canada as a Borrowell installment loan of ${{totalSavingsStarter}} “Starter” ({{aprStarter}}% APR), ${{totalSavingsPlus}} “Plus” ({{aprPlus}}% APR) or ${{totalSavingsPro}} “Pro” ({{aprPro}}% APR) with a 36 month term at a 0% interest rate. We have a 0% interest rate because we charge a program fee, which is reflected in the APR.",
      buttons: {
        nextStep: "CONFIRM",
        cancel: "BACK",
      },
    },
    agreement: {
      browserTitle: "Sign Agreement",
      browserTitlePad: "Review Debit Agreement",
      title: "Sign Agreement",
      subtitle: "Please review your account agreement and sign. You will receive an email on completion.",
      buttons: {
        nextStep: "SIGN WITH DOCUSIGN",
      },
    },
    connectBankAccount: {
      title: "Securely sign-in to your bank",
      subtitle: "Borrowell uses MX to connect your account",
      buttons: {
        connect: "CONNECT BANK",
      },
      browserTitle: "Connect Bank",
      contactUs: "If you have any issues connecting your bank, please [contact us]().",
    },
    signPAD: {
      title: "Review your bank agreement",
      subtitle: "This allows us to withdraw your monthly payment.",
      subtitleBiweekly: "This allows us to withdraw your payment.",
      subtitleAdhocPayment: "This allows us to withdraw your one-time payment.",
      buttons: {
        checkboxLabel: "I confirm that I have read and agree to the terms of this bank agreement",
        confirm: "CONFIRM",
      },
    },
    confirmNavigation: {
      furtherImprove: {
        part1: "Let's further improve your ",
        part2: "credit mix ",
        part3: "and ",
        part4: "payment history",
        part5: "!",
        creditMixTooltip: {
          title: "Credit mix",
          body: "The types of accounts that make up your credit report are called credit mix. Approximately 10% of your score is based on your credit mix. This may include credit cards, school loans, vehicle loans, and mortgages, among other sources of credit.",
        },
        paymentHistoryTooltip: {
          title: "Payment history",
          body: "Payment history refers to whether an individual pays their credit accounts on time. Approximately 35% of your score is based on payment history. Credit reports highlight any late or missing payments and reflect the payments made for each tradeline.",
        },
      },
      title: "Congratulations! You're one step closer to building your credit.",
      titleCrossSell: "Congratulations!",
      subtitle: "Look out for a new Borrowell trade line in about a month!",
      subtitleCrossSell: "Diversify your credit profile with a new tradeline now.",
      headerCrossSell:
        "You’ve added a Borrowell Credit Builder installment tradeline to your Equifax Canada credit file.",
      subheader:
        "Let's further improve your <tooltipl>credit mix<tooltipr>(Credit mix\nThe types of accounts that make up your credit report are called credit mix. Approximately 10% of your score is based on your credit mix. This may include credit cards, school loans, vehicle loans, and mortgages, among other sources of credit.)",
      subheader2:
        "and <tooltipl>payment history<tooltipr>(Payment history\nPayment history refers to whether an individual pays their credit accounts on time. Approximately 35% of your score is based on payment history. Credit reports highlight any late or missing payments and reflect the payments made for each tradeline.)!",
      buttons: {
        nextStep: "GO TO DASHBOARD",
      },
      crossSellCards: {
        openTradelineTooltip: {
          title: "Open tradeline",
          body: "An open tradeline is a type of credit account. A common open tradeline is a loan of a fixed amount. The amount of the loan is set at the time of approval and the amount you borrow won't change over time.",
        },
        revolvingTradelineTooltip: {
          title: "Revolving tradeline",
          body: "A revolving tradeline is a type of credit account. A common revolving tradeline is a credit card. You can continue to borrow as much as you like - as long as you stay under the credit limit. Repaying the borrowed amount makes that amount available to be borrowed again at a later time.",
        },
        chequingAccountTooltip: {
          title: "Chequing account",
          body: "A chequing account is a type of bank account, commonly used for everyday transactions. You can deposit and withdraw money as often as you like, and the funds in a chequing account are easily accessible for bill payments, purchases, and cash withdrawals.",
        },
        learnMore: "LEARN MORE",
        revolvingTooltip:
          "<tooltipl>Revolving tradeline<tooltipr>(Revolving tradeline\nRevolving tradeline is a type of loan issued with a credit limit that you can borrow against. With revolving tradeline, you can continue to borrow as much as you like-as long it stays under the limit. Repaying a borrowed amount makes that amount available to be borrowed again at a later time.)",
        openTooltip:
          "<tooltipl>Open tradeline<tooltipr>(Open tradeline\nOpen tradeline is a loan of a fixed amount, granted on the condition of its repayment over a clearly defined payment schedule. The value of the loan is set at the time of approval and the amount you borrow won’t change over time.)",
      },
    },
    notQualified: {
      title: "Sorry, your ID could not be verified",
      subtitle:
        "Unfortunately we were not able to verify your ID and cannot open a Credit Builder for you at this time. Please [contact us]() if you have any questions.",
      buttons: {
        nextStep: "CLOSE",
      },
    },
    somethingWentWrong: {
      title: "Sorry, there's been an issue",
      subtitle:
        "Unfortunately an error occurred and we cannot open a Credit Builder for you at this time. Please [contact us]() if you have any questions.",
      subtitleAdhocPayments:
        "We encountered an error while trying to schedule your one-time payment. Please email us at __[buildcredit@borrowell.com]()__ to schedule a one-time payment ",
      buttons: {
        nextStep: "CLOSE",
      },
    },
    maintenance: {
      title: `Sorry, we’re temporarily under maintenance. 🔧`,
      subtitle: "We will be back soon, check back tomorrow morning!",
      buttons: {
        nextStep: "CLOSE",
      },
    },
    addressRejection: {
      title: "Sorry, we detected an issue with your information",
      subtitle: "Unfortunately, we cannot sign you up to Credit Builder at this time",
      qualifications: {
        title: "**Who qualifies for the Credit Builder?**",
        subtitle: "For now, only some members who meet the full criteria below are eligible.",
        bullets: [
          { title: "Be a Canadian citizen or have permanent residency" },
          { title: "Age of majority in your province" },
          { title: "Have at least six months of credit history" },
          {
            title:
              "Have at least two tradelines (loan, line of credit, credit card, etc.) on their credit file with Equifax",
          },
          { title: "Lives in Canada, **excluding Quebec, Saskatchewan, New Brunswick**" },
        ],
      },
      footer: "Please, [contact us]() if you need to review your information",
      buttons: {
        nextStep: "CLOSE",
      },
    },
  },
  actionSheet: {
    uploadFileActionSheet: {
      buttons: {
        browseFiles: "Browse Files",
        browsePhotos: "Browse Photos",
        takePicture: "Take a Picture",
        cancel: "Cancel",
      },
    },
  },
};
