import { useMemo } from "react";

import { AnalyticsEventProps, TFeatureAnalyticsAttributes } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, DASHBOARD_ANALYTICS_ORIGINS } from "@bwll/bw-types";

const defaultCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: DASHBOARD_ANALYTICS_ORIGINS.SPONSORED_PLACEMENT,
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
};

export const useSponsoredCarouselTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      productViewed: <T>(eventLabel: string, eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...defaultCommonAttributes,
        };

        trackEvent(eventLabel, customAttributes);
      },

      productClicked: <T>(eventLabel: string, eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...defaultCommonAttributes,
        };

        trackEvent(eventLabel, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
