export enum SIGNUP_INTENT {
  PRE_MEMBER_DASHBOARD = "preMemberDashboard",
  CREDIT_BUILDER = "creditBuilder",
  RENT_ADVANTAGE = "rentAdvantage",
  FREE_CREDIT_SCORE = "freeCreditScore",
}

export const allowedIntents = {
  [SIGNUP_INTENT.PRE_MEMBER_DASHBOARD]: "preMemberDashboard",
  [SIGNUP_INTENT.CREDIT_BUILDER]: "creditBuilder",
  [SIGNUP_INTENT.RENT_ADVANTAGE]: "rentAdvantage",
  [SIGNUP_INTENT.FREE_CREDIT_SCORE]: "freeCreditScore",
};
