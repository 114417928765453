import { CHAT_ID_NONENGAGED_USERS } from "@bwll/bw-utils";

export const misc = {
  paymentProfile: {
    title: "Payment History",
    subtitle: "Showing payment history up to 36 months",
    caption: {
      paid: "Paid",
      missed: "Missed",
      noInfo: "No Information",
      deferred: "Deferred Payment",
    },
  },
  common: {
    maybeLater: "Maybe later",
    confirm: "Confirm",
    cancel: "Cancel",
    continue: "Continue",
    viewProduct: "View Product",
    back: "Back",
  },
  featureCard: {
    approvalText: "Approval odds: ",
    approvalChance: "HIGH",
    applyButton: "APPLY",
    detailsButton: "DETAILS",
  },
  chats: {
    [CHAT_ID_NONENGAGED_USERS]: [
      "Welcome back, {{firstName}}!",
      "Sit tight while we update your credit score.",
      "Credit tip: The path to great credit is a marathon - not a sprint! Allow yourself some time to reach your goals.",
    ],
  },
  commonAccessibility: {
    goBack: "Go Back",
  },
};
