import styled from "@emotion/native";

import { fontSize, lineHeight, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Body2 } from "../..";
import { StyledLink2 } from "../../atoms/Link/Link.styles";

export const listItemWrapperStyles = {
  display: "flex",
  flexDirection: "row",
  marginBottom: spacing.xxxs,
};

export const listItemBulletStyles = {
  fontSize: fontSize.xl,
  marginTop: spacing.xxxs / 2,
  marginLeft: spacing.xxs,
  marginRight: spacing.xs,
};

export const CustomLink2 = styled(StyledLink2)`
  height: ${styledValue(lineHeight.s)};
`;

export const SmallScriptWrapper = styled.View`
  display: inline-block;
  top: ${styledValue(-fontSize.s * 0.3)};
  margin-bottom: ${styledValue(fontSize.s * 0.3)};
`;

export const SmallScript = styled(Body2)`
  font-size: ${styledValue(fontSize.xs * 0.7)};
  line-height: ${styledValue(lineHeight.xs * 0.7)};
`;
