export const mfa = {
  protect: {
    title: "Protect your account with two-step verification",
    subtitle:
      "Add an extra layer of security to your account by using a one-time code in addition to your password when you log in.",
    buttons: {
      next: "ENABLE 2-STEP VERIFICATION",
      cancel: "MAYBE LATER",
    },
  },
  method: {
    title: "Select a verification method",
    errorMessage: "Please select a method to continue.",
    options: {
      smsTitle: "SMS text message",
      smsText: "Receive a SMS/text message with a one-time code when you log in.",
      totpTitle: "Authenticator app",
      totpText: "Use an app to generate a one-time code when you log in.",
    },
    buttons: {
      next: "CONTINUE",
    },
  },
  password: {
    title: "Enter your password to switch two-step verification method",
    disableTitle: "Enter your password to disable two-step verification",
    button: "SWITCH METHOD",
    disable: "DISABLE 2-STEP VERIFICATION",
    options: {
      forgotPassword: "I forgot my password",
    },
    errors: {
      emptyErrorMessage: "Please enter your password",
      invalidPassword: "Your password is incorrect",
    },
  },
  methodSetup: {
    sms: {
      title: "SMS verification",
      subtitle: "Enter the phone number we should send your verification code to.",
      resendError: "Please wait {{seconds}} seconds before requesting another code.",
    },
    totp: {
      title: "Authenticator app",
      subtitle:
        "To link your authenticator app to your Borrowell account, copy and paste this key to your authenticator app.",
      markdown:
        "You can use any authenticator app such as [Google Authenticator]() or [Microsoft Authenticator]().",
    },
    inputs: {
      phoneNumber: "Phone Number",
    },
    buttons: {
      next: "CONTINUE",
    },
    errorMessage: "Please, enter a valid phone number.",
  },
  confirmSetup: {
    sms: {
      title: "Check your text messages",
      subtitle: "Enter the one-time code we’ve sent to **{{phoneNumber}}**.",
      subtitleLogin: "Enter the one-time code we’ve sent to your phone number.",
      description: "Text messages may take 30 seconds to arrive.",
      changeNumber: "Change number",
    },
    totp: {
      title: "Check your authenticator app",
      subtitle: "Enter the six digit code generated by your authenticator app.",
    },
    caption: "Can’t get your verification code?",
    inputs: {
      verificationCode: "Verification Code",
    },
    buttons: {
      next: "CONTINUE",
      pasteFromClipboard: "PASTE FROM CLIPBOARD",
      resend: "RESEND CODE",
      recoveryCode: "Use recovery code",
      help: "Help",
    },
    errorMessage: "The code you entered is incorrect or expired.",
  },
  confirmation: {
    title: "You’ve successfully enabled two-step verification",
    buttons: {
      next: "DONE",
    },
  },
  recovery: {
    title: "Enter your recovery code",
    generateTitle: "We’ve generated a new recovery code for you",
    description:
      "Your recovery code is used in case you lose access to your device and can’t receive your code through SMS or your authenticator app. Each code can only be used once.",
    card: {
      title: "Using your recovery code will disable two-step verification.",
      subtitle: "We highly recommend re-enabling it again once you are signed in.",
    },
    inputs: {
      recoveryCode: "Recovery Code",
    },
    caption: "Can’t find your recovery code?",
    buttons: {
      next: "SUBMIT",
      done: "DONE",
      help: "Visit our Help Centre",
    },
    accordion: {
      title: "Where should I save my recovery code?",
      description: [
        "Make sure your recovery stays accessible even if you lose access to your device.",
        "You can save your recovery code in a cloud storage location such as Dropbox, iCloud or Google Drive.",
        "You can also use a password manager such as LastPass or 1Password",
      ],
    },
    errorMessage: "The code you entered is incorrect or expired.",
    emptyErrorMessage: "Please enter a recovery code",
  },
};
