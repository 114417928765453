import { Nullable, NullableString } from "../helpers";

export enum PROMOTION_TYPE {
  POP_UP = 1,
  SNACKBAR = 2,
  PROMOCARD = 3,
}

export enum PROMOTION_ACTION_ID {
  DISMISSED = 1,
  COMPLETED = 2,
}

export enum PROMO_CARD_TYPE {
  PRE_SELECTION = 2,
  GENERIC_PROMO_CARD = 6,
}

export enum PROMO_CARD_ROTATION_SLOT_NUMBER {
  SLOT_1 = 1,
  SLOT_2 = 2,
}

export enum POPUP_TYPE {
  CREDIT_BUILDING = 1,
  MARKETPLACE = 2,
}

export enum CREDIT_BUILDING_PV_KNACK_ID {
  CREDIT_BUILDER = 5,
  RENT_ADVANTAGE = 6,
  RENT_ADVANTAGE_PAST_PAYMENTS = 37,
}

export interface PromotionData {
  vertical: number;
  id: string;
  knackId: string | null;
  name: string;
  type: PROMOTION_TYPE;
  productReferenceId: string;
}

export interface PromotionPopup extends PromotionData {
  title: string;
  body: string;
  buttonText: string;
  image: string;
}

export interface PromotionSnackbar extends PromotionData {
  expandedTitle: string;
  expandedBody: string;
  expandedImage: string;
  experimentalExpandedImage?: string;
  expandedButtonText: string;
  collapsedTitle: string;
  collapsedButtonText: string;
}

export interface RecordPromotionActionRequest {
  key: string;
  type: PROMOTION_TYPE;
  popupActionId?: PROMOTION_ACTION_ID;
  productReferenceId?: string;
}

export type PopupVisibilityConfig = {
  isCblRaPopupSourceSwitchSelectorOn: boolean;
  isCblExperimentOn: boolean;
  isCBActive: boolean;
  shouldShowCblTab: boolean;
  isFrench: boolean;
  isRentAdvantagePopupCardOn: boolean;
  isRentAdvantageActive: boolean;
  isRASuccess: boolean;
  shouldShowRentReportingTab: boolean;
  isMktPlaceExperimentOn: boolean;
  isPromotionsSuccess: boolean;
  promotionData?: PromotionPopup;
};

export enum SessionStorageKeys {
  SnackbarShowExpanded = "snackbarShowExpanded",
  DashboardShowBadge = "dashboardShowBadge",
}

export enum SnackBarViewStatus {
  Expanded = "expanded",
  Collapsed = "collapsed",
}

export interface PromotedProduct {
  productId: string;
  productImpressionId: string;
  title: string;
  subtitle: string;
  description: string;
  partnerUrl: string;
  imageUrl: string;
  approvalChance: string;
  approvalChanceColor: string;
  annualFee: string;
  purchaseRate: string;
  cashAdvance: string;
  rank: number;
  likelihoodOfApprovalType: Nullable<number>;
  likelihoodOfApproval: Nullable<number>;
}
