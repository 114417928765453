import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  ImpressionedProductVerticalBankAccount,
  TrackEventFuncAsync,
} from "@bwll/bw-types";
import { mapBankAccountToProductEventProps, noop } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_details_page",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: 1,
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
} as const;

const undefinedBankAccountEvents = {
  trackProductViewed: noop,
  trackProductClicked: noop,
  trackProductExpanded: noop,
};

export const useBankAccountProductDetailsAnalytics = (
  trackEvent: TrackEventFuncAsync,
  bankAccount?: ImpressionedProductVerticalBankAccount,
) => {
  const { productViewed, productClicked, productExpanded } = useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(() => {
    if (!bankAccount) return undefinedBankAccountEvents;

    const productEventProps = mapBankAccountToProductEventProps(bankAccount);

    return {
      trackProductViewed: () =>
        productViewed({
          ...productEventProps,
          ...pageEventProps,
        }),
      trackProductClicked: () =>
        productClicked({
          ...productEventProps,
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
        }),
      trackProductExpanded: (isLegalDetailsExpanded: boolean) => {
        productExpanded({
          [ANALYTICS_ATTRIBUTES.PRODUCT_ID]: productEventProps.product_id,
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: productEventProps.impression_id,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: productEventProps.tracking_tag,
          [ANALYTICS_ATTRIBUTES.PLACEMENT]: pageEventProps.placement,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: pageEventProps.display_order,
          [ANALYTICS_ATTRIBUTES.CTA_TEXT]: productEventProps.call_to_action,
          [ANALYTICS_ATTRIBUTES.FEATURES_SHOWN]: true,
          [ANALYTICS_ATTRIBUTES.LEGAL_DETAILS_EXPANDED]: isLegalDetailsExpanded,
        });
      },
    };
  }, [bankAccount, productClicked, productExpanded, productViewed]);
};
