import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  CreditCardCategoryKey,
  ImpressionedProductVerticalCreditCard,
  MARKETPLACE_ANALYTICS_ORIGINS,
  TabView,
  TrackEventFuncAsync,
} from "@bwll/bw-types";
import {
  CreditCardFilterCategories,
  CreditCardFilterer,
  isTrendingProduct,
  mapCreditCardToProductEventProps,
} from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
} as const;

const filterEventProps = {
  [ANALYTICS_ATTRIBUTES.FILTER_LOCATION]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.VERTICAL]: "Credit Cards",
} as const;

// Tracking plan tracks 'filters' and providers separately
const getActiveFilters = (filters: CreditCardFilterer[]) =>
  filters.filter((f) => f.category !== CreditCardFilterCategories.Providers).map((f) => f.key);

const getActiveProviders = (filters: CreditCardFilterer[]) =>
  filters.filter((f) => f.category === CreditCardFilterCategories.Providers).map((f) => f.key);

export const useCreditCardProductListAnalytics = (
  trackEvent: TrackEventFuncAsync,
  categoryName: CreditCardCategoryKey,
) => {
  const { productViewed, productClicked, tabSwitched, filterSelected, filterButtonClicked, buttonClicked } =
    useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(
    () => ({
      trackProductViewed: (
        card: ImpressionedProductVerticalCreditCard,
        index: number,
        currentSortOption: string,
        selectedFilters: CreditCardFilterer[] = [],
      ) =>
        productViewed({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
          [ANALYTICS_ATTRIBUTES.SORT_BY_VALUE]: currentSortOption,
          [ANALYTICS_ATTRIBUTES.ACTIVE_FILTERS_SELECTED]: getActiveFilters(selectedFilters),
          [ANALYTICS_ATTRIBUTES.ACTIVE_PROVIDERS_SELECTED]: getActiveProviders(selectedFilters),
          [ANALYTICS_ATTRIBUTES.IS_TRENDING]: isTrendingProduct(card),
        }),
      trackProductClicked: (
        card: ImpressionedProductVerticalCreditCard,
        index: number,
        currentSortOption: string,
        selectedFilters: CreditCardFilterer[] = [],
      ) =>
        productClicked({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
          [ANALYTICS_ATTRIBUTES.SORT_BY_VALUE]: currentSortOption,
          [ANALYTICS_ATTRIBUTES.ACTIVE_FILTERS_SELECTED]: getActiveFilters(selectedFilters),
          [ANALYTICS_ATTRIBUTES.ACTIVE_PROVIDERS_SELECTED]: getActiveProviders(selectedFilters),
          [ANALYTICS_ATTRIBUTES.IS_TRENDING]: isTrendingProduct(card),
        }),
      trackButtonClicked: (card: ImpressionedProductVerticalCreditCard, destinationPath?: string) => {
        const { impression_id, tracking_tag } = mapCreditCardToProductEventProps(card);
        return buttonClicked({
          impression_id,
          tracking_tag,
          [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: "Details",
          [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: destinationPath ?? "",
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "Credit Cards",
          [ANALYTICS_ATTRIBUTES.BUTTON_CONTEXT]: MARKETPLACE_ANALYTICS_ORIGINS.CREDIT_CARDS_DETAILS,
        });
      },
      trackTabSwitched: (
        card: ImpressionedProductVerticalCreditCard,
        index: number,
        latestTabViewed: TabView,
        firstTabViewed: TabView,
      ) => {
        const { product_id, impression_id, tracking_tag } = mapCreditCardToProductEventProps(card);
        return tabSwitched({
          product_id,
          impression_id,
          tracking_tag,
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index,
          [ANALYTICS_ATTRIBUTES.SUBVERTICAL]: categoryName,
          // Tab Switched can only be called if there is more than 1 tab
          [ANALYTICS_ATTRIBUTES.NUMBER_OF_TABS]: 2,
          [ANALYTICS_ATTRIBUTES.TAB_TITLE]: latestTabViewed,
          [ANALYTICS_ATTRIBUTES.DEFAULT_VALUE]: firstTabViewed,
        });
      },
      trackFilterSelected: (
        filterName: string,
        filterValue: string,
        selectedFilters: CreditCardFilterer[],
        countBefore: number,
        countAfter: number,
      ) =>
        filterSelected({
          ...filterEventProps,
          [ANALYTICS_ATTRIBUTES.FILTER_NAME]: filterName,
          [ANALYTICS_ATTRIBUTES.FILTER_CATEGORY]: "product_catalog_filter_chips",
          [ANALYTICS_ATTRIBUTES.FILTER_VALUE]: filterValue,
          [ANALYTICS_ATTRIBUTES.NUMBER_RESULTS_BEFORE]: countBefore,
          [ANALYTICS_ATTRIBUTES.NUMBER_RESULTS_AFTER]: countAfter,
          [ANALYTICS_ATTRIBUTES.ACTIVE_FILTERS_SELECTED]: getActiveFilters(selectedFilters),
          [ANALYTICS_ATTRIBUTES.ACTIVE_PROVIDERS_SELECTED]: getActiveProviders(selectedFilters),
        }),
      trackFilterButtonClicked: (cardCount: number) =>
        filterButtonClicked({
          ...filterEventProps,
          [ANALYTICS_ATTRIBUTES.NUMBER_RESULTS_BEFORE]: cardCount,
        }),
    }),
    [categoryName, productClicked, productViewed, tabSwitched, filterSelected, filterButtonClicked],
  );
};
