import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

import { borderRadius } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import {
  ANIMATION_DURATION_IN_MS,
  ANIMATION_DURATION_OUT_MS,
  ANIMATION_OPACITY_IN,
  ANIMATION_OPACITY_OUT,
  BACKGROUND_COLOR,
} from "./Skeleton.constants";
import type { SkeletonStyledProps } from "./Skeleton.types";

const pulseKeyframe = keyframes`
  0% {
    opacity: ${ANIMATION_OPACITY_OUT};
  }

  50% {
    opacity: ${ANIMATION_OPACITY_IN};
  }

  100% {
    opacity: ${ANIMATION_OPACITY_OUT};
  }
`;

const animationDurationMs = ANIMATION_DURATION_IN_MS + ANIMATION_DURATION_OUT_MS;

const backgroundColor = css`
  background-color: ${BACKGROUND_COLOR};
`;

const setSize = ({ width, height }: SkeletonStyledProps) => css`
  width: ${typeof width === "number" ? styledValue(width) : width};
  height: ${typeof height === "number" ? styledValue(height) : height};
`;

const applyAnimation = ({ animated }: SkeletonStyledProps) => {
  if (animated) {
    return css`
      animation: ${pulseKeyframe} ${animationDurationMs}ms infinite;
    `;
  }
};

export const Circle = styled.div<SkeletonStyledProps>`
  ${applyAnimation};
  ${setSize};
  ${backgroundColor};
  border-radius: 50%;
`;

export const Rect = styled.div<SkeletonStyledProps>`
  ${applyAnimation};
  ${setSize};
  ${backgroundColor};
`;

export const Rounded = styled.div<SkeletonStyledProps>`
  ${applyAnimation};
  ${setSize};
  ${backgroundColor};
  border-radius: ${styledValue(borderRadius.s)};
`;
