import styled from "@emotion/native";
import { Animated } from "react-native";

import { COLORS, borderRadius, borderWidth, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { Body2 } from "../../atoms/Typography";
import { ToastAlignment } from "./Toast.types";

const TOAST_BACKGROUND = "#F5FAFF";

export const ToastContainer = styled(Animated.View)<{
  alignment: ToastAlignment;
  bottomOffset: number;
  width: number;
  visible: boolean;
}>`
  flex-direction: row;
  gap: ${sv(spacing.xs)};
  align-items: center;
  ${({ visible }) => !visible && "display: none;"}

  position: fixed;
  ${({ bottomOffset }) => `bottom: ${sv(spacing.xs + bottomOffset)}`};
  ${({ width }) => `width: ${sv(width)};`}

  ${({ alignment, width }) =>
    alignment === "left"
      ? `left: ${sv(spacing.xs)};`
      : alignment === "right"
      ? `right: ${sv(spacing.xs)};`
      : `
        left: 50%;
        transform: translate(-${sv(width / 2)}, 0);
      `}

  z-index: 300;

  background: ${TOAST_BACKGROUND};

  border: ${sv(borderWidth.small)} solid ${COLORS.PRIMARY["100"]};
  border-radius: ${sv(borderRadius.s)};

  padding: ${sv(spacing.xs)};
`;

export const ToastText = styled(Body2)`
  flex: 1;
`;
