import React, { Component } from "react";

import type { ErrorBoundaryProps, ErrorBoundaryState } from "./ErrorBoundary.types";

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.props.onError?.(error, errorInfo);
  }

  render() {
    if (this.state.hasError && this.props.FallbackComponent) {
      /**
       * Rendering an optional fallback UI. If the fallback component is not provided, `children` is rendered by default
       */
      return this.props.FallbackComponent;
    }

    return this.props.children;
  }
}
