import axios from "axios";
import { useMutation } from "react-query";

import { useEnvironmentContext } from "@bwll/bw-hooks";
import { useSessionContext } from "@bwll/bw-hooks/src/contexts";
import { MutationOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey } from "@bwll/bw-utils";

const engageMember = async (accessToken: string, apiGatewayUrl: string) => {
  const response = await axios.get(`${apiGatewayUrl}/api/credit-monitoring/engage`, {
    headers: generateApiHeaders(accessToken, {
      "Cache-Control": "no-cache",
      pragma: "no-cache",
      expires: "-1",
    }),
  });
  return response;
};

export const useEngageMember = ({ onSuccess, onError, onSettled, onMutate }: MutationOptions = {}) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();
  const { accessToken } = userData;
  return useMutation(() => engageMember(accessToken, envConfigs.API_GATEWAY_URL), {
    mutationKey: generateQueryKey(QUERY_KEYS.GET_ENGAGE_MEMBER, accessToken),
    onSuccess: async (response) => {
      onSuccess?.(response);
    },
    onError,
    onSettled,
    onMutate,
  });
};
