import React from "react";

import { Link2Wrapper, StyledLink2 } from "./Link.styles";
import { LinkProps } from "./Link.types";

const DEFAULT_TEST_ID = "link2";

/**
 * @component Link2
 * @example
 * <Link onPress={() => {}} textAlign={"center"} hasUnderline>{"Link 2"}</Link>
 */
export const Link2 = ({
  children,
  onPress,
  testID = DEFAULT_TEST_ID,
  numberOfLines,
  ...props
}: LinkProps) => {
  return (
    <Link2Wrapper
      onPress={onPress}
      testID={testID}
      hasUnderline={props.hasUnderline}
      color={props.color}
      displayInline={props.displayInline}
    >
      <StyledLink2 numberOfLines={numberOfLines} {...props}>
        {children}
      </StyledLink2>
    </Link2Wrapper>
  );
};
