import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { Subheading1 } from "@bwll/bw-components/next";
import { fontSize } from "@bwll/bw-styles";
import { uuid } from "@bwll/bw-utils";

import {
  DEFAULT_ELEMENT_ID,
  TOOLTIP_CLOSE_DELAY_MS,
  TOOLTIP_OPEN_DELAY_MS,
  WEB_TOOLTIP_CLASSNAME,
} from "./Tooltip.constants";
import { Container, Header, Icon, TooltipContent, triggerElementStyles } from "./Tooltip.styles";
import type { TooltipProps } from "./Tooltip.types";

const domNode = document.createElement("div");
domNode.setAttribute("class", `${WEB_TOOLTIP_CLASSNAME}-root`);
document.body.appendChild(domNode);

const Portal = ({ children }: PropsWithChildren<object>) => ReactDOM.createPortal(children, domNode);

const tooltipCloseConfig = {
  escape: true,
  resize: true,
};

export const Tooltip = ({
  children,
  onClose,
  onOpen,
  content,
  title = "",
  width,
  id,
  openDelayMs = TOOLTIP_OPEN_DELAY_MS,
  closeDelayMs = TOOLTIP_CLOSE_DELAY_MS,
  interactive = true,
  placement = "bottom",
}: TooltipProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  /** Prevent unique ID regeneration on every render cycle */
  const uniqueId = useMemo(() => uuid(), []);

  /**
   * Note: the consumer is responsible for passing unique `id`.
   * If the `id` is missing, we create a unique id based on the output of the uuid() function
   * to make sure all tooltips on the same page have unique accessor
   */
  const tooltipId = id ? `${id}-${uniqueId}` : `${DEFAULT_ELEMENT_ID}-${uniqueId}`;

  const close = useCallback(() => {
    setIsActive(false);
    onClose?.();
  }, [onClose]);

  const open = useCallback(() => {
    setIsActive(true);
    onOpen?.();
  }, [onOpen]);

  return (
    <>
      <a
        data-tooltip-id={tooltipId}
        data-tooltip-place={placement}
        data-tooltip-delay-show={openDelayMs}
        data-tooltip-delay-hide={closeDelayMs}
        onClick={isActive ? close : open}
        style={triggerElementStyles}
      >
        {children}
      </a>
      {isActive && (
        <Portal>
          <Container className={`${WEB_TOOLTIP_CLASSNAME}-container`} $width={width}>
            <ReactTooltip
              id={tooltipId}
              isOpen={isActive}
              clickable={interactive}
              className={WEB_TOOLTIP_CLASSNAME}
              opacity={1}
              place={placement}
              globalCloseEvents={tooltipCloseConfig}
            >
              {interactive && (
                <Header>
                  <Subheading1>{title}</Subheading1>
                  <Icon size={fontSize.xl} icon="close" onClick={close} />
                </Header>
              )}
              <TooltipContent>{content}</TooltipContent>
            </ReactTooltip>
          </Container>
        </Portal>
      )}
    </>
  );
};
