import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { useMemo } from "react";

import { composeAuthRedirectUri } from "@bwll/bw-hooks/src/utils";

export const BORROWELL_MEMBER_ROLE = "Borrowell Member";

/**
 * Configuration options for the User Manager.
 */
export interface IAuthUserManagerConfig {
  /**
   * The authority URL for the authentication server.
   */
  authority: string;

  /**
   * The client ID for the application.
   */
  clientId: string;

  /**
   * The redirect URI path for the application.
   * Note: The path should start with a forward slash.
   */
  redirectUriPath: string;

  /**
   * Optional flag to enable silent authentication.
   */
  isSilentAuth?: boolean;
}

export const useUserManager = ({
  authority,
  clientId,
  redirectUriPath,
  isSilentAuth = false,
}: IAuthUserManagerConfig) => {
  return useMemo(() => {
    const redirectUri = composeAuthRedirectUri(window.location.origin, redirectUriPath);
    const userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority,
      client_id: clientId,
      redirect_uri: redirectUri,
      silent_redirect_uri: redirectUri,
      post_logout_redirect_uri: redirectUri,
      response_type: "code",
      scope: [
        "openid",
        "roles",
        "email",
        "pv.read",
        "ecr.read",
        "gemstone.web.public.full_access",
        "gemstone.web.identity.full_access",
        "mc.read",
        "cc.read",
        "uc.read",
        "IdentityServerApi",
        "ns.read",
        "pp.read",
        "ps.read",
        "rr.read",
        "ss.read",
        "promo.read",
      ].join(" "),
      loadUserInfo: true,
      prompt: isSilentAuth ? "none" : undefined,
      monitorSession: true,
    });

    return userManager;
  }, [authority, clientId, isSilentAuth, redirectUriPath]);
};
