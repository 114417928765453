/**
 * Represents an enum of "origin" Analytics attribute values in the Dashboard app.
 */
export const DASHBOARD_ANALYTICS_ORIGINS = {
  RECOMMENDATIONS_SNACKBAR: "dashboard_recommendatios_snackbar",
  CBL_PROMO_CARD: "dashboard_cbl_promo_card",
  PRESELECTED: "dashboard_preselected",
  PRODUCT_PLACEMENT: "dashboard_product_placement",
  SPONSORED_PLACEMENT: "dashboard_sponsored_carousel",
  SNACKBAR_PRODUCT_OFFER: "snackbar_product_offer",
  LTO_PLACEMENT: "dashboard_limited_time_offer",
  PROMOTED_PRODUCT_PLACEMENT: "dashboard_promoted_card",
  DASHBOARD: "dashboard",
  POPUP: "dashboard_popup",
} as const;

/**
 * Represents possible values of {@link DASHBOARD_ANALYTICS_ORIGINS}.
 */
export type DashboardAnalyticsOrigin =
  (typeof DASHBOARD_ANALYTICS_ORIGINS)[keyof typeof DASHBOARD_ANALYTICS_ORIGINS];
