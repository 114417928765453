// default values
export const enum ANALYTICS_VALUES {
  CATEGORY_PRODUCT_CATALOG = "product catalog",
  CATEGORY_PRODUCT_CATALOG_MOBILE = "product-catalog",
  DASHBOARD_LOCATION = "dashboard",
  DASHBOARD_SIDEBAR_LOCATION = "sidebar",
  FEED_POSITION = "feed",
  IMPRESSION_ILLUSTRATIVE_CARD = "dashboard-illustrative-card",
  PROMO_CARD_RANKING = "2_promo_card",
  HIERARCHY_PROMO_CARD_RANKING = "1_promo_card",
  PRE_SELECTION = "pre-selection",
  REACT_NATIVE = "react-native",
  SINGLE_SPA = "single-spa",
  CBL2_DEFAULT_IMAGE_NAME = "CBL 2.0 09-2022",
  RENT_ADVANTAGE_DEFAULT_IMAGE_NAME = "RA 08-2022",
  IMPRESSION_DASHBOARD_CBL_PROMO_CARD_V2 = "dashboard-cbl-promo-card-v2",
  PROMO_CATEGORY_RENT_ADVANTAGE_MOBILE = "Rent Reporting",
  PROMO_CATEGORY_RENT_ADVANTAGE_WEB = "rent advantage",
  RENT_ADVANTAGE_TRACKING_TAG = "CB-rentadvantage",
  // origins
  CREDIT_REPORT = "Credit Report",
  SIMILAR_PRODUCTS = "similar_products",
}

export enum DEVICE_PLATFORM {
  IOS = "iOS",
  ANDROID = "Android",
  WEB = "Web",
}

export enum LAYOUT_TYPE {
  MOBILE_APP = "Mobile_App",
  WEB = "Web",
  MOBILE_WEB = "MWeb",
}

export enum RENT_REPORTING_VERSION {
  V1 = "v1.0",
  V1_1_0 = "v1.1.0",
  V2 = "v2.0",
}

export enum ONBOARDING_TYPE {
  BANKING = "Banking",
  MANUAL = "Manual",
}

export enum APPLICATION_TYPE {
  SINGLE_SPA = "single-spa",
}

export type VERSION = RENT_REPORTING_VERSION;

export enum EVENT_LABEL {
  // base
  PAGE = "Page",
  SCREEN_VIEW = "Screen View",
  BUTTON_CLICKED = "Button Clicked",
  NAVIGATION_BUTTON_CLICKED = "Navigation Button Clicked",
  POPUP_SCREEN_VIEWED = "Popup Screen Viewed",
  POPUP_BUTTON_CLICKED = "Popup Button Clicked",
  POPUP_BUTTON_VIEWED = "Popup Button Viewed",
  POPUP_DISMISSED = "Popup Dismissed",
  PROMO_CARD_VIEWED = "Promo Card Viewed",
  PROMO_CARD_CLICKED = "Promo Card Clicked",
  MODAL_VIEWED = "Modal Viewed",
  MODAL_DISMISSED = "Modal Dismissed",
  LINK_CLICKED = "Link Clicked",
  CHECKBOX_CLICKED = "Checkbox Clicked",
  EVENT_CLICK = "Click",
  // marketplace
  PRODUCT_VIEWED = "Product Viewed",
  PRODUCT_CLICKED = "Product Clicked",
  PRODUCT_EXPANDED = "Product Expanded",
  DROPDOWN_CLICKED = "Dropdown Clicked",
  DROPDOWN_OPTION_CLICKED = "Dropdown Option Clicked",
  FILTER_SELECTED = "Filter Selected",
  COMPARE_CARD_ADDED = "Compare Card Added",
  COMPARE_BUTTON_CLICKED = "Compare Button Clicked",
}

export enum COMPONENT_PLACEMENT {
  DASHBOARD = "Dashboard",
}

export enum EVENT_ACTIONS {
  EVENT = "event",
}

export enum EVENT_CATEGORIES {
  EVENT = "event",
}

export const CURRENT_RENT_REPORTING_VERSION = RENT_REPORTING_VERSION.V1;
