export const PromoCardTemplateVariant = {
  card: "card",
  snackbar: "snackbar",
  accordion: "accordion",
} as const;

export const LINE_TEST_ID = "promo-card-template-line";
export const TITLE_TEST_ID = "promo-card-template-title";
export const ICON_TEST_ID = "promo-card-template-icon";
export const BODY_TEST_ID = "promo-card-template-body";
export const CTA_TEST_ID = "promo-card-template-cta";
export const NEW_BADGE_TEST_ID = "promo-card-template-new-badge";
