import { useGetMember } from "./api";

export type { Individual } from "@bwll/bw-types/src/types/member";

export const useIndividualClient = () => {
  const { data, isLoading, isSuccess } = useGetMember();
  return {
    data: data?.individual,
    isLoading,
    isSuccess: isSuccess && !!data?.individual,
  };
};
