import React from "react";

import { InfoTabsProvider } from "./InfoTabs.provider";
import * as Styled from "./InfoTabs.styles";
import type { InfoTabsProps } from "./InfoTabs.types";
import { Content, Header, Tab } from "./components";

const InfoTabs = ({ children, initialTabID, onPress }: InfoTabsProps) => (
  <InfoTabsProvider initialTabID={initialTabID} onPress={onPress}>
    <Styled.Layout>{children}</Styled.Layout>
  </InfoTabsProvider>
);

InfoTabs.Header = Header;
InfoTabs.Tab = Tab;
InfoTabs.Content = Content;

export { InfoTabs };
