import React, { useEffect } from "react";

import { useEngageMember, useTransitionGetMember } from "@bwll/bw-hooks";

interface IProps {
  children: React.ReactNode;
  isEnabled?: React.ReactNode;
}

/**
 * Checks if the member has skipped the report and engages the member if the condition is met.
 */
export const EngagementCheck = ({ children, isEnabled }: IProps) => {
  const { data: memberData, isLoading } = useTransitionGetMember();
  const { mutate: engageMember } = useEngageMember();

  useEffect(() => {
    if (!isLoading && memberData?.isReportSkipped && isEnabled) {
      engageMember({});
    }
  }, [memberData, engageMember, isEnabled, isLoading]);

  return <>{children}</>;
};
