export const pushNotifications = {
  title: "Push notifications from Borrowell",
  descriptionParagraph1:
    "Borrowell sends you push notifications for communications related to both account updates and promotions.",
  descriptionParagraph2:
    "When there are updates to your account or products you have signed up for, we inform you as a member so you’re always aware of changes to your credit profile and important updates.",
  descriptionParagraph3:
    "Promotional notifications are how we inform you about financial products that fit your credit profile. These financial products could help you build your credit or help you reach your financial goals.",
  descriptionParagraph4:
    "To turn off promotional push notifications, click the toggle off. Note: it may take up to 2 days for push notification preferences to be updated.",
  descriptionParagraph5:
    "For more information about promotional push notifications, please reach out to us at __[info@borrowell.com](mailto:info@borrowell.com)__ or 1 University Ave, 3rd Floor, Toronto, Ontario, M5J 2P1",
};
