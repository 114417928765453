import styled from "@emotion/native";

import { Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderWidth, fontSize, spacing } from "@bwll/bw-styles";

export const StyledCheckboxComponent = styled.View(() => ({
  flex: 1,
}));

export const StyledCheckboxOptionComponent = styled.TouchableOpacity<{ isFirst?: boolean }>(
  ({ isFirst }) => ({
    flexDirection: "row",
    paddingVertical: spacing.xxs,
    paddingTop: isFirst ? spacing.xxxs : spacing.xxs,
  }),
);

export const StyledCheckboxSquareComponent = styled.View(({ selected }: { selected: boolean }) => ({
  borderRadius: spacing.xxxs,
  borderWidth: borderWidth.small,
  height: spacing.s,
  width: spacing.s,
  backgroundColor: selected ? COLORS.PRIMARY.DEFAULT : COLORS.WHITE,
  borderColor: selected ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL[600],
  marginRight: spacing.xxs,
  alignSelf: "center",
  justifyContent: "center",
}));

export const StyledCheckboxLabel = styled(Label)(() => ({
  fontSize: fontSize.xs,
}));
