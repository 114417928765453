import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.View`
  gap: ${styledValue(spacing.xxs)};
`;

export const ExpandableContainer = styled.View<{ isExpanded: boolean; showBorder: boolean; height: number }>`
  ${({ isExpanded, height }) =>
    !isExpanded &&
    `
    max-height: ${styledValue(height)};

  `};
  ${({ isExpanded, showBorder }) =>
    !isExpanded &&
    showBorder &&
    `
    border-bottom-color: ${COLORS.NEUTRAL.COOL["100"]};
    border-bottom-width: 1px;
  `};
  overflow: hidden;
`;

export const ShowMoreLabel = styled.View`
  display: flex;
  flex-direction: row;
  gap: ${styledValue(spacing.xxs)};
`;
