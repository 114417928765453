import { useMemo } from "react";

import { useExperimentStoreContext } from "@bwll/bw-hooks";
import {
  IUseExperimentsProps,
  STANDARD_TREATMENTS,
  WEB_EXPERIMENT_IDS,
  WebTreatmentIdsType,
} from "@bwll/bw-types";

/**
 * This hook is used to check if the experiment treatment matches the treatment or list of treatments passed in
 * Should only be used if the parent has access to the experimentStoreContext
 * @param {object} IUseExperimentsProps - experimentId, treatment to match, treatments to match
 * @returns {boolean} result - true when user is in the experiment treatment or one of the treatments
 */
export const useExperiments = ({
  experimentId,
  treatment = STANDARD_TREATMENTS.ON,
  treatments = [],
}: IUseExperimentsProps) => {
  const { experimentsMap } = useExperimentStoreContext();

  const result = useMemo(() => {
    const experimentTreatment = experimentsMap.userConfig?.[experimentId as WEB_EXPERIMENT_IDS]?.treatment;
    return treatments.length
      ? treatments.includes(experimentTreatment || "")
      : experimentTreatment === treatment;
  }, [experimentsMap.userConfig, experimentId, treatments, treatment]);

  return result;
};
