import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  ImpressionedProductVerticalCreditCard,
  TrackEventFuncAsync,
} from "@bwll/bw-types";
import { isTrendingProduct, mapCreditCardToProductEventProps } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "credit_cards_compare_page",
} as const;

const VERTICAL = "Credit Cards";

export const useCreditCardComparisonAnalytics = (trackEvent: TrackEventFuncAsync) => {
  const { productViewed, productClicked, compareButtonClicked, compareCardAdded } =
    useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(
    () => ({
      trackProductViewed: (card: ImpressionedProductVerticalCreditCard) =>
        productViewed({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.IS_TRENDING]: isTrendingProduct(card),
        }),
      trackProductClicked: (card: ImpressionedProductVerticalCreditCard) =>
        productClicked({
          ...mapCreditCardToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
          [ANALYTICS_ATTRIBUTES.VERTICAL]: VERTICAL,
          [ANALYTICS_ATTRIBUTES.IS_TRENDING]: isTrendingProduct(card),
        }),
      trackCompareButtonClicked: (productTrackingTags: string[]) =>
        compareButtonClicked({
          [ANALYTICS_ATTRIBUTES.VERTICAL]: VERTICAL,
          [ANALYTICS_ATTRIBUTES.PRODUCTS_SELECTED]: productTrackingTags,
          [ANALYTICS_ATTRIBUTES.COMPARISON_CARDS_COUNT]: productTrackingTags.length,
        }),
      trackCompareCardAdded: (card: ImpressionedProductVerticalCreditCard) => {
        const { impression_id, tracking_tag } = mapCreditCardToProductEventProps(card);
        return compareCardAdded({
          [ANALYTICS_ATTRIBUTES.VERTICAL]: VERTICAL,
          [ANALYTICS_ATTRIBUTES._IMPRESSION_ID]: impression_id,
          [ANALYTICS_ATTRIBUTES.TRACKING_TAG]: tracking_tag,
        });
      },
    }),
    [productClicked, productViewed, compareButtonClicked, compareCardAdded],
  );
};
