import styled from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { Body2 } from "../../../atoms/Typography";

export const Container = styled.View`
  gap: ${sv(spacing.xxxs)};
`;

export const InputLabel = styled(Body2)`
  color: ${COLORS.NEUTRAL.COOL[600]};
`;
