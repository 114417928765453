import {
  CREDIT_CARD_CARD_TYPE_KEYS,
  CREDIT_CARD_NETWORK_KEYS,
  ProductVerticalCreditCard,
} from "@bwll/bw-types";

import { isCardType, isNetworkType, isSpecialOffer, isTrendingProduct } from "./creditCardFilterersUtils";

/**
 * Represents {@link CreditCardFilterOption} values.
 */
export const CreditCardFilterOptions = {
  TopOffers: "TopOffers",
  TrendingOffers: "TrendingOffers",
  CashBack: "CashBack",
  Travel: "Travel",
  Rewards: "Rewards",
  NoFee: "NoFee",
  BalanceTransfer: "BalanceTransfer",
  CreditBuilder: "CreditBuilder",
  AmericanExpress: "AmericanExpress",
  MasterCard: "MasterCard",
  Visa: "Visa",
} as const;

export const CreditCardFilterCategories = {
  Offers: "Offers",
  Benefits: "Benefits",
  Features: "Features",
  Network: "Network",
  Providers: "Providers",
} as const;

export type CreditCardFilterCategory = keyof typeof CreditCardFilterCategories;
export type StaticCreditCardFilterOption = keyof typeof CreditCardFilterOptions;
export type DynamicCreditCardFilterOption = string;
export type CreditCardFilterOption = StaticCreditCardFilterOption | DynamicCreditCardFilterOption;

export type CreditCardFilterer = {
  key: string;
  category: CreditCardFilterCategory;
  predicate: (c: ProductVerticalCreditCard) => boolean;
};

/**
 * Provides objects that filter Credit Cards based on their properties.
 */
export const creditCardFilterers: Record<StaticCreditCardFilterOption, CreditCardFilterer> = {
  [CreditCardFilterOptions.TopOffers]: {
    key: CreditCardFilterOptions.TopOffers,
    category: CreditCardFilterCategories.Offers,
    predicate: isSpecialOffer,
  },
  [CreditCardFilterOptions.TrendingOffers]: {
    key: CreditCardFilterOptions.TrendingOffers,
    category: CreditCardFilterCategories.Offers,
    predicate: (creditCard) => isTrendingProduct(creditCard),
  },
  // Card types
  [CreditCardFilterOptions.CashBack]: {
    key: CreditCardFilterOptions.CashBack,
    category: CreditCardFilterCategories.Benefits,
    predicate: isCardType.bind(this, CREDIT_CARD_CARD_TYPE_KEYS.CASH_BACK),
  },
  [CreditCardFilterOptions.Travel]: {
    key: CreditCardFilterOptions.Travel,
    category: CreditCardFilterCategories.Benefits,
    predicate: isCardType.bind(this, CREDIT_CARD_CARD_TYPE_KEYS.TRAVEL),
  },
  [CreditCardFilterOptions.Rewards]: {
    key: CreditCardFilterOptions.Rewards,
    category: CreditCardFilterCategories.Benefits,
    predicate: isCardType.bind(this, CREDIT_CARD_CARD_TYPE_KEYS.REWARDS),
  },
  [CreditCardFilterOptions.BalanceTransfer]: {
    key: CreditCardFilterOptions.BalanceTransfer,
    category: CreditCardFilterCategories.Features,
    predicate: isCardType.bind(this, CREDIT_CARD_CARD_TYPE_KEYS.BALANCE_TRANSFER),
  },
  [CreditCardFilterOptions.CreditBuilder]: {
    key: CreditCardFilterOptions.CreditBuilder,
    category: CreditCardFilterCategories.Features,
    predicate: isCardType.bind(this, CREDIT_CARD_CARD_TYPE_KEYS.CREDIT_BUILDER),
  },
  [CreditCardFilterOptions.NoFee]: {
    key: CreditCardFilterOptions.NoFee,
    category: CreditCardFilterCategories.Features,
    predicate: (creditCard) =>
      isCardType(CREDIT_CARD_CARD_TYPE_KEYS.NO_FEE, creditCard) || creditCard.isFirstYearWaived,
  },
  [CreditCardFilterOptions.AmericanExpress]: {
    key: CreditCardFilterOptions.AmericanExpress,
    category: CreditCardFilterCategories.Network,
    predicate: (creditCard) => isNetworkType(CREDIT_CARD_NETWORK_KEYS.AMERICAN_EXPRESS, creditCard),
  },
  [CreditCardFilterOptions.MasterCard]: {
    key: CreditCardFilterOptions.MasterCard,
    category: CreditCardFilterCategories.Network,
    predicate: isNetworkType.bind(this, CREDIT_CARD_NETWORK_KEYS.MASTERCARD),
  },
  [CreditCardFilterOptions.Visa]: {
    key: CreditCardFilterOptions.Visa,
    category: CreditCardFilterCategories.Network,
    predicate: isNetworkType.bind(this, CREDIT_CARD_NETWORK_KEYS.VISA),
  },
};
