import React from "react";

import { fontSize } from "@bwll/bw-styles";

import { Icon } from "../Icon";
import { IconButtonComponent } from "./IconButton.styles";
import { IconButtonProps } from "./IconButton.types";

/**
 * Component for Close Button.
 */
export const IconButton = ({
  onPress,
  testID,
  icon,
  accessibilityLabel,
  size = fontSize.m,
  color,
}: IconButtonProps) => {
  return (
    <IconButtonComponent
      onPress={onPress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      testID={testID}
    >
      <Icon icon={icon} color={color} size={size} />
    </IconButtonComponent>
  );
};
