export const misc = {
  "paymentProfile": {
    "title": "Historique des paiements",
    "subtitle": "Affichage de l'historique des paiements jusqu'à 36 mois",
    "caption": {
      "paid": "Payé",
      "missed": "Manqué",
      "noInfo": "Aucune information",
      "deferred": "Paiement différé"
    }
  },
  "common": {
    "maybeLater": "Peut-être plus tard",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "continue": "Continue",
    "viewProduct": "View Product",
    "back": "Retour"
  },
  "featureCard": {
    "approvalText": "Probabilités d'approbation : ",
    "approvalChance": "HAUT",
    "applyButton": "FAIRE UNE DEMANDE",
    "detailsButton": "DÉTAILS"
  },
  "chats": {
    "nonEngagedUsers": [
      "Bienvenue à nouveau, {{firstName}}!",
      "Patientez pendant que nous mettons à jour votre cote de crédit.",
      "Conseil en matière de crédit : le processus d'obtention d'un bon crédit est un marathon, pas un sprint! Accordez-vous du temps pour atteindre vos objectifs."
    ]
  },
  "commonAccessibility": {
    "goBack": "Retour"
  }
}
