import * as Sentry from "@sentry/react";
import { makeFetchTransport, makeMultiplexedTransport } from "@sentry/react";

import { appInsightsService } from "@bwll/bw-utils";

import { PROJECT_BUILD_NUMBER, PROJECT_VERSION_URL_MAPPING, SENTRY_PROJECT_DSN } from "./contants";
import { ErrorReportingConfig } from "./types";

/**
 * Fetches the build number for each project from the version URL mapping.
 * If the build number cannot be fetched, it fallback to "unknown".
 */
(async () => {
  // We can skip this if running from localhost
  if (window.location.hostname !== "localhost") {
    const urls = PROJECT_VERSION_URL_MAPPING;

    for (const key in urls) {
      if (urls.hasOwnProperty.call(urls, key)) {
        try {
          const response = await fetch(urls[key]);
          const data = await response.json();
          PROJECT_BUILD_NUMBER[key] = `${key}@${data["Build Number"]}`;
        } catch (error) {
          PROJECT_BUILD_NUMBER[key] = `${key}@"unknown"`;
        }
      }
    }
  }

  return PROJECT_BUILD_NUMBER;
})();

/**
 * Custom transport that extracts the extra data from the event and returns it as the data to be sent.
 * This is required to forward transactions and events to the correct DSN and release in Sentry.
 * https://docs.sentry.io/platforms/javascript/guides/react/best-practices/micro-frontends/#manually-route-errors-to-different-projects
 */
const transport = makeMultiplexedTransport(makeFetchTransport, ({ getEvent }) => {
  const event = getEvent(["event", "transaction"]);

  const project = event?.transaction?.split("/")?.[1];

  if (project) {
    return [
      {
        dsn: SENTRY_PROJECT_DSN[project as keyof typeof SENTRY_PROJECT_DSN],
        release:
          PROJECT_BUILD_NUMBER?.[project as keyof typeof PROJECT_BUILD_NUMBER] || `${project}@localhost`,
      },
    ];
  } else {
    return [];
  }
});

/**
 * Utility for initializing and handling error reporting with Sentry and AppInsights.
 */
export const ErrorReporting = (() => {
  let isActive = false;
  let isIdentified = false;

  /**
   * Initializes the error reporting services with the provided configuration.
   *
   * @param {ErrorReportingConfig} config - Configuration for Sentry and AppInsights.
   */
  const init = ({
    sentry: {
      dsn,
      environment,
      tracesSampleRate = 0,
      replaysOnErrorSampleRate = 0,
      replaysSessionSampleRate = 0,
    },
    appInsights,
  }: ErrorReportingConfig) => {
    if (!isActive) {
      Sentry.init({
        dsn,
        transport,
        environment,
        tracesSampleRate,
        replaysOnErrorSampleRate,
        replaysSessionSampleRate,
        integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
        tracePropagationTargets: [
          /^https:\/\/gateway\..*\.bwll\.net/,
          /^https:\/\/identityserverapi.\..*\.bwll.net/,
        ],
        beforeSendTransaction(event) {
          event.tags = {
            ...event.tags,
            "previous-route": event.contexts?.trace?.data?.previousRoute?.name,
          };
          return event;
        },
      });

      if (appInsights?.connectionString) {
        appInsightsService.init(appInsights?.connectionString);
      }

      isActive = true;
    }
  };

  /**
   * Handles capturing exceptions and sending them to Sentry and AppInsights.
   *
   * @param {Error} error - The error object.
   * @param {Object} errorInfo - Optional additional error information.
   */
  const logError = (error: Error, errorInfo?: object) => {
    Sentry.captureException(error, errorInfo);

    appInsightsService.trackException(error, errorInfo);
  };

  /**
   * Identifies the user with the provided individual client ID.
   *
   * @param {string} individualClientId - The individual client ID.
   */
  const identify = (individualClientId: string) => {
    if (!isIdentified) {
      if (Sentry.isInitialized()) {
        Sentry.setUser({ id: individualClientId });
      }

      appInsightsService.setIndividualClientId(individualClientId);

      isIdentified = true;
    }
  };

  return {
    init,
    logError,
    identify,
  };
})();
