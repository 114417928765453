import styled from "@emotion/native";

import { COLORS, shadow, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { CellProps } from "./Cell.types";

export const StyledCell = styled.View<CellProps>`
  background: ${({ backgroundColor }) => backgroundColor ?? COLORS.WHITE};
  padding-vertical: ${({ paddingVertical }) => styledValue(paddingVertical ?? spacing.xs)};
  padding-horizontal: ${({ paddingHorizontal }) => styledValue(paddingHorizontal ?? spacing.xs)};
  border-radius: ${({ borderRadius }) => styledValue(borderRadius ?? 0)};
  ${({ hasShadow }) => hasShadow && shadow};
`;
