import styled from "@emotion/native";
import { Animated, StyleSheet } from "react-native";

import { COLORS, borderRadius, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const BottomSheetBackdrop = styled(Animated.View)`
  ${StyleSheet.absoluteFillObject};
  background-color: ${toRGBAString(COLORS.NEUTRAL.COOL["800"], 0.8)};
  z-index: 20;
`;

export const BottomSheetBackdropWeb = styled.View`
  ${StyleSheet.absoluteFillObject};
  background-color: ${toRGBAString(COLORS.NEUTRAL.COOL["800"], 0.5)};
  z-index: 201;
  position: fixed;
`;

export const BottomSheetWrapper = styled(Animated.View)`
  ${StyleSheet.absoluteFillObject};
  width: 100%;
  height: 85%;
  background: ${COLORS.WHITE};
  border-top-left-radius: ${styledValue(20)};
  border-top-right-radius: ${styledValue(20)};
  z-index: 21;
`;

export const BottomSheetWrapperWeb = styled.View`
  ${StyleSheet.absoluteFillObject};
  padding-top: ${styledValue(40)};
  width: 100%;
  background: ${COLORS.WHITE};
  border-top-left-radius: ${styledValue(20)};
  border-top-right-radius: ${styledValue(20)};
  z-index: 202;
  position: fixed;
  top: 60px;
  height: 93%;
`;

export const ContentContainer = styled.View`
  padding-left: ${styledValue(spacing.m)};
  padding-right: ${styledValue(spacing.m)};
`;

export const BottomSheetGrabberContainer = styled.View`
  padding-top: ${styledValue(spacing.m)};
  padding-bottom: ${styledValue(spacing.xs)};
`;

export const BottomSheetGrabber = styled.View`
  width: ${styledValue(40)};
  height: ${styledValue(6)};
  background: ${COLORS.NEUTRAL.COOL["600"]};
  border-radius: ${styledValue(borderRadius.xs)};
  align-self: center;
`;
