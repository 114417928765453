/* eslint-disable turbo/no-undeclared-env-vars */
import * as process from "process";

export const ENVIRONMENT_VARIABLES = {
  APP_INSIGHTS_CONNECTION_STRING: process.env.BWLL_APP_INSIGHTS_CONNECTION_STRING as string,
  COOKIES_DOMAIN: process.env.BWLL_COOKIES_DOMAIN as string,
  ENVIRONMENT: process.env.BWLL_ENVIRONMENT as string,
  MARKETING_BASE_URL: process.env.BWLL_MARKETING_BASE_URL as string,
  GEMSTONE_BASE_URL: process.env.BWLL_GEMSTONE_BASE_URL as string,
  APP_BASE_URL: process.env.BWLL_APP_BASE_URL as string,
  IDENTITY_AUTHORITY: process.env.BWLL_IDENTITY_AUTHORITY as string,
  OIDC_CLIENT: process.env.BWLL_OIDC_CLIENT as string,
  REFINER_ENABLED: process.env.BWLL_REFINER_ENABLED as unknown as boolean,
  REFINER_ENVIRONMENT_ID: process.env.BWLL_REFINER_ENVIRONMENT_ID as string,
} as const;
