import styled from "@emotion/native";
import { Platform } from "react-native";

import { COLORS, borderRadius, fontSize, fonts, lineHeight, spacing } from "@bwll/bw-styles";
import { Platforms } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

import { IsOpenProps } from "./Accordion.types";

export const AccordionWrapper = styled.View<{ isBorderless: boolean }>`
  width: 100%;
  border: ${({ isBorderless }) => (isBorderless ? "none" : `1px solid ${COLORS.NEUTRAL.COOL["200"]}`)};
  border-radius: ${({ isBorderless }) => styledValue(isBorderless ? spacing.none : borderRadius.s)};
`;

export const TitleWrapper = styled.TouchableOpacity<IsOpenProps & { applyDefaultPadding: boolean }>(
  ({ isOpen, applyDefaultPadding }) => ({
    padding: applyDefaultPadding ? spacing.xs : spacing.none,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...(isOpen
      ? {
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }
      : {
          borderRadius: 8,
        }),
  }),
);

export const Title = styled.Text({
  fontFamily: Platform.OS === Platforms.WEB ? `${fonts.lato.regular}, Lato` : fonts.lato.regular,
  fontWeight: "bold",
  fontSize: fontSize.xs,
  lineHeight: lineHeight.m,
  letterSpacing: 0.32,
  color: COLORS.NEUTRAL.WARM["800"],
});

export const Expandable = styled.View<IsOpenProps & { isBorderless: boolean }>(
  ({ isOpen, isBorderless }) => ({
    display: isOpen ? "flex" : "none",
    overflow: "hidden",
    borderTopWidth: isBorderless ? 0 : 1,
    borderTopColor: COLORS.NEUTRAL.COOL["200"],
  }),
);

export const Footer = styled.View({
  borderTopWidth: 1,
  borderTopColor: COLORS.NEUTRAL.COOL["200"],
});

export const IconWrapper = styled.View({
  marginLeft: 10,
  alignSelf: "center",
});
