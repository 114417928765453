export enum RENT_REPORTING_STATUS {
  REPORTED_AS_PAID = 0,
  REPORTED_AS_MISSED = 1,
  WILL_BE_REPORTED_AS_PAID = 2,
  WILL_BE_REPORTED_AS_MISSED = 3,
}

export interface RentTransaction {
  transactionId: string;
  amount: number;
  datePosted: string;
  description: string;
  originalDescription?: string;
  reportedToAgencyDate?: string;
  reportingStatus?: RENT_REPORTING_STATUS;
  manuallyAcknowledgedDate?: string;
  rentPeriod?: string;
}

export interface UniversalTransaction {
  id: string;
  description: string;
  date: string;
  amount: number;
}

export interface LatestRentPeriodTransactions {
  rentPeriod: string;
  totalAmount: number;
  transactions: RentTransaction[];
}

export interface UpdateRentTransaction {
  rentPeriod: string;
  transactionId: string;
}

export interface DeleteRentTransaction {
  transactionId: string;
}

export enum RentTransactionsManagementFlow {
  ONBOARDING,
  CONFIRMATION,
}

export enum TRANSACTIONS_FILTERS_NAMES {
  OLDEST_FIRST = "oldest",
  NEWEST_FIRST = "newest",
  LOWEST_FIRST = "lowest",
  HIGHEST_FIRST = "highest",
}

export const transactionFilterValues = {
  [TRANSACTIONS_FILTERS_NAMES.NEWEST_FIRST]: {
    field: "createdAt",
    order: "desc",
  },
  [TRANSACTIONS_FILTERS_NAMES.OLDEST_FIRST]: {
    field: "createdAt",
    order: "asc",
  },
  [TRANSACTIONS_FILTERS_NAMES.LOWEST_FIRST]: {
    field: "amount",
    order: "asc",
  },
  [TRANSACTIONS_FILTERS_NAMES.HIGHEST_FIRST]: {
    field: "amount",
    order: "desc",
  },
};
