import MarketplaceEmptyResults from "./assets/marketplace-empty-results.svg";
import MarketplaceLoading from "./assets/marketplace-loading.svg";
import RaBetterInterestRates from "./assets/ra-better-interest-rates.svg";
import RaBuildCreditHistory from "./assets/ra-build-credit-history.svg";
import RaConfirmYourRent from "./assets/ra-confirm-your-rent.svg";
import RaConfirmationSuccess from "./assets/ra-confirmation-success.svg";
import RaCreditScoreGrowth from "./assets/ra-credit-score-growth.svg";
import RaQualifyMoreProducts from "./assets/ra-qualify-more-products.svg";
import RaSignUp from "./assets/ra-sign-up.svg";
import RaWatchCreditGrow from "./assets/ra-watch-credit-grow.svg";
import SvgEmpty from "./assets/svg-empty.svg";

type SvgImage = {
  key: string;
  component: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const SvgPlaceholder = SvgEmpty;

// Add new SVG image key and component mapping here
export const SVG_IMAGES = [
  { key: "marketplace-empty-results", component: MarketplaceEmptyResults },
  { key: "marketplace-loading", component: MarketplaceLoading },
  { key: "svg-empty", component: SvgEmpty },
  { key: "ra-confirmation-success", component: RaConfirmationSuccess },
  { key: "ra-credit-score-growth", component: RaCreditScoreGrowth },
  { key: "ra-sign-up", component: RaSignUp },
  { key: "ra-confirm-your-rent", component: RaConfirmYourRent },
  { key: "ra-build-credit-history", component: RaBuildCreditHistory },
  { key: "ra-watch-credit-grow", component: RaWatchCreditGrow },
  { key: "ra-better-interest-rates", component: RaBetterInterestRates },
  { key: "ra-qualify-more-products", component: RaQualifyMoreProducts },
] as const satisfies ReadonlyArray<SvgImage>;

/** List of keys of the SVG images registered in SVG_IMAGES. Sorted in ascending order. */
export const SVG_IMAGE_KEYS = SVG_IMAGES.map(({ key }) => key).sort((a, b) => a.localeCompare(b));
