import { CreditCardCardTypeKeys, CreditCardNetworkKey, ProductVerticalCreditCard } from "@bwll/bw-types";

/**
 * Returns true if the credit card is eligible for a special offer.
 * @param param0 The credit card.
 * @returns True if the credit card is eligible for a special offer, false if not.
 */
export const isSpecialOffer = ({ isEligibleForSpecialOffer }: ProductVerticalCreditCard) =>
  Boolean(isEligibleForSpecialOffer);

/**
 * Returns true if the credit card is of the provided card type.
 * @param cardType The card type to check for.
 * @param param1 The credit card.
 * @returns True if the credit card is of the provided card type, false if not.
 */
export const isCardType = (cardType: CreditCardCardTypeKeys, { cardTypes }: ProductVerticalCreditCard) =>
  cardTypes?.includes(cardType);

/**
 * Returns true if the credit card is of the provided network type.
 * @param networkType The network type to check for.
 * @param param1 The credit card.
 * @returns True if the credit card is of the provided network type, false if not.
 */
export const isNetworkType = (networkType: CreditCardNetworkKey, { network }: ProductVerticalCreditCard) =>
  network === networkType;

/**
 * Returns true if the credit card is a trending product
 * @param param1 The credit card.
 * @returns True if the credit card is a trending product, false if not.
 */
export const isTrendingProduct = ({ trendingOrder }: ProductVerticalCreditCard) =>
  trendingOrder !== null && trendingOrder !== undefined;
