import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

const treatments = {
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS].OFF]: {
    showFilters: false,
    showFilterButtonTitle: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS].V1]: {
    showFilters: false,
    showFilterButtonTitle: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS].V2]: {
    showFilters: true,
    showFilterButtonTitle: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS].V3]: {
    showFilters: true,
    showFilterButtonTitle: true,
  },
};

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceCreditCardFilters".
 */
export const useCreditCardFiltersExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_FILTERS]?.treatment ??
      "off";

    return (
      treatments[treatment] ?? {
        showFilters: false,
        showFilterButtonTitle: false,
      }
    );
  }, [experimentsMap.userConfig]);
};
