import React, { useRef, useState } from "react";
import { Pressable } from "react-native";

import { useBreakpoints, usePlatformContext } from "@bwll/bw-hooks";
import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { makeTestIdGenerator } from "@bwll/bw-utils";

import { Icon, IconNames } from "../../atoms/Icon";
import { Spacer } from "../../atoms/Spacer";
import { CardContainer } from "../../molecules/CardContainer";
import {
  BODY_TEST_ID,
  CTA_TEST_ID,
  ICON_TEST_ID,
  LINE_TEST_ID,
  NEW_BADGE_TEST_ID,
  PromoCardTemplateVariant,
  TITLE_TEST_ID,
} from "./PromoCardTemplate.constants";
import * as Styled from "./PromoCardTemplate.styles";
import type { PromoCardTemplateProps } from "./PromoCardTemplate.types";

export const PromoCardTemplate = ({
  variant = PromoCardTemplateVariant.card,
  title,
  icon,
  body,
  cta,
  ctaTitle,
  accordionCtaTitleCollapsed,
  accordionCtaTitleExpanded,
  accordionCta,
  accordionContent,
  isAccordionExpanded = false,
  testID,
  children,
  withLine = true,
  badgeText,
  onPress,
  onAccordionToggled,
  cardImageBackgroundImage,
  cardImageBackgroundColor,
  cardImageSource,
  cardImageProps,
}: PromoCardTemplateProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isAccordionExpanded);
  const { platform } = usePlatformContext();
  const { isDesktop } = useBreakpoints();

  const defaultTestId = useRef(`${Date.now()}`);

  const generateTestId = makeTestIdGenerator(testID || defaultTestId.current);

  const isAccordion = variant === PromoCardTemplateVariant.accordion;

  const isSnackbar = isAccordion || (variant === PromoCardTemplateVariant.snackbar && isDesktop);

  const toggleAccordion = () => {
    setIsAccordionOpen((prevState) => {
      const newState = !prevState;

      onAccordionToggled?.(newState);

      return newState;
    });
  };

  return (
    <CardContainer testID={testID}>
      {cardImageSource && !isSnackbar && (
        <>
          <Styled.CardImageContainer
            borderRadius={borderRadius.s}
            testID={`CardImageBackground_${testID}`}
            backgroundImageUrl={cardImageBackgroundImage}
            backgroundColor={cardImageBackgroundColor}
          >
            <Styled.CardImage testID={`CardImage_${testID}`} source={cardImageSource} {...cardImageProps} />
          </Styled.CardImageContainer>
        </>
      )}
      <Styled.Container isSnackbar={isSnackbar} isAccordion={isAccordion}>
        <Styled.ContentContainer isSnackbar={isSnackbar} isAccordion={isAccordion}>
          {badgeText && (
            <Styled.BadgeContainer testID={generateTestId(NEW_BADGE_TEST_ID)}>
              <Styled.BadgeText>{badgeText}</Styled.BadgeText>
            </Styled.BadgeContainer>
          )}
          {title && (
            <Styled.TitleContainer testID={generateTestId(TITLE_TEST_ID)}>
              {icon && (
                <Styled.IconContainer testID={generateTestId(ICON_TEST_ID)}>
                  <Icon icon={icon as IconNames} size={fontSize.s} color={COLORS.NEUTRAL.WARM["800"]} />
                </Styled.IconContainer>
              )}
              <Styled.TitleText>{title}</Styled.TitleText>
            </Styled.TitleContainer>
          )}
          {body && (
            <>
              {title ? <Spacer height={spacing.xxs} /> : null}
              <Styled.BodyContainer>
                {!title && icon && (
                  <Styled.IconContainer testID={generateTestId(ICON_TEST_ID)}>
                    <Icon icon={icon as IconNames} size={fontSize.s} color={COLORS.NEUTRAL.WARM["800"]} />
                  </Styled.IconContainer>
                )}
                <Styled.BodyText testID={generateTestId(BODY_TEST_ID)}>{body}</Styled.BodyText>
              </Styled.BodyContainer>
            </>
          )}
          {children}
        </Styled.ContentContainer>
        {!isSnackbar && withLine && <Styled.Line testID={generateTestId(LINE_TEST_ID)} />}
        {!isAccordion && cta}
        {!isAccordion && !cta && onPress && ctaTitle && (
          <Styled.CtaContainer onPress={onPress} isSnackbar={isSnackbar}>
            <Styled.CtaText platform={platform} testID={generateTestId(CTA_TEST_ID)}>
              {ctaTitle}
            </Styled.CtaText>
          </Styled.CtaContainer>
        )}
        {isAccordion && accordionContent && !accordionCtaTitleExpanded && !accordionCtaTitleCollapsed && (
          <Pressable onPress={toggleAccordion}>{accordionCta}</Pressable>
        )}
        {isAccordion && accordionContent && accordionCtaTitleExpanded && accordionCtaTitleCollapsed && (
          <Pressable onPress={toggleAccordion} testID={generateTestId(CTA_TEST_ID)}>
            <Styled.AccordionCtaContainer>
              <Styled.CtaText platform={platform}>
                {isAccordionOpen ? accordionCtaTitleExpanded : accordionCtaTitleCollapsed}
              </Styled.CtaText>
              <Icon
                icon={isAccordionOpen ? "arrow_up" : "arrow_down"}
                size={fontSize.s}
                color={COLORS.PRIMARY["500"]}
              />
            </Styled.AccordionCtaContainer>
          </Pressable>
        )}
      </Styled.Container>
      <Styled.AccordionContentContainer isOpen={isAccordionOpen}>
        {accordionContent}
      </Styled.AccordionContentContainer>
    </CardContainer>
  );
};
