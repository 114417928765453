import React from "react";

import { SkeletonVariants } from "./Skeleton.constants";
import * as Styled from "./Skeleton.styles";
import type { SkeletonProps, SkeletonVariant } from "./Skeleton.types";

const SkeletonOptions = {
  [SkeletonVariants.circle]: Styled.Circle,
  [SkeletonVariants.rect]: Styled.Rect,
  [SkeletonVariants.rounded]: Styled.Rounded,
} as const;

const getSkeletonByVariant = (variant: SkeletonVariant) => SkeletonOptions[variant];

/**
 * Component for showing the Skeleton (WEB-only)
 *
 * @component
 * @example
 * return (
 * <Skeleton
 *   width={100}
 *   height={20}
 *   variant="rounded"
 * />
 * )
 *
 * @param {SkeletonProps} props - Skeleton props
 * @property {SkeletonVariant} variant - The shape of Skeleton
 * @property {number | string} width - The width in pixels/percentage
 * @property {number | string} height - The height in pixels/percentage
 * @property {string} [testID] - Locate component in UI tests
 * @property {boolean} [animated] - Enable/disable "pulse" effect
 */
export const Skeleton = ({ variant, width, height, testID, animated = true }: SkeletonProps) => {
  const SkeletonComponent = getSkeletonByVariant(variant);

  return <SkeletonComponent data-testid={testID} animated={animated} width={width} height={height} />;
};
