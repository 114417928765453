import { ReactNode } from "react";

import { ThemeProvider } from "@emotion/react";
import { render } from "@testing-library/react";

import Chance from "chance";

import axe from "axe-core";

export const rendererWithTheme = {
  create: (component: React.ReactNode, theme?: Record<string, any>) =>
    render(<ThemeProvider theme={theme ?? {}}>{component}</ThemeProvider>),
};

export const chance = new Chance();

const defaultRunOptions: axe.RunOptions = {};

export const accessibilityTest = async (
  element: axe.ElementContext,
  options?: axe.RunOptions
): Promise<{ err: Error; violations: axe.Result[] }> => {
  const runOptions: axe.RunOptions = { ...defaultRunOptions, ...options };
  return new Promise((resolve) => {
    axe.run(element, runOptions, (err, { violations }) => {
      resolve({ err, violations });
    });
  });
};
