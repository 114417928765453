import styled from "@emotion/native";

import { COLORS, borderRadius } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const StepCounterWrapper = styled.View`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  align-items: center;
`;

export const ProgressBarCell = styled.View<{ filled: boolean }>`
  display: flex;
  flex: 1;
  width: 100%;
  height: 4px;
  border-radius: ${styledValue(borderRadius.xs)};
  background-color: ${({ filled }) => (filled ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL[200])};
`;
