/**
 * Key representation of credit card networks.
 */
export const CREDIT_CARD_NETWORK_KEYS = {
  VISA: "Visa",
  MASTERCARD: "Mastercard",
  AMERICAN_EXPRESS: "American Express",
} as const;

/**
 * Key representation of credit card networks.
 * @see {@link CREDIT_CARD_NETWORK_KEYS}
 */
export type CreditCardNetworkKey = (typeof CREDIT_CARD_NETWORK_KEYS)[keyof typeof CREDIT_CARD_NETWORK_KEYS];

/**
 * Key representation of card types for credit cards.
 */
export const CREDIT_CARD_CARD_TYPE_KEYS = {
  BALANCE_TRANSFER: "Balance Transfer",
  CASH_BACK: "Cash Back",
  CREDIT_BUILDER: "Credit Builder",
  NO_FEE: "No fee",
  REWARDS: "Rewards",
  TRAVEL: "Travel",
} as const;

/**
 *  Key representation of card types for credit cards.
 * @see {@link CREDIT_CARD_CARD_TYPE_KEYS}
 */
export type CreditCardCardTypeKeys =
  (typeof CREDIT_CARD_CARD_TYPE_KEYS)[keyof typeof CREDIT_CARD_CARD_TYPE_KEYS];
