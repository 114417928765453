import React, { useCallback, useMemo } from "react";
import { ActivityIndicator, Pressable } from "react-native";

import { TButtonAnalyticsAttributes } from "@bwll/bw-hooks";

/**
 * Note: The mobile app test environment doesn't see this file export in the root bwll/bw-hooks
 */
import { useAnalyticsContext } from "@bwll/bw-hooks/src/contexts/analytics/analyticsContext";
import { COLORS, spacing } from "@bwll/bw-styles";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

import { Icon } from "../Icon";
import { BUTTON_TEST_IDS } from "./Button.constants";
import {
  ButtonIconWrapper,
  ButtonSpaceHolder,
  ButtonTextColors,
  ButtonTextComponent,
  ButtonWrapper,
} from "./Button.styles";
import { ButtonPressableProps, ButtonProps, CombinedStylesCallback } from "./Button.types";
import { getButtonComponentStyleByType } from "./Button.utils";

/**
 * Component for showing the Button based on the style.
 * Accepts: Primary, Secondary, or Tertiary as type.
 * Setting disabled to true will use the Disabled styling as well as disable the button from being pressed
 */
type ButtonIconProps = Pick<ButtonProps, "iconName" | "iconAlignment"> & {
  iconColor: string;
};

const ButtonIcon = ({ iconAlignment = "right", iconName, iconColor }: ButtonIconProps) => (
  <ButtonIconWrapper testID={BUTTON_TEST_IDS.BUTTON_ICON} alignment={iconAlignment}>
    <Icon icon={iconName ?? "thumbs-up-outline"} size={spacing.xs} color={iconColor} />
  </ButtonIconWrapper>
);

const ButtonPressable = Pressable as React.ComponentType<ButtonPressableProps>;

export const Button = ({
  type,
  title,
  onPress,
  testID,
  accessibilityLabel,
  disabled,
  iconName,
  iconAlignment = "right",
  state = "light",
  isLoading = false,
  isAdded = false,
  analyticsAttributes: featureAnalyticsAttributes,
  /**
   * It is recommended to have enableAnalyticsEvent default value as `false` to avoid duplicate events with previous Analytics Events implemented previously.
   * Its value should come from the feature flag
   */
  enableAnalyticsEvent = false,
  dataSet,
  style,
  /**
   * Use `children` prop to render custom JSX elements like <Badge>, etc.
   */
  children,
}: ButtonProps & ButtonPressableProps) => {
  const buttonStyles = useMemo(
    () => getButtonComponentStyleByType({ type, disabled, state, isLoading, isAdded }),
    [type, state, disabled, isLoading, isAdded],
  );

  const { onButtonClick } = useAnalyticsContext();

  const combineStyles: CombinedStylesCallback = useCallback(
    ({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }, buttonStyles, style],
    [buttonStyles, style],
  );

  const iconColor = disabled
    ? COLORS.NEUTRAL.WARM["400"]
    : isAdded
    ? COLORS.NEUTRAL.WARM["700"]
    : ButtonTextColors[type][state];

  const buttonIconProps = {
    iconAlignment,
    iconName,
    iconColor,
  };

  const componentAnalyticsAttributes = useMemo<TButtonAnalyticsAttributes>(
    () => ({
      [ANALYTICS_ATTRIBUTES.BUTTON_NAME]: title ?? "",
    }),
    [title],
  );

  const onButtonPress = () => {
    enableAnalyticsEvent &&
      featureAnalyticsAttributes &&
      onButtonClick?.({ ...componentAnalyticsAttributes, ...featureAnalyticsAttributes });
    onPress();
  };

  return (
    <ButtonPressable
      onPress={onButtonPress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      testID={testID}
      disabled={disabled}
      style={combineStyles}
      dataSet={dataSet}
    >
      {isLoading && <ButtonSpaceHolder />}
      {(title || iconName) && (
        <ButtonWrapper isLoading={isLoading}>
          {iconName && iconAlignment === "left" && <ButtonIcon {...buttonIconProps} />}
          <ButtonTextComponent
            ellipsizeMode="clip"
            numberOfLines={1}
            type={type}
            disabled={disabled}
            state={state}
            isAdded={isAdded}
          >
            {title}
          </ButtonTextComponent>
          {iconName && iconAlignment === "right" && <ButtonIcon {...buttonIconProps} />}
        </ButtonWrapper>
      )}
      {isLoading && <ActivityIndicator />}
      {children}
    </ButtonPressable>
  );
};
