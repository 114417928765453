import { useEffect } from "react";
import { NativeEventEmitter, NativeModules } from "react-native";

import { usePlatformContext } from "@bwll/bw-hooks";
import { PLATFORM } from "@bwll/bw-types";
import { ENVIRONMENT_VARIABLES } from "@bwll/navbar/src/configs/environment-variables";

const RNRefiner = NativeModules.RNRefiner;
const RNRefinerEventEmitter = new NativeEventEmitter(RNRefiner);

export type IdentifyUserProps = {
  individualClientId: string;
  refinerSignature?: string;
};

export type UseRefinerProps = {
  webRefiner?: (...args: unknown[]) => void;
  onShowSurvey?: () => void;
  onHideSurvey?: () => void;
};

/**
 * Hook that handles everything refiner related.
 * @param {Function} webRefiner - The refiner.io client for web. Not needed for mobile.
 */
export const useRefiner = ({ webRefiner, onShowSurvey, onHideSurvey }: UseRefinerProps) => {
  const { platform } = usePlatformContext();
  const isMobile = platform === PLATFORM.MOBILE_APP;

  useEffect(() => {
    if (isMobile && RNRefiner) {
      RNRefinerEventEmitter.addListener("onBeforeShow", () => {
        onShowSurvey?.();
      });

      RNRefinerEventEmitter.addListener("onDismiss", () => {
        onHideSurvey?.();
      });

      RNRefinerEventEmitter.addListener("onClose", () => {
        onHideSurvey?.();
      });

      RNRefinerEventEmitter.addListener("onComplete", () => {
        onHideSurvey?.();
      });
    }
  }, [isMobile, onHideSurvey, onShowSurvey]);

  /**
   * Initializes Refiner.io client
   * @return {void}
   */
  const initializeRefiner = (envId: string): void => {
    if (isMobile) {
      RNRefiner?.initialize(envId, false);
    } else if (webRefiner && ENVIRONMENT_VARIABLES.REFINER_ENABLED) {
      webRefiner("setProject", envId);
    }
  };

  /**
   * Identifies the member.
   *
   * @param {IdentifyUserProps} data - The info about the member being identified.
   *
   * @return {void}
   */
  const identifyUser = (data: IdentifyUserProps, locale = "en"): void => {
    const { refinerSignature, ...contactAttributes } = data;

    if (isMobile) {
      RNRefiner?.identifyUser(data.individualClientId, data, locale, null);
    } else if (webRefiner && ENVIRONMENT_VARIABLES.REFINER_ENABLED) {
      webRefiner(
        "identifyUser",
        {
          id: contactAttributes.individualClientId,
          ...contactAttributes,
        },
        { signature: refinerSignature },
      );
    }
  };

  /**
   * Sends a screen view identifier to Refiner.io.
   * Documentation: https://github.com/refiner-io/mobile-sdk-react-native?tab=readme-ov-file#track-screen
   * Mobile Only!
   *
   * @param {string} routeName - The name of the route being tracked.
   *
   * @return {void}
   */
  const trackScreen = (routeName: string): void => {
    RNRefiner?.trackScreen(routeName);
  };

  /**
   * Shows a survey programmatically.
   * Documentation: https://refiner.io/docs/kb/javascript-client/reference/#show-ad-hoc
   *
   * @param {string} surveyId - The ID of the survey to be shown.
   * @param {boolean} forced - Whether we want to bypass trigger restrictions.
   *
   * @return {void}
   */
  const showSurvey = (surveyId: string, forced = false): void => {
    if (isMobile) {
      RNRefiner?.showForm(surveyId, forced);
    } else if (webRefiner && ENVIRONMENT_VARIABLES.REFINER_ENABLED) {
      webRefiner("showForm", surveyId, forced);
    }
  };

  /**
   * Purges the member data and instructs client to stop querying Refiner.io servers.
   * Web only!
   *
   * @return {void}
   */
  const dispose = (): void => {
    if (ENVIRONMENT_VARIABLES.REFINER_ENABLED && webRefiner) {
      webRefiner("resetUser");
      webRefiner("stopPinging");
    }
  };

  return {
    initializeRefiner,
    identifyUser,
    trackScreen,
    showSurvey,
    disposeRefinerClient: dispose,
  };
};
