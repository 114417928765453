import { useMemo } from "react";

import {
  useCreditBuildingProductsEligibility,
  useExperiments,
  useGetMortgages,
  useMarketplaceMortgagesExperiment,
  usePrimaryAddress,
} from "@bwll/bw-hooks";
import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

export const useUniversalNavigationBarRules = () => {
  // Check if member is in Quebec
  const { isQuebecMember } = usePrimaryAddress();

  // Get mortgages data
  const { data: mortgages } = useGetMortgages({ enabled: true });

  // Show only if member is eligible for Credit Builder.
  const shouldShowCreditBuilder = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.ENABLE_WEB_CBL_CREATION,
  });

  // Show only if member is eligible for Rent Advantage.
  const { isRentAdvantageEnabled: shouldShowRentAdvantage } = useCreditBuildingProductsEligibility();

  // Show only if member is in Marketplace Bank Accounts experiment
  const shouldShowBankAccountRedesign = useExperiments({
    experimentId: WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_BANK_ACCOUNTS,
    treatments: [
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V2,
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V3,
      WEB_TREATMENT_IDS.sspaMarketplaceBankAccounts.V4,
    ],
  });

  // Show only if member is in Marketplace mortgages experiment
  const shouldShowMortgagesRedesign = useMarketplaceMortgagesExperiment();

  // Show if member is eligible to Credit Builder or Rent Advantage.
  const shouldShowBuildCreditMenu = shouldShowCreditBuilder || shouldShowRentAdvantage;

  // Show only for non-quebec residents.
  const shouldShowCarLoans = !isQuebecMember;
  const shouldShowInvestments = isQuebecMember;

  // Show if member has mortgages available.
  const shouldShowMortgages = useMemo(
    () => (mortgages?.productVerticalMortgages ?? [])?.length > 0,
    [mortgages?.productVerticalMortgages],
  );

  return {
    shouldShowBuildCreditMenu,
    shouldShowCreditBuilder,
    shouldShowRentAdvantage,
    shouldShowCarLoans,
    shouldShowInvestments,
    shouldShowMortgages,
    shouldShowBankAccountRedesign,
    shouldShowMortgagesRedesign,
  };
};
