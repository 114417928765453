import axios from "axios";
import { useQuery } from "react-query";

import { CreditBuildingProducts, IApiHookOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

import { useEnvironmentContext, useSessionContext } from "../../../contexts";

interface ICreditBuildingEligibilityCheckResponse {
  eligibleCreditBuildingProducts: CreditBuildingProducts[];
}

const getQualifiedCreditBuildingProducts = async (accessToken: string, baseUrl: string) => {
  const response = await axios.get<ICreditBuildingEligibilityCheckResponse>(
    `${baseUrl}/api/product-verticals/qualification/credit-building-products`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useGetQualifiedCreditBuildingProducts = <TReturn = CreditBuildingProducts[]>(
  options: IApiHookOptions<CreditBuildingProducts[], TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_QUALIFIED_CREDIT_BUILDING_PRODUCTS, accessToken),
    () => getQualifiedCreditBuildingProducts(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken && !!options.enabled,
      }),
      select: (response) => response?.eligibleCreditBuildingProducts ?? [],
    },
  );
};
