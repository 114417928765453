import styled from "@emotion/native";

import { COLORS } from "@bwll/bw-styles";

import { Cell } from "../../atoms/Cell";

export const StyledCell = styled(Cell)`
  width: 100%;
  border: 1px solid ${COLORS.NEUTRAL.COOL["200"]};
`;
