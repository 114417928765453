import { differenceInMonths, differenceInYears } from "date-fns";

import {
  ApplicableProduct,
  MapRequestFormData,
  QUICK_APPLY_PARTNER_PRODUCTS,
  QuickApplyFormFields,
  QuickApplyPartner,
  QuickApplyProduct,
} from "@bwll/bw-types";
import { ResponseMapper } from "@bwll/bw-types";
import { ErrorReporting, formatDate, getCID, getImpressionedUrl } from "@bwll/bw-utils";

/**
 * Core Quick Apply fields that are common to (almost) all implementations.
 */
export const coreFields: QuickApplyFormFields[] = [
  "firstName",
  "lastName",
  "dob",
  "phone",
  "email",
  "address1",
  "address2",
  "city",
  "region",
  "postalCode",
];

/**
 * Transforms Time At Address from the Quick Apply form to a version Product Partnerships expects.
 * @param housingTimeAtAddress The original date from the form.
 * @returns A transformed version of time at address for Product Partnerships.
 */
export const getTimeAtAddress = (housingTimeAtAddress: Date): { months: number; years: number } => {
  const today = new Date();
  const months =
    differenceInMonths(today, housingTimeAtAddress) - differenceInYears(today, housingTimeAtAddress) * 12;
  const years = differenceInYears(today, housingTimeAtAddress);

  return { months, years };
};

/**
 * Gets whether the provided form schema contains additional information fields.
 * @param formSchema The partner's form schema.
 * @returns True if the schema contains additional information fields, false if not.
 */
export const schemaContainsAdditionalInfo = (formSchema: QuickApplyFormFields[]) => {
  const basicInfo = [...coreFields, "language"];
  return formSchema.filter((value) => !basicInfo.includes(value)).length > 0;
};

// Requests

/**
 * Maps form data to a common Quick Apply request body.
 * @param param0 The form data.
 * @returns A Quick Apply request body.
 */
export const mapCommonRequestBody = ({
  context: { profile, address, contact },
  housingMonthlyCost,
  housingStatus,
  housingTimeAtAddress,
  annualIncome,
  loanAmount,
  employmentStatus,
  springEmploymentStatus,
  monthlyIncome,
  employmentDuration,
  housingDuration,
  companyName,
  desiredVehicle,
}: MapRequestFormData) => {
  return {
    firstname: profile.fields.firstName,
    lastName: profile.fields.lastName,
    birthdate: profile.fields.birthdate.toISOString(),
    languagePreference: profile.fields.language.toUpperCase(),
    phoneNumber: contact.fields.phoneNumber,
    email: contact.fields.email,
    streetAddress: address.fields.streetAddress,
    streetAddress2: address.fields.streetAddress2,
    city: address.fields.city,
    regionId: address.fields.regionId,
    postalCode: address.fields.postalCode,
    monthlyHousingCost: housingMonthlyCost,
    housingStatus,
    timeAtAddress: housingTimeAtAddress && getTimeAtAddress(housingTimeAtAddress),
    annualIncome,
    loanAmount,
    productImpressionToken: "",
    productReferenceNumber: 0,
    lendingArchEmploymentStatus: employmentStatus,
    springEmploymentStatus,
    employmentMonthlyIncome: monthlyIncome,
    lendingArchEmploymentDuration: employmentDuration,
    lendingArchResidenceDuration: housingDuration,
    companyName,
    desiredVehicle,
  };
};

/**
 * Maps form data to a Fairstone Quick Apply request body.
 * @param formData The form data.
 * @returns A Fairstone Quick Apply request body.
 */
export const mapFairstoneRequestBody = (formData: MapRequestFormData) => {
  const request = mapCommonRequestBody(formData);

  const {
    context: {
      profile: {
        fields: { birthdate },
      },
      contact: {
        fields: { phoneNumber },
      },
    },
  } = formData;

  const num = phoneNumber.toString().replace(/[^0-9]+/g, "");

  request.birthdate = formatDate({
    dateString: birthdate.toISOString(),
    dateFormat: "yyyy-MM-dd",
    ignoreOffset: true,
  });
  request.phoneNumber = `${num.slice(0, 3)}-${num.slice(3, 6)}-${num.slice(6, 10)}`;

  return request;
};

/**
 * Maps form data to an Autoloans/Carloans Quick Apply request body.
 * @param formData the form data.
 * @param websiteDomain the website domain for the product, either Autoloans.ca or Carloans.ca
 * @returns an Autoloans/Carloans Quick Apply request body.
 */
export const mapAutoLoansRequestBody = (formData: MapRequestFormData, websiteDomain: string) => ({
  ...mapCommonRequestBody(formData),
  websiteDomain,
});

// Responses

const isCapitalOneResponse = (data: unknown): data is { redirectUrl: string } => {
  return typeof data === "object" && data !== null && "redirectUrl" in data;
};

export const mapCapitalOneResponse: ResponseMapper = (data, product, individualClientIdReferenceNumber) => {
  if (isCapitalOneResponse(data)) {
    const redirectUrl = new URL(data.redirectUrl);
    redirectUrl.searchParams.append(
      "subId1",
      getCID(individualClientIdReferenceNumber, product.productImpressionId),
    );
    return { redirectUrl: redirectUrl.href };
  }

  return { error: "Response could not be mapped to a Capital One response." };
};

const isSpringResponse = (data: unknown): data is { data: { redirectUrl: string; id: string } } => {
  return (
    typeof data === "object" &&
    data !== null &&
    "data" in data &&
    typeof data.data === "object" &&
    data.data !== null &&
    "redirectUrl" in data.data &&
    "id" in data.data
  );
};

export const mapSpringResponse: ResponseMapper = (data, product, individualClientIdReferenceNumber) => {
  if (!isSpringResponse(data))
    return { error: "Response could not be mapped to a Spring Financial response." };

  try {
    const redirect = new URL(data.data.redirectUrl);
    redirect.searchParams.append("id", data.data.id);

    const { impressionedUrl: impressioned } = getImpressionedUrl(
      product.websiteLink,
      individualClientIdReferenceNumber,
      product.productImpressionId,
    );
    if (impressioned === null) return { redirectUrl: redirect.href };

    const impressionedUrl = new URL(impressioned);
    impressionedUrl.searchParams.forEach((value, name) => redirect.searchParams.set(name, value));

    return { redirectUrl: redirect.href };
  } catch (error) {
    return { error: "An error occurred constructing the Spring Financial redirect URL." };
  }
};

const isAutoLoansResponse = (data: unknown): data is { success: true } => {
  return (
    typeof data === "object" &&
    data !== null &&
    "success" in data &&
    typeof data.success === "boolean" &&
    data.success
  );
};

export const mapAutoLoansResponse: ResponseMapper = (data) => {
  if (isAutoLoansResponse(data)) {
    return {
      successDescriptionKey: "autoloans",
    };
  }

  return { error: "Response could not be mapped to an Auto Loans response." };
};

export const mapFairstoneResponse: ResponseMapper = (data) => {
  if (typeof data === "string") {
    return { redirectUrl: data };
  }

  return { error: "Response could not be mapped to a Fairstone response." };
};

const isCarsfastResponse = (data: unknown): data is { status: number; statusText: string } => {
  return (
    typeof data === "object" &&
    data !== null &&
    "status" in data &&
    typeof data.status === "number" &&
    "statusText" in data &&
    typeof data.statusText === "string"
  );
};

export const mapCarsfastResponse: ResponseMapper = (data) => {
  if (isCarsfastResponse(data)) {
    return data.status === 0
      ? { error: "Carsfast rejected the application." }
      : { successDescriptionKey: "carsfast" };
  }

  return { error: "Response could not be mapped to a Carsfast response." };
};

export const getQuickApplyProductPartner = (
  product: QuickApplyProduct | ApplicableProduct,
): QuickApplyPartner => {
  const quickApplyProduct =
    QUICK_APPLY_PARTNER_PRODUCTS[product.productVerticalId ?? ""]?.[product.productReferenceNumber ?? -1] ??
    "";

  if (!quickApplyProduct) {
    ErrorReporting.logError(new Error("Quick apply partner for product was not found"), {
      message: "Quick apply partner for product was not found",
      productId: product.id,
      knackId: product.productReferenceNumber,
    });
  }

  return quickApplyProduct;
};
