import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  ANALYTICS_ATTRIBUTES,
  ImpressionedProductVerticalBankAccount,
  MARKETPLACE_ANALYTICS_ORIGINS,
  TrackEventFuncAsync,
} from "@bwll/bw-types";
import { mapBankAccountToProductEventProps } from "@bwll/bw-utils";

import { useMarketplaceAnalyticsEvents } from "./useMarketplaceAnalyticsEvents";

const pageEventProps = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.DISPLAY_ORIENTATION]: "vertical",
} as const;

const dropdownProps = {
  [ANALYTICS_ATTRIBUTES.DROPDOWN_CONTEXT]: "product_catalog",
  [ANALYTICS_ATTRIBUTES.DROPDOWN_NAME]: "sort_by",
  [ANALYTICS_ATTRIBUTES.VERTICAL]: "Bank Accounts",
} as const;

export const useBankAccountProductListAnalytics = (trackEvent: TrackEventFuncAsync) => {
  const {
    productViewed,
    productClicked,
    dropdownClicked,
    dropdownOptionClicked,
    filterSelected,
    buttonClicked,
  } = useMarketplaceAnalyticsEvents(trackEvent);

  return useMemo(
    () => ({
      trackProductViewed: (card: ImpressionedProductVerticalBankAccount, index: number) => {
        productViewed({
          ...mapBankAccountToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
        });
      },
      trackProductClicked: (
        card: ImpressionedProductVerticalBankAccount,
        index: number,
        numberOfProductsShowing: number,
        filterValue: string,
      ) => {
        productClicked({
          ...mapBankAccountToProductEventProps(card),
          ...pageEventProps,
          [ANALYTICS_ATTRIBUTES._CLICK_ID]: uuidv4(),
          [ANALYTICS_ATTRIBUTES.DISPLAY_ORDER]: index + 1,
          [ANALYTICS_ATTRIBUTES.NUMBER_OF_PRODUCTS_SHOWN]: numberOfProductsShowing,
          [ANALYTICS_ATTRIBUTES.FILTER_SELECTED]: filterValue,
        });
      },
      trackButtonClicked: (card: ImpressionedProductVerticalBankAccount, destinationPath?: string) => {
        const { impression_id, tracking_tag } = mapBankAccountToProductEventProps(card);
        return buttonClicked({
          impression_id,
          tracking_tag,
          [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: "Details",
          [ANALYTICS_ATTRIBUTES.DESTINATION_URL]: destinationPath ?? "",
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "Bank Accounts",
          [ANALYTICS_ATTRIBUTES.BUTTON_CONTEXT]: MARKETPLACE_ANALYTICS_ORIGINS.BANK_ACCOUNTS_DETAILS,
        });
      },
      trackDropdownClicked: () => {
        dropdownClicked({
          ...dropdownProps,
        });
      },
      trackDropdownOptionClicked: (optionValue: string) => {
        dropdownOptionClicked({
          ...dropdownProps,
          [ANALYTICS_ATTRIBUTES.OPTION_VALUE]: optionValue,
        });
      },
      trackFilterSelected: (filterValue: string) => {
        filterSelected({
          [ANALYTICS_ATTRIBUTES.FILTER_NAME]: "Bank Account Type",
          [ANALYTICS_ATTRIBUTES.FILTER_CATEGORY]: "product_catalog_filter_chips",
          [ANALYTICS_ATTRIBUTES.FILTER_LOCATION]: "product_catalog",
          [ANALYTICS_ATTRIBUTES.VERTICAL]: "Bank Accounts",
          [ANALYTICS_ATTRIBUTES.FILTER_VALUE]: filterValue,
        });
      },
    }),
    [productClicked, productViewed, dropdownClicked, dropdownOptionClicked, filterSelected],
  );
};
