import styled, { css } from "@emotion/native";

import { COLORS, borderRadius, fontSize, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Heading2 } from "../../atoms/Typography";

const BUTTON_SIZE = 40;

export const CONTROL_ARROW_COLOR = COLORS.PRIMARY.DEFAULT;

const rowCenter = css`
  flex-direction: row;
  align-items: center;
`;

export const flatListContainerStyles = css`
  flex-grow: 1;
`;

export const flatListStyles = css`
  width: 100%;
  overscroll-behavior-y: auto;
`;

const CAROUSE_CARDS_GAP = spacing.xl;

export const Row = styled.View<{ isMobileApp: boolean }>`
  ${rowCenter};
  justify-content: space-between;
  width: 100%;
  padding-horizontal: ${({ isMobileApp }) =>
    styledValue(isMobileApp ? Math.floor(CAROUSE_CARDS_GAP / 2) : spacing.none)};
`;

export const TitleContainer = styled.View`
  ${rowCenter};
  gap: 10px;
`;

export const TitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${styledValue(fontSize.m)};
`;

export const ContentContainer = styled.View`
  width: 100%;
  margin-vertical: ${styledValue(spacing.xs)};
`;

/**
 * Swipe gesture makes the area between cards visible to the user in the mobile app.
 * We need to adjust the width on in this case to create a gap between carousel cards.
 */
export const ItemContainer = styled.View<{ width: number; isMobileApp: boolean }>`
  width: ${({ width, isMobileApp }) => styledValue(isMobileApp ? width - CAROUSE_CARDS_GAP : width)};
  margin-horizontal: ${({ isMobileApp }) => styledValue(isMobileApp ? CAROUSE_CARDS_GAP / 2 : spacing.none)};
  background-color: ${COLORS.WHITE};
  border-radius: ${styledValue(borderRadius.m)};
  border: 1px solid ${COLORS.NEUTRAL.COOL["200"]};
  padding: ${styledValue(spacing.xs)};
`;

export const ControlsContainer = styled.View<{ justifyContent: "space-between" | "center" }>`
  ${rowCenter};
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export const ArrowButton = styled.Pressable`
  height: ${styledValue(BUTTON_SIZE)};
  width: ${styledValue(BUTTON_SIZE)};
  border-radius: ${styledValue(BUTTON_SIZE / 2)};
  padding: 4px;
  border: 1px solid ${CONTROL_ARROW_COLOR};
`;

export const indicatorStyles = css`
  width: ${styledValue(spacing.xxxs)};
  height: ${styledValue(spacing.xxxs)};
  border-radius: ${styledValue(spacing.xxxs)};
  margin-horizontal: ${styledValue(spacing.xxxs)};
  background-color: ${CONTROL_ARROW_COLOR};
`;

export const IndicatorsContainer = styled.View`
  margin-horizontal: ${styledValue(spacing.xl)};
  flex-shrink: 1;
`;
