import { LIKELIHOOD_OF_APPROVAL, PRODUCT_VERTICAL } from "../types";

export enum PRODUCT_VERTICAL_NAMES {
  LOAN = "Loan",
  CREDIT_CARD = "CreditCard",
  CAR_LOAN = "CarLoan",
  INSURANCE = "Insurance",
  MORTGAGE = "Mortgage",
  BANK_ACCOUNT = "BankAccounts",
  INVESTMENTS = "Investments",
  DEFAULT = "Default",
}

export enum PRODUCT_VERTICAL_ID {
  DEFAULT = 0,
  CREDIT_CARD = 1,
  LOAN = 2,
  CAR_LOAN = 2,
  INSURANCE = 3,
  MORTGAGE = 4,
  BANK_ACCOUNT = 5,
  INVESTMENTS = 6,
}

/**
 * Represents Product Vertical IDs.
 */
export enum PRODUCT_VERTICAL_GUID {
  BANK_ACCOUNT = "20cac0eb-875b-45cd-86d7-ccad1139fa68",
  CAR_LOAN = "7a881deb-bc3d-4f75-a58a-1546f5ea40fe+carloans",
  CREDIT_CARD = "7ac89b33-529d-4898-9c8a-18a259be72c4",
  INSURANCE = "3261ce48-34b2-4c92-a797-5fbd344f922f",
  INVESTMENT = "544b471f-efe8-4d7c-9835-5b17c2fcef35",
  LOAN = "7a881deb-bc3d-4f75-a58a-1546f5ea40fe",
  MORTGAGE = "ab5ee24e-3edc-4aae-99cf-fcba0bfb06ee",
}

export const PRODUCT_VERTICAL_ID_TO_NAMES_MAPPING = {
  0: "default",
  1: "creditcards",
  2: "loans",
  3: "insurance",
  4: "mortgages",
  5: "investments",
  6: "bankaccounts",
  7: "buildcredit",
} as const;

export const PRODUCT_VERTICAL_GUID_TO_NAMES_MAPPING = {
  [PRODUCT_VERTICAL_GUID.CREDIT_CARD]: "creditcards",
  [PRODUCT_VERTICAL_GUID.LOAN]: "loans",
  [PRODUCT_VERTICAL_GUID.CAR_LOAN]: "loans",
  [PRODUCT_VERTICAL_GUID.INSURANCE]: "insurance",
  [PRODUCT_VERTICAL_GUID.MORTGAGE]: "mortgages",
  [PRODUCT_VERTICAL_GUID.INVESTMENT]: "investments",
  [PRODUCT_VERTICAL_GUID.BANK_ACCOUNT]: "bankaccounts",
} as const;

export enum DEEPLINK_PRODUCT_TYPE_ID {
  DEFAULT = 0,
  CREDIT_CARD = 1,
  LOAN = 2,
  INSURANCE = 3,
  MORTGAGE = 4,
  BANK_ACCOUNT = 5,
  INVESTMENTS = 6,
  CAR_LOAN = 7,
}

export const PRODUCT_VERTICAL_FULL_DETAILS_PATH = {
  [PRODUCT_VERTICAL_NAMES.DEFAULT]: `#`,
  [PRODUCT_VERTICAL_NAMES.LOAN]: `#/app/product/loans`,
  [PRODUCT_VERTICAL_NAMES.CREDIT_CARD]: `#/app/product/creditcards`,
  [PRODUCT_VERTICAL_NAMES.BANK_ACCOUNT]: `#/app/product/bankaccounts`,
  [PRODUCT_VERTICAL_NAMES.CAR_LOAN]: `#/app/product/carloans`,
  [PRODUCT_VERTICAL_NAMES.INSURANCE]: `#/app/product/insurance`,
  [PRODUCT_VERTICAL_NAMES.INVESTMENTS]: `#/app/product/investments`,
  [PRODUCT_VERTICAL_NAMES.MORTGAGE]: `#/app/product/mortgages`,
};

export const PRODUCT_VERTICAL_NAMES_TO_QUICK_APPLY_FORM_URL_PATH = {
  [PRODUCT_VERTICAL_NAMES.DEFAULT]: `#`,
  [PRODUCT_VERTICAL_NAMES.LOAN]: `#/app/product-partnership/loans`,
  [PRODUCT_VERTICAL_NAMES.CREDIT_CARD]: `#/app/product-partnership/creditcards`,
  [PRODUCT_VERTICAL_NAMES.BANK_ACCOUNT]: `#/app/product-partnership/bankaccounts`,
  [PRODUCT_VERTICAL_NAMES.CAR_LOAN]: `#/app/product-partnership/carloans`,
  [PRODUCT_VERTICAL_NAMES.INSURANCE]: `#/app/product-partnership/insurance`,
  [PRODUCT_VERTICAL_NAMES.INVESTMENTS]: `#/app/product-partnership/investments`,
  [PRODUCT_VERTICAL_NAMES.MORTGAGE]: `#/app/product-partnership/mortgages`,
};

export const PROMOTION_PRODUCT_VERTICAL_ID = {
  0: PRODUCT_VERTICAL_NAMES.DEFAULT,
  1: PRODUCT_VERTICAL_NAMES.CREDIT_CARD,
  2: PRODUCT_VERTICAL_NAMES.LOAN,
  3: PRODUCT_VERTICAL_NAMES.INSURANCE,
  4: PRODUCT_VERTICAL_NAMES.MORTGAGE,
  5: PRODUCT_VERTICAL_NAMES.INVESTMENTS,
  6: PRODUCT_VERTICAL_NAMES.BANK_ACCOUNT,
};

export const DEEPLINK_PRODUCT_VERTICAL_ID = {
  [DEEPLINK_PRODUCT_TYPE_ID.DEFAULT]: PRODUCT_VERTICAL_NAMES.DEFAULT,
  [DEEPLINK_PRODUCT_TYPE_ID.CREDIT_CARD]: PRODUCT_VERTICAL_NAMES.CREDIT_CARD,
  [DEEPLINK_PRODUCT_TYPE_ID.LOAN]: PRODUCT_VERTICAL_NAMES.LOAN,
  [DEEPLINK_PRODUCT_TYPE_ID.INSURANCE]: PRODUCT_VERTICAL_NAMES.INSURANCE,
  [DEEPLINK_PRODUCT_TYPE_ID.MORTGAGE]: PRODUCT_VERTICAL_NAMES.MORTGAGE,
  [DEEPLINK_PRODUCT_TYPE_ID.INVESTMENTS]: PRODUCT_VERTICAL_NAMES.INVESTMENTS,
  [DEEPLINK_PRODUCT_TYPE_ID.BANK_ACCOUNT]: PRODUCT_VERTICAL_NAMES.BANK_ACCOUNT,
  [DEEPLINK_PRODUCT_TYPE_ID.CAR_LOAN]: PRODUCT_VERTICAL_NAMES.CAR_LOAN,
};

export const PRODUCT_VERTICAL_STRING_TO_ID = {
  [PRODUCT_VERTICAL_NAMES.DEFAULT]: PRODUCT_VERTICAL_ID.DEFAULT,
  [PRODUCT_VERTICAL_NAMES.CREDIT_CARD]: PRODUCT_VERTICAL_ID.CREDIT_CARD,
  [PRODUCT_VERTICAL_NAMES.LOAN]: PRODUCT_VERTICAL_ID.LOAN,
  [PRODUCT_VERTICAL_NAMES.CAR_LOAN]: PRODUCT_VERTICAL_ID.CAR_LOAN,
  [PRODUCT_VERTICAL_NAMES.INSURANCE]: PRODUCT_VERTICAL_ID.INSURANCE,
  [PRODUCT_VERTICAL_NAMES.MORTGAGE]: PRODUCT_VERTICAL_ID.MORTGAGE,
  [PRODUCT_VERTICAL_NAMES.INVESTMENTS]: PRODUCT_VERTICAL_ID.INVESTMENTS,
  [PRODUCT_VERTICAL_NAMES.BANK_ACCOUNT]: PRODUCT_VERTICAL_ID.BANK_ACCOUNT,
};

export interface PvPreSelectedProduct {
  amount: number;
  approvalChance: LIKELIHOOD_OF_APPROVAL;
  bannerTitle: string;
  interestRateText: string | undefined;
  isQuickApply: boolean;
  likelihoodOfApproval: number;
  likelihoodOfApprovalType: number;
  modalCTAButtonText: string;
  modalClarification: string;
  modalClarificationHeader: string;
  modalDescription: string;
  modalFooterText: string;
  modalSteps: string;
  modalStepsHeader: string;
  modalTitle: string;
  partner: string;
  preSelectedLink: string;
  preSelectionAvailable: boolean;
  preselectionCardBackgroundImageUrl: string;
  preselectionCardDescription: string;
  preselectionCardImageUrl: string;
  preselectionCardTitle: string;
  productImageUrl: string;
  productName: string;
  productReferenceId: string;
  productReferenceNumber: number;
  term: string | undefined;
  toolTipText: string;
  toolTipTitle: string;
  vertical: PRODUCT_VERTICAL;
  websiteLink: string;
}
