import { useMemo } from "react";

import { AnalyticsEventProps, TFeatureAnalyticsAttributes } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, DASHBOARD_ANALYTICS_ORIGINS, EVENT_LABEL } from "@bwll/bw-types";

const eventCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.PLACEMENT]: DASHBOARD_ANALYTICS_ORIGINS.DASHBOARD,
};

export const usePopupTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      popupViewed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(EVENT_LABEL.POPUP_BUTTON_VIEWED, customAttributes);
      },

      popupClicked: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(EVENT_LABEL.POPUP_BUTTON_CLICKED, customAttributes);
      },

      popupDismissed: <T>(eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(EVENT_LABEL.POPUP_DISMISSED, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
