import _refiner from "refiner-js";

import { UniversalNavigationBar } from "@bwll/bw-components/next";

import { useSetupRefinerWeb } from "@app/hooks/useSetupWebRefiner";
import { useUniversalNavigationBarLinks } from "@app/hooks/useUniversalNavigationBarLinks";

/**
 * Renders the wrapper component for the navigation bar.
 * @returns The JSX element representing the navigation bar wrapper.
 */
export const NavigationBarWrapper = () => {
  useSetupRefinerWeb(_refiner);
  const { logo, links, profile, promo } = useUniversalNavigationBarLinks();

  return (
    <UniversalNavigationBar
      logo={logo}
      links={links}
      profile={profile}
      placeholder="Borrowell"
      promo={promo}
      testID="universal-navigation-bar"
    />
  );
};
