export const rentReportingCard = {
  title: "Rent Advantage",
  titleTM: "Rent Advantage™",
  reportedDateTitle: "DATE",
  dateReported: "DATE REPORTED",
  reportedAmountTitle: "AMOUNT",
  statusTitle: "STATUS",
  cta: "VIEW DETAILS",
  learnMore: "LEARN MORE",
  amountPlaceholder: "—",
  bodyTextNotSubscribed: "Report your rent on your Equifax Canada credit report and start building your credit history!",
};
