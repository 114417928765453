import { useEffect, useRef, useState } from "react";
import React from "react";

import { StrokeLine, Wrapper } from "./StrikeThrough.styles";
import { StrikeThroughProps } from "./StrikeThrough.types";

/*
 * This function used the Right Triangles formula to calculate the bottom left corner angle to create a diagonal stroke according to the size of the child component.
 */
function calculateStrokeAngle({ width, height }: { width: number; height: number }) {
  return Math.ceil(Math.atan(height / width) * (180 / Math.PI));
}

/**
 * Component for showing the StrikeThrough.
 *
 * @component
 * @example
 * return (
 *   <StrikeThrough testID={"test-id-here"} color={COLORS.RED['700']} strokeWidth={2} shouldWrap={false}>
 *      <Body1 color={COLORS.NEUTRAL.WARM["700"]} addMargin>Hello World</Body1>
 *   <StrikeThrough/>
 * )
 */

export const StrikeThrough = ({ children, testId, color, strokeWidth, shouldWrap }: StrikeThroughProps) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const { offsetWidth, offsetHeight } = (ref.current as never) ?? {};
    if (offsetWidth && offsetHeight) {
      setWidth(offsetWidth);
      setHeight(offsetHeight);
      setAngle(calculateStrokeAngle({ width: offsetWidth, height: offsetHeight }));
    }
  }, []);

  /**
   * This logic helps preventing like break for the child components.
   */
  const childrenWithProps = React.cloneElement(children, {
    ...(!shouldWrap && { numberOfLines: 1 }),
  });

  return (
    <Wrapper ref={ref}>
      {childrenWithProps}
      <StrokeLine
        testID={testId}
        angle={angle}
        width={width}
        height={height}
        color={color}
        strokeWidth={strokeWidth}
      />
    </Wrapper>
  );
};
