import React from "react";
import { Pressable, View } from "react-native";

import { COLORS, fontSize, spacing } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";
import { ICON_TAG_TEST_IDS } from "./IconTag.constants";
import { IconTagContainer, IconTagLabel } from "./IconTag.styles";
import { IconTagProps } from "./IconTag.types";

/**
 * Component for showing the IconTag.
 *
 * @component
 * @example
 * return (
 * <IconTag
 *   backgroundColor="COLORS.PURPLE["050"]"
 *   icon="hollow_star"
 * >
 *   Tag text goes here
 * </IconTag>
 * )
 */
export const IconTag = ({
  backgroundColor,
  icon,
  children,
  onPress,
  borderColor,
  borderRadius,
  ignoreIconColor = false,
  iconColor = COLORS.NEUTRAL.WARM["700"],
  ...props
}: IconTagProps) => {
  return (
    <Pressable onPress={onPress} disabled={!onPress} {...props}>
      <IconTagContainer
        paddingHorizontal={spacing.xxs}
        paddingVertical={spacing.xxxs}
        borderColor={borderColor}
        borderRadius={borderRadius ?? spacing.xs}
        backgroundColor={backgroundColor}
      >
        <View testID={`${ICON_TAG_TEST_IDS.ICON}-${icon}`}>
          <Icon icon={icon} color={ignoreIconColor ? undefined : iconColor} size={fontSize.s} />
        </View>
        {typeof children === "string" ? (
          <IconTagLabel textDecoration={onPress ? "underline" : "none"}>{children}</IconTagLabel>
        ) : (
          children
        )}
      </IconTagContainer>
    </Pressable>
  );
};
