import i18next from "i18next";

import { ProductVerticalLoan, ProductVerticalLoanWithImpressionId } from "@bwll/bw-types";

import { formatCurrencyDollar } from "../formatCurrencyDollar";
import { uuid } from "../uuid";
import { getUrlWithCID } from "./getUrlWithCID";

export const transformAutoLoansProduct = (
  data: { products: ProductVerticalLoan[] },
  individualClientIdReferenceNumber: number,
): ProductVerticalLoanWithImpressionId[] => {
  const rawData = data.products.map((product) => {
    const imageSource = { uri: product.productImageUrl || product.companyLogo || "" };
    const formattedInterestRate: string =
      product.interestRateText || i18next.t("productCatalog:autoLoans:interestRatePlaceholder");
    const formattedMonthlyPaymentEstimate = formatCurrencyDollar({
      amount: product.monthlyPaymentEstimate,
    });

    const productImpressionId = uuid();

    return {
      ...product,
      imageSource,
      formattedInterestRate,
      formattedMonthlyPaymentEstimate,
      productImpressionId,
      websiteLink: !product.websiteLink
        ? product.websiteLink
        : getUrlWithCID(product.websiteLink, individualClientIdReferenceNumber, productImpressionId),
    };
  });

  return rawData;
};
