import { BREAKPOINTS } from "@bwll/bw-styles";

export const BREAKPOINT_NAMES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
} as const;

export type Breakpoint = (typeof BREAKPOINT_NAMES)[keyof typeof BREAKPOINT_NAMES];

export const breakpoints: {
  name: Breakpoint;
  maxWidth?: number | undefined;
  minWidth?: number | undefined;
}[] = [
  { name: BREAKPOINT_NAMES.MOBILE, maxWidth: BREAKPOINTS.MOBILE_MAXIMUM_WIDTH },
  { name: BREAKPOINT_NAMES.TABLET, maxWidth: BREAKPOINTS.TABLET_MAXIMUM_WIDTH },
  { name: BREAKPOINT_NAMES.DESKTOP, minWidth: BREAKPOINTS.DESKTOP_MINIMUM_WIDTH },
];

export const breakpointsMap = {
  [BREAKPOINT_NAMES.MOBILE]: BREAKPOINTS.MOBILE_MAXIMUM_WIDTH,
  [BREAKPOINT_NAMES.TABLET]: BREAKPOINTS.TABLET_MAXIMUM_WIDTH,
  [BREAKPOINT_NAMES.DESKTOP]: BREAKPOINTS.DESKTOP_MINIMUM_WIDTH,
} as const;
