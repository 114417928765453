import React from "react";
import { Animated, StyleSheet, View, ViewStyle } from "react-native";

import { spacing } from "@bwll/bw-styles";

export interface IProps {
  currentPosition: Animated.AnimatedValue;
  getInputRange: (position: number) => number[];
  style?: ViewStyle;
  testID?: string;
}

export interface IIndicatorsProps {
  numItems: number;
  indicatorStyle?: ViewStyle;
}

interface IIndicatorProps {
  position: number;
  style?: ViewStyle;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: spacing.xxs,
  },

  singleElementContainer: {
    justifyContent: "center",
  },

  overflowedElementsContainer: {
    width: "100%",
    justifyContent: "space-between",
  },

  indicator: {
    height: spacing.xxs,
    borderRadius: spacing.xxxs,
  },

  overflowedIndicator: {
    marginHorizontal: undefined,
  },
});

const Indicator: React.FC<IProps & IIndicatorProps> = ({
  position,
  getInputRange,
  currentPosition,
  style,
}) => {
  const scaleAnimation = currentPosition.interpolate({
    inputRange: getInputRange(position),
    outputRange: [1, 1.5, 1],
    extrapolate: "clamp",
  });

  const opacityAnimation = currentPosition.interpolate({
    inputRange: getInputRange(position),
    outputRange: [0.25, 1, 0.25],
    extrapolate: "clamp",
  });
  return (
    <Animated.View
      style={[
        styles.indicator,
        {
          opacity: opacityAnimation,
          transform: [{ scaleX: scaleAnimation }, { scaleY: scaleAnimation }],
        },
        style,
      ]}
    />
  );
};

export const Indicators: React.FC<IProps & IIndicatorsProps> = ({
  numItems,
  getInputRange,
  currentPosition,
  indicatorStyle,
  style,
}) => {
  const isSingleIndicator = numItems <= 1;
  const isOverflowed = numItems > 6;

  return (
    <View
      style={[
        styles.container,
        isOverflowed
          ? styles.overflowedElementsContainer
          : isSingleIndicator
          ? styles.singleElementContainer
          : undefined,
        style,
      ]}
    >
      {new Array(numItems).fill(true).map((_, i) => (
        <Indicator
          key={`indicator-${i}`}
          position={i}
          getInputRange={getInputRange}
          currentPosition={currentPosition}
          style={StyleSheet.flatten([isOverflowed ? styles.overflowedIndicator : undefined, indicatorStyle])}
        />
      ))}
    </View>
  );
};

export default Indicators;
