import React from "react";

import { StyledSpacer } from "./Spacer.styles";
import { SpacerProps } from "./Spacer.types";

/**
 * Component for showing the Spacer.
 *
 * @component
 * @example
 * return (
 *   <Spacer height={spacing.xxs} />
 * )
 */
export const Spacer = (props: SpacerProps) => <StyledSpacer {...props} />;
