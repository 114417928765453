import { useMemo } from "react";

import { CreditScore, CreditScoreChangeDirection, CreditScoreChangeDirectionType } from "@bwll/bw-types";
import { getFirstPreferredName, uuid } from "@bwll/bw-utils";

import { useTransitionGetMember } from "../member";

export type CurrentCreditScoreData = {
  scorePulledDate: Date;
  nextRefreshDate: Date;
  scoreChangeDirection: CreditScoreChangeDirectionType;
  scoreChangePoints: number;
  scoreCurrentPoints: number;
  isLoading: boolean;
  isError: boolean;
  userFirstName: string;
};

const dateFallback = new Date();
const creditScoresFallback: CreditScore[] = [];
const creditScoreFallback: CreditScore = {
  date: dateFallback.toISOString(),
  id: uuid(),
  score: 0,
};

export const useCurrentCreditScore = (): CurrentCreditScoreData => {
  const { data: memberData, isLoading, isError } = useTransitionGetMember();

  const userFirstName = getFirstPreferredName(memberData?.individual) || "";

  const creditScores = memberData?.creditScores || creditScoresFallback;
  const creditScoresListLength = creditScores.length;

  const currentScore = creditScores[creditScoresListLength - 1] || creditScoreFallback;
  const previousScore = creditScores[creditScoresListLength - 2] || creditScoreFallback;

  const scoreCurrentPoints = currentScore.score;
  const scoreChangePoints = scoreCurrentPoints - previousScore.score;

  let scoreChangeDirection: CreditScoreChangeDirectionType = CreditScoreChangeDirection.Neutral;

  if (scoreChangePoints > 0) {
    scoreChangeDirection = CreditScoreChangeDirection.Up;
  } else if (scoreChangePoints < 0) {
    scoreChangeDirection = CreditScoreChangeDirection.Down;
  }

  const scorePulledDate = useMemo(() => new Date(currentScore.date), [currentScore.date]);

  return useMemo(
    () => ({
      scorePulledDate,
      nextRefreshDate: new Date(memberData?.creditScoreRequest?.refreshDate || dateFallback),
      scoreChangeDirection,
      scoreChangePoints,
      scoreCurrentPoints,
      isLoading,
      isError,
      userFirstName,
    }),
    [
      scoreChangeDirection,
      scorePulledDate,
      scoreChangePoints,
      scoreCurrentPoints,
      isLoading,
      isError,
      userFirstName,
      memberData?.creditScoreRequest?.refreshDate,
    ],
  );
};
