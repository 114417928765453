import { bankAccounts } from "./bankAccounts";
import { creditCards } from "./creditCards";
import { errors } from "./errors";
import { productDetails } from "./productDetails";
import { productList } from "./productList";
import { productVerticalsNavBar } from "./productVerticalsNavBar";
import { quickApply } from "./quickApply";

export const marketplace = {
  bankAccounts,
  creditCards,
  errors,
  productDetails,
  productList,
  productVerticalsNavBar,
  quickApply
};
