import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useMemo } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";

import { SplashScreen } from "@bwll/bw-components/next";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import { useSessionContext } from "@bwll/bw-hooks";
import { determineSessionId } from "@bwll/bw-utils";
import { ErrorReporting } from "@bwll/bw-utils";

import { useAuthRedirectContext } from "./AuthRedirectContextProvider";

interface IProps {
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
  errorComponent?: React.ReactNode;
  isSilentAuth?: boolean;
}

/**
 * This component is used to trigger series of auth initializations and ensures
 * that the user is authenticated before rendering the children components. It
 * also handles setting auth-related values on the session context.
 */
export const AuthCheck = ({ children, loadingComponent, errorComponent, isSilentAuth = false }: IProps) => {
  const {
    removeUser,
    signinRedirect,
    signinSilent,
    user,
    isAuthenticated,
    activeNavigator,
    isLoading,
    events,
    error,
  } = useAuth();
  const { userData, setUserData } = useSessionContext();
  const location = useLocation();
  const { isRedirecting } = useAuthRedirectContext();

  const isUserExpired = useMemo(() => user?.expired || (user?.expires_in || 0) <= 0, [user]);

  const signInUserState = useMemo(() => {
    // Using location hook instead of window.location to respect BrowserRouter's
    // basename configuration.
    return { originLocation: location };
  }, [location]);

  const signinRedirectWithState = useCallback(() => {
    if (isRedirecting) return;
    signinRedirect({ state: signInUserState });
  }, [signinRedirect, signInUserState, isRedirecting]);

  const signinSilentWithState = useCallback(() => {
    if (isRedirecting) return;
    signinSilent({ state: signInUserState });
  }, [signinSilent, signInUserState, isRedirecting]);

  // Trigger the sign-in flow.
  useEffect(() => {
    if (hasAuthParams() || isAuthenticated || activeNavigator || isLoading) {
      // Waiting for oidc-client to be ready for sign-in calls.
      return;
    }

    if (isSilentAuth) {
      // Attempt to signinSilent for silent apps to reconcile the session.
      signinSilentWithState();
    } else {
      // Attempt to signinRedirect for the main app to reconcile the session or
      // redirect to the login page.
      signinRedirectWithState();
    }
  }, [
    isSilentAuth,
    activeNavigator,
    isAuthenticated,
    isLoading,
    signinSilentWithState,
    signinRedirectWithState,
  ]);

  const handleSignOut = useCallback(() => {
    removeUser();
    signinRedirectWithState();
  }, [removeUser, signinRedirectWithState]);

  useEffect(() => {
    if (isSilentAuth) {
      return;
    }

    // events.* returns a cleanup function
    const cleanupFunctions: (() => void)[] = [];
    cleanupFunctions.push(events.addAccessTokenExpired(handleSignOut));
    cleanupFunctions.push(events.addUserSignedOut(handleSignOut));

    return () => {
      cleanupFunctions.forEach((removeCallback) => removeCallback());
    };
  }, [isSilentAuth, events, handleSignOut]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (activeNavigator !== "signinSilent") {
      // Safeguard #1: If there's an error that's not related to signinSilent,
      // attempt to signinSilent for all apps to reconcile the session.
      signinSilentWithState();
      return;
    }
    if (!isSilentAuth) {
      // Safeguard #2: If there's an error with signinSilent, attempt to
      // signinRedirect on the main app.
      signinRedirectWithState();
      return;
    }
  }, [error, isSilentAuth, activeNavigator, signinSilentWithState, signinRedirectWithState]);

  useEffect(() => {
    // If the user is not authenticated or the session is expired, we don't
    // need to set the user data on the session context.
    if (!user?.access_token || isUserExpired) {
      return;
    }

    const { access_token: accessToken, expires_in: expiresIn, state } = user;
    const { individualClientId } = jwtDecode(accessToken);

    // Store user data in session context to allow other components to access it
    // through useSessionContext hook.
    setUserData({
      accessToken,
      expiresIn,
      sessionId: determineSessionId(accessToken),
      state,
    });

    // Set individualClientId for error reporting
    ErrorReporting.identify(individualClientId);
  }, [user, isUserExpired, setUserData]);

  // We don't want to render anything if the session is loading or expired
  // and we're doing silent auth at the same time (via the navigation bar).
  if (isSilentAuth && (isLoading || isUserExpired)) {
    return <></>;
  }

  if (isLoading || !userData?.accessToken) {
    return loadingComponent ? <>{loadingComponent}</> : <SplashScreen image={borrowellLogoWhite} />;
  }

  if (user?.expired || error) {
    return errorComponent ? <>{errorComponent}</> : <SplashScreen image={borrowellLogoWhite} />;
  }

  return <>{children}</>;
};
