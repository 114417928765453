import { ReactNode, createContext, useContext, useState } from "react";

import { noop } from "@bwll/bw-utils";

type AuthRedirectContextState = {
  isRedirecting: boolean;
  setIsRedirecting: (isRedirecting: boolean) => void;
};

export const AuthRedirectContext = createContext<AuthRedirectContextState>({
  isRedirecting: false,
  setIsRedirecting: noop,
});

/**
 * This component and the isRedirecting state is used to prevent custom
 * removeUser + redirect logic at PreMemberCheck from re-triggering
 * signinRedirect and signinSilent by the OIDC client automatically after
 * removeUser getting called.
 */
export const AuthRedirectContextProvider = ({ children }: { children: ReactNode }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  return (
    <AuthRedirectContext.Provider value={{ isRedirecting, setIsRedirecting }}>
      {children}
    </AuthRedirectContext.Provider>
  );
};

export const useAuthRedirectContext = () => {
  return useContext(AuthRedirectContext);
};
