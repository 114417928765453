import styled, { css } from "@emotion/native";

import { COLORS, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const StyledFilterContainer = styled.View`
  display: flex;
  flex-direction: column;
`;

export const StyledFilterChipContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${styledValue(spacing.xxs)} ${styledValue(spacing.m)};
`;

export const StyledPillContainer = styled.View`
  margin: ${styledValue(spacing.xxxs)};
`;

export const filterNameStyle = css`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  margin: ${styledValue(spacing.xxs)} ${styledValue(spacing.m)};
`;

export const subtitleStyle = css`
  margin: auto;
  color: ${COLORS.NEUTRAL.WARM["800"]};
`;
