import { useMemo } from "react";

import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";

import { useExperimentStoreContext } from "../../contexts";

const treatments = {
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].OFF]: {
    showSimilarOffers: false,
    showCompareCta: false,
    showLearnMoreExpandable: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].V1]: {
    showSimilarOffers: false,
    showCompareCta: false,
    showLearnMoreExpandable: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].V2]: {
    showSimilarOffers: true,
    showCompareCta: false,
    showLearnMoreExpandable: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].V3]: {
    showSimilarOffers: true,
    showCompareCta: true,
    showLearnMoreExpandable: false,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].V4]: {
    showSimilarOffers: true,
    showCompareCta: false,
    showLearnMoreExpandable: true,
  },
  [WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS].V5]: {
    showSimilarOffers: true,
    showCompareCta: true,
    showLearnMoreExpandable: true,
  },
};

/**
 * Returns the UI toggles for a member based on their treatment for the Split flag "sspaMarketplaceCreditCardSimilarOffers".
 */
export const useSimilarOffersExperiment = () => {
  const { experimentsMap } = useExperimentStoreContext();

  return useMemo(() => {
    const treatment =
      experimentsMap.userConfig?.[WEB_EXPERIMENT_IDS.SSPA_MARKETPLACE_CREDIT_CARD_SIMILAR_OFFERS]
        ?.treatment ?? "off";

    return (
      treatments[treatment] ?? {
        showSimilarOffers: false,
        showCompareCta: false,
        showLearnMoreExpandable: false,
      }
    );
  }, [experimentsMap.userConfig]);
};
