export const creditScoreScreen = {
  titleLine1: "Your Equifax",
  titleLine2: "Credit Report",
  title: "Your Equifax Credit Report",
  R: "®",
  ers: "Your credit score (ERS 2.0) is provided by EQUIFAX®",
  noHistory: "This is where \n your credit score \n history will appear.",
  tooltipTitle: "About your score",
  tooltip:
    "Your credit score was calculated by Equifax and is called the Equifax Risk Score 2.0 (ERS 2.0).\n\nYour score can range from 300 to 900.\n\nERS 2.0 (the Equifax Risk Score) is based on Equifax's proprietary model and may not be the same score used by third parties to assess your creditworthiness.\n\nThe provision of this score to you is intended for your own educational use.\n\nThird parties will take into consideration other information in addition to a credit score when evaluating your creditworthiness.",
  tooltipFooter:
    "Your credit score will be updated every month. We'll send you an email when your new score is ready!",
  tooltipEquifax: "Powered By",
  creditReportTitle: "See your full credit report.",
  creditReportLink: "Click here",
  recommendationsTitle: "Try our improved product recommendations engine.",
  recommendationsLink: "View recommendations",
  creationDate: "File creation date",
  nextUpdateDate: "Next Update",
  yourEquifaxReport: "Your Equifax Credit Report",
  aboutReport: "About your report",
  creditScore: "Credit Score",
  reportLastPulled: "Report last pulled",
  reengagingMemberBannerMessage:
    "We’re currently updating your credit score and this might take some time. Here’s your credit report from {{date}}",
  downloadCreditReport: "DOWNLOAD CREDIT REPORT",
  creditReportFileName: "credit_report",
};
