export const PROJECT_BUILD_NUMBER: { [key: string]: string } = {};

export const PROJECT_VERSION_URL_MAPPING: { [key: string]: string } = {
  marketplace: "/marketplace/version.json",
  dashboard: "/dashboard/version.json",
  "rent-advantage": "/rent-advantage/version.json",
};

export const SENTRY_PROJECT_DSN = {
  marketplace: "https://32bb8e153be576f440d1e26a0acea324@o365004.ingest.us.sentry.io/4507453419814912",
  dashboard: "https://1f13dc77fe44644c492fdbe7fe039ceb@o365004.ingest.us.sentry.io/4507453420929024",
  "rent-advantage": "https://fa70a6070904ae53194234f18d86f2e9@o365004.ingest.us.sentry.io/4507453422764032",
};
