import React, { useCallback } from "react";

import { Body2 } from "@bwll/bw-components/next";
import { COLORS } from "@bwll/bw-styles";

import { useInfoTabs } from "../../InfoTabs.provider";
import { InfoTabsChildProps } from "../../InfoTabs.types";
import * as Styled from "./Tab.styles";

export const Tab = ({ children, tabID, testID }: InfoTabsChildProps) => {
  const { activeTabID, changeTab } = useInfoTabs();

  const handleTabPress = useCallback(() => {
    changeTab(tabID);
  }, [changeTab, tabID]);

  const isActive = activeTabID === tabID;

  return (
    <Styled.Button onPress={handleTabPress} testID={testID} isActive={isActive}>
      <Body2 color={isActive ? COLORS.BLACK : COLORS.PRIMARY["500"]} testID={`${tabID}`} variant="semi-bold">
        {children}
      </Body2>
    </Styled.Button>
  );
};
