import styled from "@emotion/native";
import { Platform, Pressable, Text } from "react-native";

import { COLORS, fontSize, fontWeights, fonts, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

const getBackgroundColor = (isActive: boolean, isPressedOrHovered: boolean) => {
  return isPressedOrHovered ? COLORS.NEUTRAL.COOL["050"] : isActive ? COLORS.PRIMARY.DEFAULT : COLORS.WHITE;
};

export const StyledPillContainer = styled(Pressable)<{ isActive: boolean; isPressedOrHovered: boolean }>`
  height: ${styledValue(spacing.xl)};
  background-color: ${({ isActive, isPressedOrHovered }) => getBackgroundColor(isActive, isPressedOrHovered)};
  border-width: ${styledValue(1)};
  border-color: ${({ isActive }) => (isActive ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL["600"])};
  border-radius: ${styledValue(spacing.xxxl)};
  justify-content: center;
  align-items: center;

  &:focus-visible {
    outline-width: ${styledValue(1)};
    outline-offset: ${styledValue(-4)};
    outline-color: ${COLORS.PRIMARY["800"]};
  }
`;

export const StyledPillText = styled(Text)<{ isActive: boolean }>`
  font-size: ${styledValue(fontSize.xs)};
  font-weight: ${fontWeights.regular};
  font-family: ${Platform.OS === "web" ? "Lato" : fonts.lato.regular};
  color: ${({ isActive }) => (isActive ? COLORS.WHITE : COLORS.NEUTRAL.WARM["700"])};
  letter-spacing: ${styledValue(0.24)};
  line-height: ${styledValue(fontSize.l)};
  padding: 0 ${styledValue(spacing.xxs)};
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
