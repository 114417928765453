import { useCallback } from "react";

import { usePlatformAnalyticAttributes } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, AnalyticsEventCallback, EVENT_LABEL } from "@bwll/bw-types";
import { timestampISO8601 } from "@bwll/bw-utils";

const defaultViewedEventArgs = {
  cardTitle: "",
  cardDescription: "",
  cardCtaCopy: "",
  impressionId: "",
  position: "",
};

const defaultClickedEventArgs = {
  ...defaultViewedEventArgs,
  destination: "",
};

export const usePreselectionAnalyticEvents = () => {
  const { platformAnalyticsAttributes } = usePlatformAnalyticAttributes();

  const createPreselectionViewedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ cardTitle, cardDescription, cardCtaCopy, impressionId, position } = defaultViewedEventArgs) => [
      EVENT_LABEL.PROMO_CARD_VIEWED,
      {
        ...platformAnalyticsAttributes,
        [ANALYTICS_ATTRIBUTES.POSITION]: position,
        [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: cardTitle,
        [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: cardDescription,
        [ANALYTICS_ATTRIBUTES.CALL_TO_ACTION]: cardCtaCopy,
        [ANALYTICS_ATTRIBUTES.IMPRESSION_ID]: impressionId || timestampISO8601(),
      },
    ],
    [platformAnalyticsAttributes],
  );

  const createPreselectionClickedAnalyticEvent = useCallback<AnalyticsEventCallback>(
    ({ cardTitle, cardDescription, cardCtaCopy, impressionId, position, url } = defaultClickedEventArgs) => [
      EVENT_LABEL.PROMO_CARD_VIEWED,
      {
        ...platformAnalyticsAttributes,
        [ANALYTICS_ATTRIBUTES.POSITION]: position,
        [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: cardTitle,
        [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: cardDescription,
        [ANALYTICS_ATTRIBUTES.CALL_TO_ACTION]: cardCtaCopy,
        [ANALYTICS_ATTRIBUTES.IMPRESSION_ID]: impressionId || timestampISO8601(),
        [ANALYTICS_ATTRIBUTES.DESTINATION]: url,
      },
    ],
    [platformAnalyticsAttributes],
  );

  return {
    createPreselectionViewedAnalyticEvent,
    createPreselectionClickedAnalyticEvent,
  };
};
