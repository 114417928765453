import { useCallback } from "react";

import {
  IMPRESSION_EVENT_NAMES,
  ON_LINK_CLICK_EVENT_NAMES,
  TFeatureAnalyticsAttributes,
  useAnalyticsContext,
} from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES } from "@bwll/bw-types";

interface IProps {
  title: string;
  body: string;
  linkText: string;
  enableAnalyticsEvent: boolean;
  analyticsAttributes?: TFeatureAnalyticsAttributes;
  onLinkPress: () => void;
  clickId?: string;
}
type TPromoCardAnalyticsAttributes = {
  [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: string;
  [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: string;
  [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: string;
};
export const usePromoCardAnalytics = ({
  title,
  body,
  linkText,
  analyticsAttributes,
  enableAnalyticsEvent,
  onLinkPress,
  clickId,
}: IProps) => {
  const { onLinkClick, impressionView } = useAnalyticsContext();

  const trackPromoCardLinkClick = useCallback(() => {
    enableAnalyticsEvent &&
      onLinkClick?.<TPromoCardAnalyticsAttributes>({
        eventName: ON_LINK_CLICK_EVENT_NAMES.PROMO_CARD_CLICKED,
        eventAttributes: {
          [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: title,
          [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: body,
          [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: linkText,
          ...(clickId ? { [ANALYTICS_ATTRIBUTES.CLICK_ID]: clickId } : {}),
          ...analyticsAttributes,
        },
      });

    onLinkPress();
  }, [enableAnalyticsEvent, onLinkClick, title, body, linkText, clickId, analyticsAttributes, onLinkPress]);

  const trackPromoCardView = useCallback(() => {
    if (impressionView) {
      impressionView({
        eventName: IMPRESSION_EVENT_NAMES.PROMO_CARD_VIEWED,
        eventAttributes: {
          [ANALYTICS_ATTRIBUTES.PROMO_TEXT_HEADER]: title,
          [ANALYTICS_ATTRIBUTES.PROMO_TEXT_BODY]: body,
          [ANALYTICS_ATTRIBUTES.BUTTON_TEXT]: linkText,
          ...analyticsAttributes,
        },
      });
    }
  }, [impressionView, analyticsAttributes, body, title, linkText]);

  return {
    trackPromoCardLinkClick,
    trackPromoCardView,
  };
};
