import { SignoutRedirectArgs } from "oidc-client-ts";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { MenuLinkProps } from "@bwll/bw-components/next/molecules/MenuLink/MenuLink.types";
import { UniversalNavigationBarPromoProps } from "@bwll/bw-components/next/organisms/UniversalNavigationBar/UniversalNavigationBar.types";
import { useBreakpoints, useIndividualClient, useUserConfigExperiments } from "@bwll/bw-hooks";
import { WEB_EXPERIMENT_IDS, WEB_TREATMENT_IDS } from "@bwll/bw-types";
import { getFirstPreferredName } from "@bwll/bw-utils";

import { useUniversalNavigationBarRules } from "./useUniversalNavigationBarRules";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

export const useUniversalNavigationBarLinks = () => {
  const navigate = useNavigate();
  const i18next = useTranslation();
  const {
    universalNavBar: { isMobileNavBar },
  } = useBreakpoints();
  const { data: experiments } = useUserConfigExperiments();
  const { data: individualClient } = useIndividualClient();
  const { signoutRedirect, removeUser } = useAuth();
  const {
    shouldShowBuildCreditMenu,
    shouldShowCreditBuilder,
    shouldShowRentAdvantage,
    shouldShowCarLoans,
    shouldShowInvestments,
    shouldShowMortgages,
    shouldShowBankAccountRedesign,
    shouldShowMortgagesRedesign,
  } = useUniversalNavigationBarRules();
  const loansExternalWebsitePath = "/#/app/product/loans/personal";

  /**
   * Wrapper that executes before the sign-out process is triggered.
   * @param {SignoutRedirectArgs} args - Optional arguments for sign out redirect.
   * @returns {Promise<void>} - A promise that resolves when the sign-out process is completed successfully.
   */
  const onSignOut = useCallback(
    async (args?: SignoutRedirectArgs) => {
      await removeUser();
      return await signoutRedirect(args);
    },
    [removeUser, signoutRedirect],
  );

  const shouldShowReferAFriend = useMemo(
    () =>
      experiments?.[WEB_EXPERIMENT_IDS.ENABLE_REFER_A_FRIEND]?.treatment !==
      WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.ENABLE_REFER_A_FRIEND].OFF,
    [experiments],
  );

  const shouldRedirectToNewDashboard = useMemo(
    () =>
      experiments?.[WEB_EXPERIMENT_IDS.SSPA_DASHBOARD_TRAFFIC_ALLOCATION]?.treatment ===
        WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_DASHBOARD_TRAFFIC_ALLOCATION].ON &&
      experiments?.[WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_WEB]?.treatment ===
        WEB_TREATMENT_IDS[WEB_EXPERIMENT_IDS.SSPA_ENABLE_DASHBOARD_V2_WEB].ON,
    [experiments],
  );

  const navigateWithinSspa = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  const navigateToExternalWebsite = useCallback(
    (path: string, baseUrl: string = ENVIRONMENT_VARIABLES.GEMSTONE_BASE_URL) => {
      window.location.href = `${baseUrl}${path}`;
    },
    [],
  );

  const logo = useMemo(() => {
    const standardLogo = {
      uri: `${ENVIRONMENT_VARIABLES.APP_BASE_URL}/assets/logo.png`,
      width: 150,
      height: 70,
    };

    const smallLogo = {
      uri: `${ENVIRONMENT_VARIABLES.APP_BASE_URL}/assets/logo-small.svg`,
      width: 50,
      height: 40,
    };

    return {
      testID: "borrowell-logo-button",
      // TODO: Create placeholder page on the root path which redirects user to
      // the Angular app.
      onPress: () =>
        shouldRedirectToNewDashboard
          ? navigateWithinSspa("/dashboard")
          : navigateToExternalWebsite("/#/dashboard"),
      image: {
        source: isMobileNavBar ? smallLogo : standardLogo,
      },
    };
  }, [isMobileNavBar, shouldRedirectToNewDashboard, navigateWithinSspa, navigateToExternalWebsite]);

  const buildCredit = {
    testID: "build-credit",
    label: i18next.t("navbar:products:buildCredit"),
    links: [
      shouldShowCreditBuilder && {
        testID: "build-credit-credit-builder",
        label: i18next.t("navbar:products:creditBuilder"),
        icon: "cbl_build",
        highlight: true,
        onPress: () => navigateToExternalWebsite("/#/app/cb"),
      },
      shouldShowRentAdvantage && {
        testID: "build-credit-rent-advantage",
        label: i18next.t("navbar:products:rentAdvantage"),
        icon: "key",
        highlight: true,
        onPress: () => navigateWithinSspa("/rent-advantage"),
      },
    ]
      .filter(Boolean)
      .map((link) => link as MenuLinkProps),
  };

  const recommendations = {
    testID: "recommendations",
    label: i18next.t("navbar:products:recommendations"),
    links: [
      {
        testID: "recommendations-loans",
        label: i18next.t("navbar:products:loans"),
        icon: "loans",
        onPress: () => navigateToExternalWebsite(loansExternalWebsitePath),
      },
      {
        testID: "recommendations-credit-cards",
        label: i18next.t("navbar:products:creditCards"),
        icon: "card",
        onPress: () => navigateWithinSspa(`/marketplace/credit-cards`),
      },
      shouldShowCarLoans && {
        testID: "recommendations-car-loans",
        label: i18next.t("navbar:products:carLoans"),
        icon: "car-loans",
        onPress: () => navigateToExternalWebsite("/#/app/product/carloans"),
      },
      shouldShowMortgages && {
        testID: "recommendations-mortgages",
        label: i18next.t("navbar:products:mortgages"),
        icon: "house",
        onPress: () =>
          shouldShowMortgagesRedesign
            ? navigateWithinSspa(`/marketplace/mortgages`)
            : navigateToExternalWebsite("/#/app/product/mortgages/purchasing"),
      },
      {
        testID: "recommendations-bank-accounts",
        label: i18next.t("navbar:products:bankAccounts"),
        icon: "bank_2",
        onPress: () =>
          shouldShowBankAccountRedesign
            ? navigateWithinSspa(`/marketplace/bank-accounts`)
            : navigateToExternalWebsite("/#/app/product/bankaccounts"),
      },
      {
        testID: "recommendations-insurance",
        label: i18next.t("navbar:products:insurance"),
        icon: "shield_outline",
        onPress: () => navigateToExternalWebsite("/#/app/product/insurance/auto"),
      },
      shouldShowInvestments && {
        testID: "recommendations-investments",
        label: i18next.t("navbar:products:investments"),
        icon: "invest",
        onPress: () => navigateToExternalWebsite("/#/app/product/investments"),
      },
      {
        testID: "recommendations-build-credit",
        label: i18next.t("navbar:products:buildCredit"),
        icon: "score_active",
        onPress: () => navigateToExternalWebsite("/#/app/product/creditbuilding"),
      },
    ]
      .filter(Boolean)
      .map((link) => link as MenuLinkProps),
  };

  const profile = {
    testID: "profile",
    label: getFirstPreferredName(individualClient),
    onPress: () => null,
    links: [
      {
        testID: "profile-profile",
        label: i18next.t("navbar:profile:profile"),
        onPress: () => navigateToExternalWebsite("/#/app/profile"),
      },
      {
        testID: "profile-legal",
        label: i18next.t("navbar:profile:legal"),
        onPress: () => navigateToExternalWebsite("/legal", ENVIRONMENT_VARIABLES.MARKETING_BASE_URL),
      },
      {
        testID: "profile-help",
        label: i18next.t("navbar:profile:help"),
        onPress: () => navigateToExternalWebsite("/hc/en-us", "https://helpcentre.borrowell.com"),
      },
      {
        testID: "profile-logout",
        label: i18next.t("navbar:profile:logout"),
        onPress: onSignOut,
      },
    ],
  };

  const links = [
    {
      testID: "credit-report",
      label: i18next.t("navbar:products:creditReport"),
      onPress: () => navigateToExternalWebsite("/#/app/creditReport"),
    },
    {
      testID: "credit-cards",
      label: i18next.t("navbar:products:creditCards"),
      onPress: () => navigateWithinSspa(`/marketplace/credit-cards`),
    },
    {
      testID: "loans",
      label: i18next.t("navbar:products:loans"),
      onPress: () => navigateToExternalWebsite(loansExternalWebsitePath),
    },
    shouldShowBuildCreditMenu && { ...buildCredit },
    recommendations,
  ]
    .filter(Boolean)
    .map((link) => link as MenuLinkProps);

  const promo = {
    testID: "refer-a-friend",
    label:
      experiments?.[WEB_EXPERIMENT_IDS.ENABLE_REFER_A_FRIEND]?.config?.navButtonCopy ??
      i18next.t("navbar:profile:refer"),
    icon: "gift_box",
    onPress: () => navigateToExternalWebsite("/#/app/referral"),
  } as UniversalNavigationBarPromoProps;

  return {
    logo,
    links,
    profile,
    promo: shouldShowReferAFriend ? promo : undefined,
  };
};
