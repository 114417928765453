import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
  IExceptionTelemetry,
} from "@microsoft/applicationinsights-web";

class AppInsightsService {
  reactPlugin: ReactPlugin | null = null;
  appInsights: ApplicationInsights | null = null;

  init = (connectionString: string) => {
    if (this.appInsights) {
      return;
    }

    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extensions: [this.reactPlugin],
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
      },
    });

    this.appInsights.loadAppInsights();
  };

  setIndividualClientId = (individualClientId: string) => {
    if (this.appInsights !== null && individualClientId !== undefined) {
      this.appInsights.addTelemetryInitializer((item) => {
        if (item.data !== undefined) {
          item.data.individualClientId = individualClientId;
        }
      });
    }
  };

  trackEvent = (event: IEventTelemetry, customProperties?: ICustomProperties) => {
    if (this.appInsights === null) {
      return;
    }

    this.appInsights.trackEvent(event, customProperties);
  };

  trackException = (exception: Error, customProperties?: ICustomProperties) => {
    if (this.appInsights === null) {
      return;
    }

    const exceptionTelemetry: IExceptionTelemetry = { exception };
    this.appInsights.trackException(exceptionTelemetry, customProperties);
  };
}

export const appInsightsService = new AppInsightsService();
