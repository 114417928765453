export const rentReportingCard = {
  "title": "Le Bienfait du Loyer",
  "titleTM": "Le Bienfait du Loyer",
  "reportedDateTitle": "DATE",
  "dateReported": "DATE",
  "reportedAmountTitle": "MONTANT",
  "statusTitle": "STATUT",
  "cta": "VOIR LES DÉTAILS",
  "learnMore": "EN SAVIOR PLUS",
  "amountPlaceholder": "-",
  "bodyTextNotSubscribed": "Report your rent on your Equifax Canada credit report and start building your credit history!",

}
