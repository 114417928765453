import styled from "@emotion/native";

import { spacing } from "@bwll/bw-styles";
import { styledValue as sv } from "@bwll/bw-utils";

import { Cell } from "../../atoms/Cell";
import { Image } from "../../atoms/Image";

export const CardCell = styled(Cell)`
  flex-direction: row;
  gap: ${sv(spacing.xxs)};
`;

export const CardContentContainer = styled.View`
  flex: 1 1 0;
`;

export const CardImage = styled(Image)`
  width: 200px;
  height: 125px;
  align-self: center;
  resize-mode: contain;
`;

export const HorizontalView = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${sv(12)};
`;

export const CardIconContainer = styled.View`
  flex: 0 0 auto;
`;
