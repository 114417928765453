import React from "react";

import { StyledDivider } from "./Divider.styles";
import { DividerProps } from "./Divider.types";

/**
 * Component to add a division line to Cell or other components
 *
 * @component
 * @example
 * return (
 *   <Divider />
 * )
 */
export const Divider = ({ ...props }: DividerProps) => <StyledDivider {...props} />;
