import { createContext, useContext, useMemo, useState } from "react";

import { noop } from "@bwll/bw-utils";
import { chance } from "@bwll/test-utils";

import { WithChildren } from "../../types";
import { ToastProps } from "./Toast.types";
import { ToastStack } from "./ToastStack.component";
import { ToastStackItem } from "./ToastStack.types";

// Contexts
type ToastStackContext = [ToastStackItem[], React.Dispatch<React.SetStateAction<ToastStackItem[]>>];

type ToastStackProviderProps = WithChildren<{
  initialState?: ToastStackItem[];
}>;

export const ToastStackContext = createContext<ToastStackContext>([[], noop]);

// API
/**
 * Provides the ToastStackContext to its child components and allows universal toasts to be used.
 *
 * Not yet tested for compatibility with mobile.
 */
export const ToastStackProvider = ({ initialState, children }: ToastStackProviderProps) => {
  const state = useState(initialState ?? []);

  return (
    <ToastStackContext.Provider value={state}>
      <ToastStack>{children}</ToastStack>
    </ToastStackContext.Provider>
  );
};

/**
 * Gets the current ToastStack state and functions to show toasts.
 * @returns The current ToastStack state and functions to show toasts.
 */
export const useToastStack = () => {
  const [toastStack, setToastStack] = useContext(ToastStackContext);

  return useMemo(
    () => ({
      /**
       * The currently shown toasts.
       */
      toasts: toastStack,
      /**
       * Show a new toast in the toast stack.
       * @param toast The toast props.
       * @returns The toast's key.
       */
      addToast: (toast: Omit<ToastProps, "bottomOffset">): string => {
        const key = chance.guid();
        setToastStack((toasts) => [...toasts, { key, ...toast }]);
        return key;
      },
    }),
    [setToastStack, toastStack],
  );
};
