import styled, { css } from "@emotion/native";
import { StyleSheet } from "react-native";

import { COLORS, borderRadius, fontSize, fonts, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { isWeb } from "../../constants";
import { GOOGLE_ADDRESS_POWERED_BY_IMAGE_WIDTH } from "./GoogleAddressInput.constants";

export const InputContainer = styled.View`
  position: relative;
  z-index: ${isWeb ? "unset" : 0};
`;

export const OptionsContainer = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 67px;
  border-bottom-left-radius: ${styledValue(borderRadius.s)};
  border-bottom-right-radius: ${styledValue(borderRadius.s)};
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.PRIMARY.DEFAULT};
  z-index: 1;
`;

export const OptionContainer = styled.Pressable<{ interactive?: boolean }>`
  cursor: ${({ interactive }) => (interactive ? "pointer" : "initial")};
  flex-direction: column;
  padding: ${styledValue(spacing.xxs)};
  padding-bottom: ${styledValue(spacing.none)};
`;

export const Line = styled.View`
  height: 1px;
  background-color: ${COLORS.NEUTRAL.COOL["100"]};
  margin-top: ${styledValue(spacing.xxs)};
`;

export const PoweredByImageContainer = styled.View`
  width: ${styledValue(GOOGLE_ADDRESS_POWERED_BY_IMAGE_WIDTH)};
  padding-bottom: ${styledValue(spacing.xxs)};
`;

export const textInputActiveStyle = css`
  border-radius: ${styledValue(borderRadius.s)};
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

/**
 * Style configuration for react-native-google-places-autocomplete
 * https://www.npmjs.com/package/react-native-google-places-autocomplete#styling
 */
export const mobileStyles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },

  textInputContainer: {
    backgroundColor: "transparent",
    borderRadius: 0,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.xs,
    flex: 1,
    fontSize: fontSize.s,
    fontFamily: fonts.lato.regular,
    marginBottom: 0,
    height: undefined,
    color: COLORS.NEUTRAL.WARM["800"],
  },

  poweredContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: spacing.xs,
  },

  listView: {
    zIndex: 9999,
    position: "absolute",
    top: 67,
    left: 0,
    borderColor: COLORS.PRIMARY.DEFAULT,
    borderWidth: 1,
    borderRadius: borderRadius.s,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  row: {
    padding: 0,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.xs,
    height: undefined,
    backgroundColor: COLORS.WHITE,
  },
});
