import { useCallback, useEffect, useRef, useState } from "react";
import { Animated, Pressable, View } from "react-native";

import { Body1, Icon, IconTag, Image, Link1, MenuLink } from "@bwll/bw-components/next";
import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, spacing } from "@bwll/bw-styles";

import {
  CloseLink,
  Content,
  HamburgerLinkContainer,
  HamburgerLinkLabel,
  HamburgerMenu,
  HamburgerMenuContainer,
  HamburgerMenuOverlay,
  HamburgerMenuPromo,
  Links,
  Menu,
  NavBarContainer,
  NavBarHeightSpacer,
  Subtitle,
  TagLink,
} from "./UniversalNavigationBar.styles";
import { HamburgerLinkProps, UniversalNavigationBarProps } from "./UniversalNavigationBar.types";

const HamburgerLink = ({ testID, label, icon, color, onPress }: HamburgerLinkProps) => {
  const [hovered, setHovered] = useState(false);

  return (
    <HamburgerLinkContainer
      testID={`${testID}-link-mobile`}
      onPress={onPress}
      hovered={hovered}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      {icon && (
        <Icon
          icon={icon}
          size={spacing.xl}
          color={hovered ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL["700"]}
        />
      )}
      <HamburgerLinkLabel color={color} hovered={hovered}>
        {label}
      </HamburgerLinkLabel>
    </HamburgerLinkContainer>
  );
};

/**
 * Used to render the Universal Navigation Bar on the SSPA web apps.
 * The use of this component is currently limited to web.
 */
export const UniversalNavigationBar = ({
  links,
  profile,
  testID,
  placeholder,
  logo,
  promo,
}: UniversalNavigationBarProps) => {
  const {
    universalNavBar: { isMobileNavBar, navBarHeight },
  } = useBreakpoints();
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [currentOverlayOpacity, setCurrentOverlayOpacity] = useState(0);
  const menuAnimationValueRef = useRef(new Animated.Value(0));

  const handleMenuOptionClick = useCallback(
    (onPress: () => void) => () => {
      setIsHamburgerMenuOpen(false);
      onPress();
    },
    [],
  );

  useEffect(() => {
    Animated.timing(menuAnimationValueRef.current, {
      toValue: isHamburgerMenuOpen ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();

    menuAnimationValueRef.current.addListener((value) => {
      setCurrentOverlayOpacity(value.value);
    });
  }, [isHamburgerMenuOpen]);

  return (
    <>
      <NavBarHeightSpacer navBarHeight={navBarHeight} />
      <NavBarContainer testID={testID} navBarHeight={navBarHeight}>
        <Content>
          <Pressable testID={logo.testID} onPress={handleMenuOptionClick(logo.onPress)}>
            <Image {...logo.image} resizeMode="contain" />
          </Pressable>
          {!isMobileNavBar && (
            <Links>
              {links?.map((link, index) => (
                <MenuLink {...link} key={index}>
                  {link.label}
                </MenuLink>
              ))}
            </Links>
          )}
          {isMobileNavBar && !!placeholder && <Body1 testID="borrowell-title-mobile">{placeholder}</Body1>}
          {!isMobileNavBar && promo?.label && (
            <Link1 testID={`${promo.testID}-link`} onPress={handleMenuOptionClick(promo.onPress)}>
              <IconTag
                backgroundColor={COLORS.TURQUOISE["050"]}
                iconColor={COLORS.TURQUOISE["900"]}
                borderColor={COLORS.TURQUOISE["900"]}
                borderRadius={spacing.xxxs}
                icon="gift_box"
              >
                <TagLink>{promo.label}</TagLink>
              </IconTag>
            </Link1>
          )}
          {isMobileNavBar ? (
            <HamburgerMenu
              testID="menu-open-button-mobile"
              onPress={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
            >
              <Icon icon="menu" size={spacing.l} color={COLORS.BLACK} />
            </HamburgerMenu>
          ) : (
            <Menu>{profile?.label && <MenuLink {...profile} isCloseToViewportLimit />}</Menu>
          )}
        </Content>
        <HamburgerMenuOverlay opacity={currentOverlayOpacity} onPress={() => setIsHamburgerMenuOpen(false)} />
        <HamburgerMenuContainer
          style={{
            transform: [
              {
                translateX: menuAnimationValueRef.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: ["100%", "0%"],
                }),
              },
            ],
          }}
        >
          <CloseLink testID={`menu-close-button-mobile`} onPress={() => setIsHamburgerMenuOpen(false)}>
            <Icon icon="close" size={spacing.l} color={COLORS.BLACK} />
          </CloseLink>
          {promo && (
            <HamburgerMenuPromo>
              <HamburgerLink
                testID={`${promo.testID}`}
                color={COLORS.TURQUOISE["900"]}
                label={promo.label}
                onPress={handleMenuOptionClick(promo.onPress)}
              />
            </HamburgerMenuPromo>
          )}
          {links?.map((link, index) => (
            <View key={index}>
              {link?.links ? (
                <>
                  <Subtitle>{link.label}</Subtitle>
                  {link.links.map((subLink, subIndex) => (
                    <HamburgerLink
                      testID={subLink.testID}
                      label={subLink.label}
                      icon={subLink.icon}
                      onPress={handleMenuOptionClick(subLink.onPress)}
                      key={subIndex}
                    />
                  ))}
                </>
              ) : (
                <HamburgerLink
                  testID={link.testID}
                  label={link.label}
                  icon={link.icon}
                  onPress={handleMenuOptionClick(link.onPress)}
                />
              )}
            </View>
          ))}
          {profile?.links && (
            <>
              <Subtitle>{profile.label}</Subtitle>
              {profile.links.map((link, index) => (
                <HamburgerLink
                  testID={link.testID}
                  label={link.label}
                  icon={link.icon}
                  onPress={handleMenuOptionClick(link.onPress)}
                  key={index}
                />
              ))}
            </>
          )}
        </HamburgerMenuContainer>
      </NavBarContainer>
    </>
  );
};
