import {
  BankAccountSortFunction,
  sortBankAccountsByInterestRate,
  sortBankAccountsByMonthlyFee,
  sortBankAccountsByPreferredOrder,
} from "../sorting";

/**
 * Represents {@link BankAccountSortOption} values.
 */
export const BankAccountSortOptions = {
  Recommended: "Recommended",
  Fee: "Fee",
  InterestRate: "InterestRate",
} as const;

export type BankAccountSortOption = keyof typeof BankAccountSortOptions;
export type BankAccountSorter = {
  key: BankAccountSortOption;
  sortFn: BankAccountSortFunction;
  localeKey: string;
};

/**
 * Provides objects that sort Bank Accounts based on their properties.
 */
export const bankAccountSorters: Record<BankAccountSortOption, BankAccountSorter> = {
  [BankAccountSortOptions.Recommended]: {
    key: BankAccountSortOptions.Recommended,
    sortFn: sortBankAccountsByPreferredOrder,
    localeKey: "marketplace:productList:sortAndFilters:sort:recommended",
  },
  [BankAccountSortOptions.Fee]: {
    key: BankAccountSortOptions.Fee,
    sortFn: sortBankAccountsByMonthlyFee,
    localeKey: "marketplace:productList:sortAndFilters:sort:fees",
  },
  [BankAccountSortOptions.InterestRate]: {
    key: BankAccountSortOptions.InterestRate,
    sortFn: sortBankAccountsByInterestRate,
    localeKey: "marketplace:productList:sortAndFilters:sort:shortInterestRate",
  },
};
