import styled, { css } from "@emotion/native";

import { COLORS, borderRadius, fontSize, fontWeights, fonts, spacing } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

import { Cell } from "../../atoms/Cell";
import { Image } from "../../atoms/Image";
import { Body2, Heading2 } from "../../atoms/Typography";

type StyledLayoutProps = { isSnackbar: boolean; isAccordion?: boolean; pressed?: boolean };
type AccordionContentContainerProps = { isOpen: boolean };
type CtaTextProps = { platform: PLATFORM };
type CardImageContainerProps = { backgroundImageUrl?: string; backgroundColor?: string };
type CardImageProps = { width?: string; height?: string };

export const Container = styled.View<StyledLayoutProps>`
  flex-direction: ${({ isSnackbar }) => (isSnackbar ? "row" : "column")};
  justify-content: ${({ isSnackbar }) => (isSnackbar ? "space-between" : "flex-start")};
  align-items: center;
  gap: ${({ isAccordion }) => (isAccordion ? styledValue(spacing.xxs) : styledValue(spacing.none))};
`;

const rowCenter = css`
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.View<StyledLayoutProps>`
  width: ${({ isSnackbar, isAccordion }) => (isAccordion ? "60%" : isSnackbar ? "80%" : "100%")};
`;

export const BadgeContainer = styled.View`
  padding: ${styledValue(spacing.xxxs)} ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(borderRadius.m)};
  background-color: ${COLORS.GREEN["050"]};
  width: max-content;
  align-self: flex-start;
  align-items: center;
`;

export const BadgeText = styled(Body2)`
  color: ${COLORS.GREEN["800"]};
  font-size: ${styledValue(fontSize.xxs)};
  font-weight: ${fontWeights.bold};
  line-height: ${styledValue(spacing.xs)};
  letter-spacing: ${styledValue(0.4)};
`;

export const TitleContainer = styled.View`
  ${rowCenter};
`;

export const BodyContainer = styled.View`
  ${rowCenter};
`;

export const IconContainer = styled.View`
  ${rowCenter};
  margin-right: ${styledValue(spacing.xxs)};
`;

export const TitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-weight: 700;
  font-size: ${styledValue(fontSize.s)};
  line-height: ${styledValue(fontSize.xl)};
`;

export const BodyText = styled(Body2)`
  color: ${COLORS.NEUTRAL.WARM["700"]};
  flex-shrink: 1;
`;

export const CtaContainer = styled.Pressable<StyledLayoutProps>`
  padding-top: ${({ isSnackbar }) => (isSnackbar ? styledValue(spacing.none) : styledValue(spacing.xs))};
  margin-horizontal: ${({ isSnackbar }) => (isSnackbar ? 0 : "auto")};
  width: ${({ isSnackbar }) => (isSnackbar ? "unset" : "100%")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${styledValue(spacing.xxs)};
`;

export const Line = styled.View`
  height: ${styledValue(1)};
  width: 100%;
  background-color: ${COLORS.NEUTRAL.COOL["100"]};
  margin-top: ${styledValue(spacing.xs)};
`;

export const GenericAccordionCtaContainer = styled.View`
  cursor: pointer;
  width: max-content;
`;

export const AccordionCtaContainer = styled.View`
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  width: max-content;
`;

export const AccordionContentContainer = styled.View<AccordionContentContainerProps>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0px")};
  overflow: hidden;
  width: 100%;
  transition: ease-in-out 200ms max-height;
`;

export const CtaText = styled(Body2)<CtaTextProps>`
  font-family: ${({ platform }) => (platform === PLATFORM.WEB_APP ? "unset" : fonts.lato.bold)};
  font-size: ${styledValue(fontSize.xs)};
  font-weight: ${({ platform }) => (platform === PLATFORM.WEB_APP ? fontWeights.bold : fontWeights.regular)};
  text-decoration: none;
  color: ${COLORS.PRIMARY.DEFAULT};
`;

export const CardImageContainer = styled(Cell)<CardImageContainerProps>`
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl ?? "none"});
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.TRANSPARENT};
  background-size: cover;
  background-position: center;
`;

export const CardImage = styled(Image)<CardImageProps>`
  width: ${({ width }) => width ?? "200px"};
  height: ${({ height }) => height ?? "125px"};
  align-self: center;
  resize-mode: contain;
`;
