import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { CreditUpdatesResponse, IApiHookOptions, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getCreditCoachUpdates = async (accessToken: string, apiGatewayUrl: string) => {
  const response: AxiosResponse<CreditUpdatesResponse> = await axios.get(
    `${apiGatewayUrl}/api/credit-coach/v2/credit-updates`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useCreditCoachUpdates = <TReturn = CreditUpdatesResponse>(
  options: IApiHookOptions<CreditUpdatesResponse, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_CREDIT_COACH_UPDATES, accessToken),
    () => getCreditCoachUpdates(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken,
      }),
    },
  );
};
