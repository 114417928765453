import { useEffect, useMemo } from "react";

import { useGetQualifiedCreditBuildingProducts } from "@bwll/bw-hooks";
import { CreditBuildingProducts } from "@bwll/bw-types";

interface CreditBuildingProductsEligibilityStatusResult {
  isRentAdvantageEnabled: boolean;
  isCreditBuildingLoanEnabled: boolean;
}

export const useCreditBuildingProductsEligibility = (
  enabled = false,
): CreditBuildingProductsEligibilityStatusResult => {
  const { data: creditBuildingProducts, refetch } = useGetQualifiedCreditBuildingProducts({ enabled: true });

  useEffect(() => {
    /**
     * This prevents from hiding the rent advantage icon on Tab Navigation after Sign up
     * Logic to refetch the credit building products when the isQualifyLoaded status changes
     */
    if (enabled && creditBuildingProducts?.length === 0) {
      refetch();
    }
  }, [creditBuildingProducts, enabled, refetch]);

  return useMemo(() => {
    const isRentAdvantageEnabled =
      creditBuildingProducts?.includes(CreditBuildingProducts.RentAdvantage) ?? false;
    const isCreditBuildingLoanEnabled =
      creditBuildingProducts?.includes(CreditBuildingProducts.CreditBuildingLoan) ?? false;

    return {
      isRentAdvantageEnabled,
      isCreditBuildingLoanEnabled,
    };
  }, [creditBuildingProducts]);
};
