import styled from "@emotion/native";

import { COLORS, borderRadius, spacing, toRGBAString } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const Container = styled.Pressable<{ isActive: boolean }>`
  border: 1px solid ${({ isActive }) => (isActive ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL["600"])};
  background-color: ${({ isActive }) =>
    isActive ? toRGBAString(COLORS.PRIMARY.DEFAULT, 0.1) : COLORS.WHITE};
  border-radius: ${styledValue(borderRadius.s)};
  padding: ${styledValue(spacing.xs)};
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Column = styled.View``;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const RadioIndicatorContainer = styled.View`
  margin-left: ${styledValue(spacing.xxs)};
`;

export const RadioIndicatorOuter = styled.View<{ isActive: boolean }>`
  width: ${styledValue(spacing.xs)};
  height: ${styledValue(spacing.xs)};
  border-radius: ${styledValue(spacing.xs / 2)};
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ isActive }) => (isActive ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL["600"])};
`;

export const RadioIndicatorInner = styled.View`
  width: ${styledValue(spacing.xxs)};
  height: ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(spacing.xxs / 2)};
  background-color: ${COLORS.PRIMARY.DEFAULT};
`;
