import { ProductVerticalBankAccount } from "@bwll/bw-types";

/**
 * Returns true if provided bank account is a Chequing account.
 * @param param0 The bank account.
 * @returns True if bank account is a Chequing account, false if not.
 */
export const isChequing = ({ productSegments }: ProductVerticalBankAccount) =>
  productSegments[0] === "Chequing";

/**
 * Returns true if provided bank account is a Savings account.
 * @param param0 The bank account.
 * @returns True if bank account is a Savings account, false if not.
 */
export const isSavings = ({ productSegments }: ProductVerticalBankAccount) => productSegments[0] === "Saving";

/**
 * Returns true if the provided bank account has a company icon
 * @param param0 The bank account
 * @returns True if the provided bank account has a company icon
 */
export const companyIconExists = ({ companyIcon }: ProductVerticalBankAccount) => !!companyIcon;

export const getPromoBannerText = ({ cashbackBannerText, bannerText }: ProductVerticalBankAccount) =>
  cashbackBannerText ? cashbackBannerText : bannerText ? bannerText : null;
