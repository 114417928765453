import styled from "@emotion/native";
import { Platform } from "react-native";

import { Caption, Label } from "@bwll/bw-components/next/atoms/Typography";
import { COLORS, borderRadius, borderWidth, fonts, spacing } from "@bwll/bw-styles";

export const StyledTextInputContainer = styled.View(() => ({
  flex: 1,
}));

export const StyledTextInputComponent = styled.TextInput(
  ({ focused, disabled, errored }: { focused: boolean; disabled: boolean; errored: boolean }) => ({
    padding: spacing.xxs,
    minHeight: spacing.xl + spacing.xs,
    fontFamily: Platform.OS === "web" ? `${fonts.lato.regular}, Lato` : fonts.lato.regular,
    borderWidth: borderWidth.small,
    borderRadius: borderRadius.s,
    borderColor: errored ? COLORS.RED[700] : focused ? COLORS.PRIMARY.DEFAULT : COLORS.NEUTRAL.COOL[600],
    backgroundColor: disabled ? COLORS.NEUTRAL.COOL[200] : COLORS.WHITE,
    marginVertical: spacing.xxxs,
    color: disabled ? COLORS.NEUTRAL.COOL[600] : undefined,
    // Disables the default outline styles on the web
    ...Platform.select({
      web: {
        outlineColor: "transparent",
        outlineStyle: "solid",
      },
    }),
  }),
);

export const StyledLabelComponent = styled(Label)(({ errored }: { errored: boolean }) => ({
  color: errored ? COLORS.RED[700] : COLORS.NEUTRAL.WARM["700"],
}));

export const StyledCaptionComponent = styled(Caption)(
  ({ errored, color }: { errored: boolean; color?: string }) => ({
    color: errored ? COLORS.RED[700] : color ?? COLORS.NEUTRAL.WARM["700"],
  }),
);
