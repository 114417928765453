import { COLORS } from "@bwll/bw-styles";

export const SkeletonVariants = {
  rect: "rect",
  circle: "circle",
  rounded: "rounded",
} as const;

export const ANIMATION_DURATION_IN_MS = 1000;
export const ANIMATION_DURATION_OUT_MS = 1000;
export const ANIMATION_OPACITY_IN = 0.4;
export const ANIMATION_OPACITY_OUT = 1;
export const BACKGROUND_COLOR = COLORS.NEUTRAL.COOL["300"];
