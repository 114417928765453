import { ProductVerticalBankAccount } from "@bwll/bw-types";

import { isChequing, isSavings } from "./bankAccountUtils";

/**
 * Represents {@link BankAccountFilterOption} values.
 */
export const BankAccountFilterOptions = {
  Chequing: "Chequing",
  Savings: "Savings",
} as const;

export type BankAccountFilterOption = keyof typeof BankAccountFilterOptions;
export type BankAccountFilterFunction = <TBankAccount extends ProductVerticalBankAccount>(
  bankAccounts: TBankAccount[],
) => TBankAccount[];
export type BankAccountFilterer = {
  key: BankAccountFilterOption;
  filterFn: BankAccountFilterFunction;
  localeKey: string;
};

/**
 * Provides objects that filter Bank Accounts based on their properties.
 */
export const bankAccountFilterers: Record<BankAccountFilterOption, BankAccountFilterer> = {
  [BankAccountFilterOptions.Chequing]: {
    key: BankAccountFilterOptions.Chequing,
    filterFn: (bankAccounts) => bankAccounts.filter(isChequing),
    localeKey: "marketplace:productList:sortAndFilters:filters:bankAccounts:chequing",
  },
  [BankAccountFilterOptions.Savings]: {
    key: BankAccountFilterOptions.Savings,
    filterFn: (bankAccounts) => bankAccounts.filter(isSavings),
    localeKey: "marketplace:productList:sortAndFilters:filters:bankAccounts:savings",
  },
};

/**
 * Filters a set of bank accounts by combining the results of each filter into one list.
 * @param filterers The filterers to apply to the provided bank accounts.
 * @param bankAccounts The bank accounts to filter.
 * @returns The provided set of bank accounts, filtered by the provided filterers.
 */
export const filterBankAccounts = <TBankAccount extends ProductVerticalBankAccount>(
  filterers: BankAccountFilterer[],
  bankAccounts: TBankAccount[],
): TBankAccount[] => {
  if (filterers.length === 0) return bankAccounts;

  const filteredBankAccounts = filterers.reduce(
    (acc: TBankAccount[], { filterFn }) => acc.concat(filterFn(bankAccounts)),
    [],
  );

  return Array.from(new Set(filteredBankAccounts));
};
