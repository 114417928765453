import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, ProductVerticalMortgage, QUERY_KEYS } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

interface MortgagesResponseData {
  productVerticalMortgages: ProductVerticalMortgage[];
}

const getMortgages = async (accessToken: string, baseUrl: string) => {
  const response = await axios.get<MortgagesResponseData>(
    `${baseUrl}/api/product-verticals/products/mortgages`,
    {
      headers: generateApiHeaders(accessToken),
    },
  );

  return response.data;
};

export const useGetMortgages = <TReturn = MortgagesResponseData>(
  options: IApiHookOptions<MortgagesResponseData, TReturn> = {},
) => {
  const { userData } = useSessionContext();
  const { envConfigs } = useEnvironmentContext();

  const { accessToken, expiresIn } = userData;

  return useQuery(
    generateQueryKey(QUERY_KEYS.GET_MORTGAGES, accessToken),
    () => getMortgages(accessToken, envConfigs.API_GATEWAY_URL),
    {
      ...queryBehavior({
        expiresIn,
        ...options,
        enabled: !!accessToken && !!options.enabled,
      }),
    },
  );
};
