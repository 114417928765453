export const all = {
  "buttons": {
    "apply": "APPLY",
    "details": "DETAILS",
    "quickApply": "QUICK APPLY",
    "share": "SHARE",
    "learnMore": "LEARN MORE",
    "viewAll": "VIEW ALL",
    "viewProduct": "VIEW PRODUCT",
  },
  "loadingResults": "Loading results...",
  "pageTotalResults": "{{count}} result",
  "pageTotalResults_plural": "{{count}} results",
  "advertiserDisclosure": {
    "title": "Advertiser Disclosure",
    "content": "How does Borrowell keep providing Credit Reports and Score for Free? We make money by analyzing your credit profile to suggest financial products for you from over 50 different companies. If you take one of our recommendations, that partner pays us a referral fee.\n\nOn most recommendations we show you an approval chance, this is unique to you based on your credit profile and is not affected by the compensation we receive. We also own Refresh Financial, a financial services company that provides credit products. While Borrowell receives compensation from partners, unless a post is clearly marked “Sponsored”, products mentioned in editorial articles and reviews are based on the author’s subjective assessment of their value to readers, not compensation.\n\nOur goal at Borrowell is to present readers with various product choices that will help you achieve your financial goals but our offers do not represent all financial products. The content provided on our site is for information only and is not financial or professional advice. For more details and product information click the View Product link / learn more link.",
    "link": "Advertiser disclosure"
  },
  "offerEndDate": "Offer ends {{date}}",
  "offerChecklist": "Offer checklist",
  "sponsored": "Sponsored",
  "metrics": {
    "amount": "Amount",
    "apr": "APR",
    "aprFrom": "from {{amount}}",
    "term": "Term",
    "months": "{{count}} month",
    "months_plural": "{{count}} months",
    "approvalChance": "Approval chance",
    "learnMore": "Learn More",
    "preSelected": {
      "amount": {
        "default": "Pre-qualified amount",
        "Fig": "Pre-approved amount"
      },
      "apr": {
        "default": "Pre-qualified APR",
        "Fig": "Pre-approved APR"
      }
    }
  },
  "emptyState": {
    "title": "Building your credit is a journey, and we're here to help",
    "description": "Check out our credit building product to kickstart your path to great offers",
    "cardCTAText": "BUILD YOUR CREDIT HISTORY",
    "cardDescription": "Build the payment history and credit mix of your credit report and unlock exclusive offers tailored just for you!",
    "cardLinkText": "Sign up for Credit Builder"
  },
  "errorState": {
    "title": "Oops! Our marketplace needs a moment",
    "description1": "We apologize, but we’re facing difficulties loading this page.",
    "description2": "Our team is actively working on it, but in the meantime, you may find it helpful to try logging out and then logging back in again."
  }
}
