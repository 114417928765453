import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ErrorBoundary } from "@bwll/bw-components/next";
import { initializeLocalization } from "@bwll/bw-locale";
import { SingleSpaCustomProps } from "@bwll/bw-types";
import { appInsightsService } from "@bwll/bw-utils";

import { NavigationBarWrapper } from "./components/NavigationBarWrapper";
import { ENVIRONMENT_VARIABLES } from "./configs/environment-variables";

import { ContextProviders } from "@app/contexts";

const { localizationReady } = initializeLocalization({ interpolation: {} });

const onError = (error: Error, errorInfo: React.ErrorInfo) => {
  if (ENVIRONMENT_VARIABLES.APP_INSIGHTS_CONNECTION_STRING) {
    appInsightsService.init(ENVIRONMENT_VARIABLES.APP_INSIGHTS_CONNECTION_STRING);
    appInsightsService.trackException(error, errorInfo);
  }
};

export const App = ({ queryClient }: Pick<SingleSpaCustomProps, "queryClient">) => {
  localizationReady();

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary
          onError={onError}
          FallbackComponent={<pre>Oops! Our navigation bar needs a moment.</pre>}
        >
          <ContextProviders queryClient={queryClient}>
            <NavigationBarWrapper />
          </ContextProviders>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
