import React from "react";
import { AuthProvider as OidcAuthProvider } from "react-oidc-context";

import {
  IAuthUserManagerConfig,
  useSignInRedirectCallback,
  useUserManager,
} from "@bwll/bw-hooks/src/hooks/authorization";
import { useSetVisitUrlCookie } from "@bwll/bw-hooks/src/hooks/useSetVisitUrlCookie";

import { AuthRedirectContextProvider } from "./AuthRedirectContextProvider";

interface IProps {
  children: React.ReactNode;
  authConfig: IAuthUserManagerConfig;
  cookiesDomain: string;
}
export const AuthProvider = ({ children, authConfig, cookiesDomain }: IProps) => {
  useSetVisitUrlCookie({ cookiesDomain });
  const signInRedirectCallback = useSignInRedirectCallback(authConfig.isSilentAuth);
  const userManager = useUserManager(authConfig);

  return (
    <OidcAuthProvider
      userManager={userManager}
      onSigninCallback={signInRedirectCallback}
      skipSigninCallback={authConfig.isSilentAuth}
    >
      <AuthRedirectContextProvider>{children}</AuthRedirectContextProvider>
    </OidcAuthProvider>
  );
};
