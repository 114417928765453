import styled from "@emotion/native";
import { StyleSheet } from "react-native";

import { COLORS, lineHeight, mq, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { View } from "../../primitives";

type IndicatorsContainerProps = {
  minWidth: number;
  maxWidth: number;
};

const SIDE_PADDING = 64;
const responsivePadding = [0, SIDE_PADDING, SIDE_PADDING];

export const Content = View(
  mq({
    flex: 1,
    paddingLeft: responsivePadding,
    paddingRight: responsivePadding,
  }),
);

export const OffersText = styled.View`
  padding: ${styledValue(spacing.none)};
  height: ${styledValue(lineHeight.xl)};
`;

export const Container = View<{ minHeight: number; carouselWidth?: number; horizontalMargin?: number }>(
  ({ minHeight, carouselWidth, horizontalMargin }) => ({
    width: carouselWidth ?? "100%",
    padding: 0,
    margin: "auto",
    minHeight: minHeight,
    flex: 1,
    marginLeft: horizontalMargin ? -horizontalMargin : 0,
  }),
);

export const CarouselNavigatorContainer = styled.View`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IndicatorsContainer = styled.View<IndicatorsContainerProps>`
  margin: ${spacing.m} 0px;
  min-width: ${({ minWidth }) => styledValue(minWidth, "px")};
  max-width: ${({ maxWidth }) => styledValue(maxWidth, "px")};
`;

export const styles = StyleSheet.create({
  indicatorStyle: {
    width: spacing.xxxs,
    height: spacing.xxxs,
    borderRadius: spacing.xxxs,
    marginHorizontal: spacing.xxxs,
    backgroundColor: COLORS.PRIMARY.DEFAULT,
  },
});

const positionalMargins = {
  left: { marginRight: spacing.xxs },
  middle: { marginLeft: spacing.xxs, marginRight: spacing.xxs },
  right: { marginLeft: spacing.xxs },
};

export const ItemWrapper = View<{
  cardPosition: "left" | "middle" | "right";
  width: number;
  marginLeft?: number;
  marginRight?: number;
}>(({ cardPosition, width, marginLeft, marginRight }) => ({
  width,
  ...positionalMargins[cardPosition],
  ...(marginLeft && { marginLeft: marginLeft }),
  ...(marginRight && { marginRight: marginRight }),
  display: "flex",
  flex: 1,
}));

const ARROW_SIZE = 48;

export const Arrow = styled.Pressable<{
  position: "left" | "right";
  isEnabled: boolean;
  color: string;
}>(({ position, isEnabled, color }) => ({
  position: "absolute",
  border: `1.5px solid ${color}`,
  borderRadius: ARROW_SIZE / 2,
  padding: 3.5,
  width: ARROW_SIZE,
  height: ARROW_SIZE,
  bottom: "50%",
  display: isEnabled ? "flex" : "none",
  ...(position === "right"
    ? {
        right: 0,
      }
    : {
        left: 0,
      }),
  transform: [{ translateY: ARROW_SIZE / 2 }],
}));
