export const historicalLandingPage = {
  maintenanceScreen: {
    ctaButton: "COMING BACK SOON",
    message: "We will be accepting new sign-ups very soon!",
  },
  hero: {
    title: "Build credit history with past rent payments!",
    subTitle: "Report up to 24 months of rent in one go!",
    tertiaryTitle: "~~$99~~ $59 Early bird pricing (Limited time only)",
    ctaButton: "START BUILDING CREDIT",
  },
  howSection: {
    title: "How does it work?",
    content: [
      {
        title: "Sign up",
        description: "Sign up with a flat one time fee.",
      },
      {
        title: "Confirm rental payments",
        description:
        "Upload proof of your past rent payments by submitting your lease and bank statements or rental receipts.",
      },
      {
        title: "Build your credit history with Equifax Canada",
        description: "Our team will verify your documents and report your rental payments to Equifax.",
      },
      {
        title: "Watch your credit grow",
        description:
          "If you don’t see a credit score increase after uploading at least 12 months of past rent payments, we’ll give you your money back!¹",
      },
    ],
  },
  whySection: {
    title: "Why is good credit history important?",
    content: [
      {
        title: "Better interest rates",
        description:
          "Good credit history can help you get lower rates which will save you money in the long run.",
      },
      {
        title: "Qualify for more financial products",
        description: "Get access to better credit cards with higher rewards.",
      },
    ],
  },
  faqSection: {
    title: "Frequently asked questions",
    ctaButton: "VIEW ALL FAQS",
    content: [
      {
        title: "What are past rental payments?",
        description:
          "We've partnered with Equifax Canada, one of the two major credit bureaus in Canada to launch past rental payments, which is a credit building product that allows tenants to report their past rental payments from up-to 2 years ago to Equifax Canada without landlord approval.",
      },
      {
        title: "What are the required criteria for my lease agreement and proof of rent payments?",
        description:
          "The team will consider the following criteria when reviewing your lease agreement and proof of rental payments:\n" +
          "\n" +
          "**Lease agreement** - the document must include:\n" +
          "\n" +
          "  - Name of parties (tenant & landlord)\n" +
          "  - Address\n" +
          "  - Lease start date/term\n" +
          "  - Rent amount & due date\n" +
          "  - Signatures \n" +
          "\n",
        description2:
          "**Proof of rental payments** - the following 2 document types are accepted and must include at a minimum:\n" +
          "\n" +
          "<u>Bank statements</u>:\n" +
          "  - Amount of rent paid\n" +
          "  - Date rent was paid\n",
        description3:
          "\n" +
          "<u>Rental Receipts</u>:\n" +
          "  - Amount of rent paid\n" +
          "  - Address of the rental property\n" +
          "  - Dates rent was paid\n" +
          "  - Name of person who paid the rent\n" +
          "  - Name of the person or business who received the rent payments\n" +
          "  - Name and signature of the landlord\n" +
          "\n",
        description4:
          "Please note that you must submit a valid lease agreement to get your past rental payments submitted to Equifax. As well, if any of the criteria points are missing, the document will be rejected. We only submit valid proof of rental payments to Equifax and reserve the right to make any inquiries we consider necessary to verify your rent details in accordance with our Rent Advantage Terms and Conditions. ",
      },
      {
        title: "Are there any hidden fees or penalties?",
        description:
          "There are no hidden fees or penalties. Signing up for past rental payments requires only a one-time payment.\n\nOn top of that, if your score doesn't increase, we’ll give you your money back! For complete Money-Back Guarantee terms and conditions click [here](https://borrowell.com/terms-money-back-guarantee).",
      },
      {
        title: "How do past rental payments help build my credit history?",
        description:
          "Reporting your past rental payments can help build your credit history by reporting up to your last 24 months of rent payments to Equifax Canada.\n" +
          "\n" +
          "There are many factors that determine your credit score:\n" +
          "\n" +
          "- [Payment history](https://borrowell.com/blog/on-time-payments): Your payment history is your track record of how successful you’ve been at making credit payments, and is the single biggest factor that makes up your credit score. By making your rent payments on time and having them reported to Equifax Canada, you’re building a solid payment history, signaling to lenders that you can be trusted with credit. Making on-time payments makes up ~35% of your overall credit score.\n" +
          "- [Credit Mix](https://borrowell.com/blog/what-is-a-credit-mix): Your credit mix refers to the various types of credit accounts that make up your credit report. By adding an open tradeline for rent reporting, you’re diversifying the different types of credit on your report, which indicates that you are able to successfully manage multiple credit accounts. The types of credit or tradelines you have on your credit report determines ~10% of your overall credit score.\n" +
          "- [Credit History](https://borrowell.com/blog/how-does-the-length-of-credit-history-affect-credit-score): If you’ve never had a credit product before and this will be the first tradeline on your credit report, then you’ll also be building your credit history. The longer your history with credit, the more trustworthy you’ll be seen by potential lenders when assessing whether or not to lend to you. Credit history makes up ~15% of your credit score.",
      },
      {
        title: "Which credit bureau will my rental payments be reported to?",
        description:
          "We will report your payments to Equifax Canada, one of the largest Canadian credit bureaus.",
      },
      {
        title: "How long will it take for my past rental payments to show up on my credit report?",
        description:
          "After you report past rental payments, it can take 30-90 days for them to show up on your credit report.",
      },
      {
        title: "Do I need to have Rent Advantage to sign up for past rental payments?",
        description: "No, you can sign up for past rental payments without being a Rent Advantage member.",
      },
      {
        title: "What happens if I don’t have the ability to show proof of rent for the full 24 month window?",
        description:
          "That’s okay! To make it as easy as possible, we only report the past rental payments that you submit.",
      },
      {
        title: "How long after sign-up do I have to upload all the required documents? ",
        description:
          "If you are not a Rent Advantage member, we recommend submitting all past rental payments at the same time or immediately after sign-up, as the date range for the last 24 months begins when your past rent payments get reported to Equifax.\n" +
          "\n" +
          "If you are an existing Rent Advantage member, we recommend you submit all past rental payments immediately. The 24 month period (that you can report past payments for) starts from the month Rent Advantage was first reported to Equifax.\n" +
          "\n" +
          "For example: If you have been subscribed to Rent Advantage for 10 months, you can only submit an additional 14 months of past rent payments, as the 24 month period started when you first enrolled in Rent Advantage.\n",
      },
    ],
  },
  footNote:
  "*Reporting past rent payments is not available to Quebec residents at this time.\n\n¹Terms and conditions apply. To be eligible for the Money-Back Guarantee for reporting past payments, (1) you must have reported at least twelve (12) months of your past twenty-four (24) months of rent payments from the time you signed up for past rent payments or when your rental information is submitted by you and verified by Borrowell, whichever is later; and (2) your credit score on your Borrowell credit report must have either remained the same or decreased due to the addition of past rent payments. For complete Money-Back Guarantee terms and conditions visit https://borrowell.com/terms-money-back-guarantee.",
  footerCtaButton: "START BUILDING CREDIT",
};
