import { ThemeProvider } from "@emotion/react";
import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";

import { SplashScreen } from "@bwll/bw-components/next";
import { AuthCheck, AuthProvider } from "@bwll/bw-components/next/contexts/authorization";
import borrowellLogoWhite from "@bwll/bw-components/src/assets/borrowellLogoWhite.png";
import {
  ENV_TYPE,
  EnvironmentProvider,
  ExperimentStoreProvider,
  PlatformContextProvider,
  SessionProvider,
} from "@bwll/bw-hooks";
import { baseTheme } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";

import { ContextProvidersProps } from "./ContextProviders.types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

const environment = ENVIRONMENT_VARIABLES.ENVIRONMENT as ENV_TYPE;

const authConfig = {
  authority: ENVIRONMENT_VARIABLES.IDENTITY_AUTHORITY,
  clientId: ENVIRONMENT_VARIABLES.OIDC_CLIENT,
  redirectUriPath: `/${window?.location?.pathname?.split("/")?.filter(Boolean)?.[0]}`,
  isSilentAuth: true,
};

export const ContextProviders = ({ children, queryClient }: ContextProvidersProps) => {
  return (
    <AuthProvider authConfig={authConfig} cookiesDomain={ENVIRONMENT_VARIABLES.COOKIES_DOMAIN}>
      <SessionProvider>
        <AuthCheck isSilentAuth={authConfig.isSilentAuth}>
          <PlatformContextProvider platform={PLATFORM.WEB_APP}>
            <EnvironmentProvider environment={environment} queryClient={queryClient}>
              <ThemeProvider theme={baseTheme}>
                <Suspense fallback={<SplashScreen image={borrowellLogoWhite} />}>
                  <ExperimentStoreProvider>
                    <HelmetProvider>{children}</HelmetProvider>
                  </ExperimentStoreProvider>
                </Suspense>
              </ThemeProvider>
            </EnvironmentProvider>
          </PlatformContextProvider>
        </AuthCheck>
      </SessionProvider>
    </AuthProvider>
  );
};
