import styled, { css } from "@emotion/native";

import { COLORS, fontSize, fontWeights, letterSpacing, lineHeight, spacing } from "@bwll/bw-styles";
import { PLATFORM } from "@bwll/bw-types";
import { styledValue } from "@bwll/bw-utils";

import { Image } from "../../atoms/Image";
import { Body2, Heading2 } from "../../atoms/Typography";

type StyledLayoutProps = { platform: PLATFORM; width?: number };

export const CardContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  gap: ${styledValue(spacing.xxs)};
  width: 100%;
`;

const rowCenter = css`
  flex-direction: row;
  align-items: center;
`;

export const ContentContainer = styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ImageContainer = styled.View<StyledLayoutProps>`
  padding: ${({ platform }) => (platform === PLATFORM.WEB_APP ? "10px" : "10px 0")};
`;

export const PromoCardImage = styled(Image)`
  align-self: center;
  height: ${styledValue(80)};
  width: ${styledValue(80)};
`;

export const TitleContainer = styled.View`
  ${rowCenter};
  padding-bottom: ${styledValue(spacing.xxxs)};
`;

export const BodyContainer = styled.View`
  ${rowCenter};
  flex-wrap: wrap;
`;

export const TitleText = styled(Heading2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-weight: ${fontWeights.bold};
  font-size: ${styledValue(fontSize.m)};
  line-height: ${styledValue(lineHeight.xl)};
`;

export const BodyText = styled(Body2)`
  color: ${COLORS.NEUTRAL.WARM["700"]};
  font-weight: ${fontWeights.regular};
  font-size: ${styledValue(fontSize.xs)};
  line-height: ${styledValue(lineHeight.m)};
  letter-spacing: ${styledValue(0.24)};
  padding-bottom: ${styledValue(spacing.xs)};
  max-width: 100%;
`;

export const CtaContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${styledValue(8)};
  cursor: pointer;
  width: fit-content;
`;

export const CtaLink = styled(Body2)<StyledLayoutProps>`
  color: ${COLORS.PRIMARY["500"]};
  font-weight: ${fontWeights.bold};
  font-size: ${styledValue(fontSize.xs)};
  width: ${({ width }) => (width && width < 365 ? "min-content" : "fit-content")};
  line-height: ${styledValue(lineHeight.xs)};
  letter-spacing: ${styledValue(letterSpacing.xxl)};
`;
