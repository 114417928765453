import { useMemo } from "react";

import { AnalyticsEventProps, TFeatureAnalyticsAttributes } from "@bwll/bw-hooks";
import { ANALYTICS_ATTRIBUTES, RENT_REPORTING_VERSION } from "@bwll/bw-types";

const eventCommonAttributes = {
  [ANALYTICS_ATTRIBUTES.VERSION]: RENT_REPORTING_VERSION.V1,
};

export const useRentAdvantageWidgetTrackEvents = ({ trackEvent, commonAttributes }: AnalyticsEventProps) => {
  return useMemo(
    () => ({
      track: <T>(eventLabel: string, eventAttributes: TFeatureAnalyticsAttributes<T>) => {
        const customAttributes = {
          ...commonAttributes,
          ...eventAttributes,
          ...eventCommonAttributes,
        };

        trackEvent(eventLabel, customAttributes);
      },
    }),
    [trackEvent, commonAttributes],
  );
};
