import { useCallback, useEffect, useRef, useState } from "react";
import { Animated } from "react-native";

import { COLORS } from "@bwll/bw-styles";

import { Icon } from "../../atoms/Icon";
import { IconButton } from "../../atoms/IconButton";
import { TOAST_TEST_IDS as TEST_IDS, TOAST_DEFAULTS } from "./Toast.constants";
import * as Styled from "./Toast.styles";
import { ToastProps } from "./Toast.types";

export const Toast = ({
  alignment = "center",
  icon,
  text,
  width = TOAST_DEFAULTS.WIDTH,
  bottomOffset = 0,
  closeAfter = TOAST_DEFAULTS.CLOSE_AFTER_MS,
  onClose,
}: ToastProps) => {
  // Animation
  const [isDisplayed, setIsDisplayed] = useState<boolean>(true);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  // Handlers
  const handleClose = useCallback(
    (args: { manual: boolean }) =>
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        setIsDisplayed(false);
        onClose?.(args);
      }),
    [fadeAnim, onClose],
  );

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
    }).start();

    const timer = setTimeout(() => handleClose?.({ manual: false }), closeAfter);

    return () => clearTimeout(timer);
  }, [closeAfter, fadeAnim, handleClose]);

  return (
    <Styled.ToastContainer
      alignment={alignment}
      bottomOffset={bottomOffset}
      width={width}
      visible={isDisplayed}
      style={{
        opacity: fadeAnim,
      }}
    >
      {icon && <Icon icon={icon} size={20} color={COLORS.NEUTRAL.WARM["800"]} />}
      <Styled.ToastText>{text}</Styled.ToastText>
      <IconButton
        icon="close"
        size={20}
        color={COLORS.NEUTRAL.WARM["800"]}
        onPress={() => handleClose({ manual: true })}
        testID={TEST_IDS.CLOSE_BUTTON}
      />
    </Styled.ToastContainer>
  );
};
