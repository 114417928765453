import axios from "axios";
import { useQuery } from "react-query";

import { useEnvironmentContext, useSessionContext } from "@bwll/bw-hooks";
import { IApiHookOptions, QUERY_KEYS, UserConfiguration } from "@bwll/bw-types";
import { generateApiHeaders, generateQueryKey, queryBehavior } from "@bwll/bw-utils";

const getUserConfigurations = async (accessToken: string, baseUrl: string) => {
  const url = new URL("/api/user-configuration/userconfigurations", baseUrl);

  const response = await axios.get<UserConfiguration>(url.href, {
    headers: generateApiHeaders(accessToken),
  });

  return response.data;
};

/**
 * Sends a GET request to the /api/user-configuration/userconfigurations endpoint.
 * @param options Query configuration options.
 * @returns The UseQuery result.
 */
export const useGetUserConfigurations = <TTransformReturn = UserConfiguration>(
  options: IApiHookOptions<UserConfiguration, TTransformReturn> = {},
) => {
  const {
    userData: { accessToken, expiresIn },
  } = useSessionContext();
  const {
    envConfigs: { API_GATEWAY_URL },
  } = useEnvironmentContext();

  const queryKey = generateQueryKey(QUERY_KEYS.GET_USER_CONFIG_USER_CONFIGURATIONS, accessToken);

  const queryFn = () => getUserConfigurations(accessToken, API_GATEWAY_URL);

  const queryOptions = {
    ...queryBehavior({
      expiresIn,
      ...options,
      enabled: Boolean(accessToken) && options.enabled,
    }),
  };

  return useQuery(queryKey, queryFn, queryOptions);
};
