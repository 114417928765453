import { useEffect, useRef } from "react";

import { useGetUserConfigurations, useIndividualClient, useRefiner } from "@bwll/bw-hooks";
import { UserConfiguration, WEB_EXPERIMENT_IDS } from "@bwll/bw-types";

import { ENVIRONMENT_VARIABLES } from "@app/configs/environment-variables";

/**
 * Gets the list of active surveys from Split that has the 'refinerSurvey' in their name, plus the 'mortgageRenewalSurvey'.
 *
 * @param {UserConfiguration} userConfig - The user configuration object.
 * @returns {object} - Surveys object containing the active surveys and Refiner signature.
 */
const transformUserConfig = (userConfig: UserConfiguration) => {
  return {
    surveyExperiments: userConfig?.experiments
      .filter(
        (item) =>
          item.name.includes(WEB_EXPERIMENT_IDS.REFINER_SURVEY) ||
          item.name.includes(WEB_EXPERIMENT_IDS.MORTGAGE_RENEW_SURVEY),
      )
      .reduce<Record<string, string>>((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {}),
    refinerSignature: userConfig?.userAttributes?.refinerSignature ?? "",
  };
};

/**
 * Sets up the refiner web by initializing the refiner with the given environment ID and identifying the user with the individual client ID and survey targeting data.
 *
 * @param {Function} webRefiner - The global function from refiner-js package.
 */

export const useSetupRefinerWeb = (webRefiner: (...args: unknown[]) => void): void => {
  const refinerEnvId = ENVIRONMENT_VARIABLES.REFINER_ENVIRONMENT_ID;
  const { initializeRefiner, identifyUser } = useRefiner({ webRefiner });
  const { data: individualClient } = useIndividualClient();
  const individualClientId = individualClient?.id ?? "";
  const { data: userConfigData } = useGetUserConfigurations({
    transform: transformUserConfig,
  });

  const hasIdentifyUserBeenCalled = useRef(false);

  useEffect(() => {
    if (hasIdentifyUserBeenCalled.current) return;

    if (refinerEnvId) {
      initializeRefiner(refinerEnvId);
    }

    if (individualClientId && userConfigData) {
      identifyUser({
        individualClientId,
        ...userConfigData?.surveyExperiments,
        refinerSignature: userConfigData?.refinerSignature,
      });
      hasIdentifyUserBeenCalled.current = true;
    }
  }, [
    initializeRefiner,
    userConfigData?.refinerSignature,
    identifyUser,
    refinerEnvId,
    individualClientId,
    userConfigData,
  ]);
};
