import { memo, useCallback, useContext } from "react";

import { Toast } from "./Toast.component";
import { ToastStackContext } from "./ToastStack.context";
import { ToastStackItem, ToastStackProps } from "./ToastStack.types";

const MemoizedToast = memo(
  (props: ToastStackItem) => <Toast {...props} />,
  (prevProps: ToastStackItem, nextProps: ToastStackItem) => prevProps.key === nextProps.key,
);
MemoizedToast.displayName = "MemoizedToast";

export const ToastStack = ({ children }: ToastStackProps) => {
  const [toasts, setToasts] = useContext(ToastStackContext);

  const removeToast = useCallback(
    (key: string) => {
      setToasts((current) => current.filter((t) => t.key !== key));
    },
    [setToasts],
  );

  return (
    <>
      {children}
      {toasts.map((toast, i) => (
        <MemoizedToast
          {...toast}
          key={toast.key}
          bottomOffset={i * 70}
          onClose={(args) => {
            removeToast(toast.key);
            toast.onClose?.(args);
          }}
        />
      ))}
    </>
  );
};
