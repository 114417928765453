import { differenceInMilliseconds, isValid } from "date-fns";

import { Nullable } from "@bwll/bw-types";

export const enum SORTING_ORDER {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export interface IGenericObject {
  [key: string]: string;
}

type PossiblyValidDate = string | number | Date;

export const sortObjectsByDate = <T extends Record<string, unknown>>(
  array: T[],
  dateKey: string,
  sortingOrder: SORTING_ORDER,
): T[] => {
  const copy = [...array];
  const isDescendingOrder = sortingOrder === SORTING_ORDER.DESCENDING;

  copy.sort((a, b) => {
    const dateA = new Date(a[dateKey] as PossiblyValidDate);
    const dateB = new Date(b[dateKey] as PossiblyValidDate);

    if (isValid(dateA) && isValid(dateB)) {
      const firstItem = isDescendingOrder ? dateB : dateA;
      const nextItem = isDescendingOrder ? dateA : dateB;

      return differenceInMilliseconds(firstItem, nextItem);
    }

    return 0;
  });

  return copy;
};

export const sortObjectsByValue = <T extends Record<string, unknown>>(
  array: T[],
  objectValueKey: string,
  sortingOrder: SORTING_ORDER,
): T[] => {
  const copy = [...array];

  copy.sort((a, b) => {
    const isDescendingOrder = sortingOrder === SORTING_ORDER.DESCENDING;
    const valueA = parseInt(a[objectValueKey] as string);
    const valueB = parseInt(b[objectValueKey] as string);

    return isDescendingOrder ? valueB - valueA : valueA - valueB;
  });

  return copy;
};

export const sortByAsc = (a: Nullable<number>, b: Nullable<number>) => {
  if (a === b) {
    return 0;
  }

  if (a === null) {
    return -1;
  }

  if (b === null) {
    return 1;
  }

  return a - b;
};

export const sortByDesc = (a: Nullable<number>, b: Nullable<number>) => {
  if (a === b) {
    return 0;
  }

  if (a === null) {
    return 1;
  }

  if (b === null) {
    return -1;
  }

  return b - a;
};
