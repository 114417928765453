import React from "react";
import { View } from "react-native";

import { useInfoTabs } from "../../InfoTabs.provider";
import { InfoTabsChildProps } from "../../InfoTabs.types";

export const Content = ({ children, tabID, testID }: InfoTabsChildProps) => {
  const { activeTabID } = useInfoTabs();

  const isActive = activeTabID === tabID;

  if (!isActive) {
    return null;
  }

  return <View testID={testID}>{children}</View>;
};
