export const edit = {
  headings: {
    edit: "Edit your personal details",
    identity: "Identity",
    contact: "Contact",
    address: "Home address",
  },
  form: {
    fullNameLabel: "Full name",
    dateOfBirthLabel: "Date of birth",
    phoneNumberLabel: "Phone number",
    emailAddressLabel: "Email address",
    streetAddress1Label: "Street address",
    streetAddress2Label: "Unit number (Optional)",
    cityLabel: "City",
    regionLabel: "Province/Territory",
    postalCodeLabel: "Postal code",
  },
  buttons: {
    updateProfile: "Update Profile",
    discardChanges: "Discard changes",
  },
};
