import { bankingAccountSelectionPage } from "./bankingAccountSelectionPage";
import { checkoutConfirmationPage } from "./checkoutConfirmationPage";
import { checkoutSummaryPage } from "./checkoutSummaryPage";
import { confirmRentTransaction } from "./confirmRentTransaction";
import { customerPortal } from "./customerPortal";
import { editAddressPage } from "./editAddressPage";
import { editRentAmount } from "./editRentAmount";
import { editRentDetails } from "./editRentDetails";
import { errors } from "./errors";
import { formPage } from "./formPage";
import { genericError } from "./genericError";
import { historicalConfirmationPage } from "./historicalConfirmationPage";
import { historicalLandingPage } from "./historicalLandingPage";
import { impactBanner } from "./impactBanner";
import { landingPage } from "./landingPage";
import { landingPageExternal } from "./landingPageExternal";
import { manualOnboardingComment } from "./manualOnboardingComment";
import { manualOnboardingDetails } from "./manualOnboardingDetails";
import { manualUploadSuccess } from "./manualUploadSuccess";
import { mxConnectBankingPage } from "./mxConnectBankingPage";
import { mxReconnectBankingPage } from "./mxReconnectBankingPage";
import { onboardingStepsPage } from "./onboardingStepsPage";
import { pageMetadata } from "./pageMetadata";
import { paymentProofComments } from "./paymentProofComments";
import { popupCard } from "./popupCard";
import { promoBanner } from "./promoBanner";
import { proofOfPayment } from "./proofOfPayment";
import { rentConfirmationPage } from "./rentConfirmationPage";
import { rentReportingCard } from "./rentReportingCard";
import { reportingStatus } from "./reportingStatus";
import { selectRentPayment } from "./selectRentPayment";
import { servicingDashboard } from "./servicingDashboard";
import { subscriptionStatus } from "./subscriptionStatus";
import { successfulOnboardingPage } from "./successfulOnboardingPage";
import { transactionAmountModal } from "./transactionAmountModal";
import { transactionDetails } from "./transactionDetails";
import { transactionSelectionMethodPage } from "./transactionSelectionMethodPage";

export const rentReporting = {
  rentConfirmationPage,
  bankingAccountSelectionPage,
  checkoutConfirmationPage,
  errors,
  formPage,
  landingPage,
  landingPageExternal,
  onboardingStepsPage,
  servicingDashboard,
  selectRentPayment,
  pageMetadata,
  checkoutSummaryPage,
  reportingStatus,
  rentReportingCard,
  customerPortal,
  genericError,
  mxConnectBankingPage,
  mxReconnectBankingPage,
  successfulOnboardingPage,
  popupCard,
  subscriptionStatus,
  confirmRentTransaction,
  transactionAmountModal,
  editRentAmount,
  editRentDetails,
  promoBanner,
  proofOfPayment,
  transactionDetails,
  manualUploadSuccess,
  editAddressPage,
  transactionSelectionMethodPage,
  paymentProofComments,
  manualOnboardingDetails,
  manualOnboardingComment,
  impactBanner,
  historicalConfirmationPage,
  historicalLandingPage,
};
