export const personalLoans = {
  "title": "Loans",
  "sectionTitle": {
    "personalLoan": "Personal loans",
    "businessLoan": "Business loans"
  },
  "chips": {
    "Personal loan": "Personal loan",
    "Business loan": "Business loan",
    "Line of credit": "Line of credit",
    "Unsecured": "Unsecured",
    "Secured": "Secured"
  },
  "tooltips": {
    "apr": "Annual interest rate applied on a loan."
  },
  "metrics": {
    "apr": " APR"
  },
  "amountPerMonth": "{{ amount }}/mo",
  "suffixes": {
    "approvalChance": " approval chance"
  },
  "preQualifiedBanner": {
    "default": "YOU'RE PRE-QUALIFIED!",
    "Fig": "YOU'RE PRE-APPROVED!"
  },
  "preQualifiedPopover": {
    "content": "Based on your credit report and Borrowell account profile information, you’ve been pre-qualified for this loan from {{companyName}}. You meet the qualification criteria and have an excellent chance of being approved for this loan!",
    "title": "You’re pre-qualified!"
  },
  "fig": {
    "header": "Fig Instant Quote",
    "loading": {
      "banner": {
        "title": "Verifying your information",
        "body": "Your Fig loan application is being processed."
      },
      "title": "Processing application...",
      "body": "Please wait - application can take up to 30 seconds. ",
      "cta": "PROCESSING..."
    },
    "error": {
      "title": "We're sorry!",
      "body": "Sorry, Fig is not able to approve you right now based on your credit report. Please contact [support@fig.ca](mailto:support@fig.ca) for more information.",
      "cta": "CLOSE"
    },
    "form": {
      "banner": {
        "title": "Verifying your information",
        "body": "Confirming the below information will help us to ensure we send the correct information to Fig for your loan application."
      },
      "bullet": {
        "one": "Unsecured Personal Loans from 12.9%",
        "two": "Up to $30,000",
        "three": "60 month term",
        "four": "Get approval & terms instantly"
      },
      "consent": "By clicking “Get My Instant Quote”, I consent to Borrowell Inc. sharing my personal information above with Fig Financial Inc. for the purpose of providing a quote and/or pre-populating the application for a Fig personal loan. This will result in a soft inquiry from TransUnion Canada that will not affect your credit score. I acknowledge that Fig may reach out to me directly with information about their products.",
      "cta": "GET MY Instant quote",
      "additionalInformation": {
        "title": "Additional Information",
        "subtitle": "You're one step closer to completing your Fig loan application. We only need a few more details!",
        "income": {
          "inputSubtitle": "Total yearly income before taxes",
          "error": "You must enter a value"
        },
        "accommodation": {
          "title": "Accommodation Details",
          "question": "Do you rent or own your place?",
          "answer": {
            "one": "Rent",
            "two": "Own",
            "three": "Other"
          },
          "error": "You must choose a value"
        },
        "mortgage": {
          "question": "What is your monthly mortgage/rent payment?"
        },
        "timeAtAddress": {
          "question": "When did you start living at your current address?",
          "error": "We are asking this to help verify your identity"
        },
        "error": "Please complete all required fields for your quote."
      }
    },
    "success": {
      "title": "Congrats, {{name}}! You’re approved for up to {{amount}}",
      "title1": "Congrats, {{name}}! You have ",
      "title2": "guaranteed approval",
      "title3": " for up to {{amount}}!",
      "apr": "Your APR with Fig",
      "loanFrom": "From {{amount}}/mo",
      "loanCaption": "Loan amount",
      "unsecured": "Unsecured",
      "loanType": "Loan Type",
      "termLength": "Up to {{amount}} years",
      "termCaption": "Term",
      "footer": "Psst.. you’re already halfway done! Click ‘Get Your Loan Now’ below to finish the last few steps. It’s that easy!",
      "buttons": {
        "apply": "GET YOUR LOAN NOW",
        "applyLater": "APPLY LATER"
      }
    },
    "tooltip": {
      "toolTipTitle": "You’re pre-approved!",
      "toolTipText": "Based on your credit report and Borrowell account profile information, you’ve been pre-approved for this loan from Fig Financial. You meet the qualification criteria and have an excellent chance of being approved for this loan!"
    }
  },
  "specialOfferCard": {
    "title": "Special offer",
    "description": {
      "default": "Apply instantly to your pre-qualified quote",
      "Fig": "Apply instantly to your pre-approved quote"
    },
    "conditions": {
      "from": "from {{percent}}%",
      "apr": "APR",
      "upToMonths": "up to {{count}} month",
      "upToMonths_plural": "up to {{count}} months",
      "months": "{{count}} month",
      "months_plural": "{{count}} months",
      "monthlyPayment": "{{amount}}/mo",
      "upToAmount": "up to {{amount}}"
    },
    "offerDaysLeft": "Hurry, offer ends in {{count}} day!",
    "offerDaysLeft_plural": "Hurry, offer ends in {{count}} days!",
    "buttons": {
      "instantQuote": "GET MY QUOTE NOW",
      "details": "DETAILS",
      "instantApply": "INSTANT APPLY"
    }
  },
  "sections": {
    "personalLoans": "Personal Loans",
    "otherLoans": "Other loan offers for you"
  }
}
