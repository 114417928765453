import styled from "@emotion/native";
import { Animated } from "react-native";

import { Subheading2 } from "@bwll/bw-components/next";
import { COLORS, lineHeight } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

export const ExpandableWrapper = styled(Animated.View)<{ borderEnabled: boolean }>`
  flex: 1 1 100%;
  border-bottom-color: ${COLORS.NEUTRAL.COOL["100"]};
  ${({ borderEnabled }) => borderEnabled && "border-bottom-width: 1px;"}
`;

export const ExpandableHeading = styled(Subheading2)`
  line-height: ${styledValue(lineHeight.xs)};
  letter-spacing: ${styledValue(1.12)};
`;

export const CenteredView = styled.View`
  align-items: center;
`;

export const ExpandableListItemWrapper = styled(Animated.View)`
  flex: 1 1 100%;
`;

export const RowView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
