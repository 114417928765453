import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

import { UNIVERSAL_NAVBAR_HEIGHTS, UNIVERSAL_NAVBAR_MOBILE_BREAKPOINT } from "@bwll/bw-styles";

import { BREAKPOINT_NAMES, Breakpoint, breakpoints } from "./useBreakpoints.constants";

export type Breakpoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  breakpoint: Breakpoint;
  universalNavBar: {
    isMobileNavBar: boolean;
    navBarHeight: number;
  };
};

export const useBreakpoints = (): Breakpoints => {
  const { width } = useWindowDimensions();

  const { name: breakpoint } = useMemo<{ name: Breakpoint }>(
    () =>
      breakpoints.find(
        ({ minWidth, maxWidth }) => (minWidth && width >= minWidth) || (maxWidth && width <= maxWidth),
      ) || { name: BREAKPOINT_NAMES.DESKTOP },
    [width],
  );

  const universalNavBar = useMemo(
    () =>
      width < UNIVERSAL_NAVBAR_MOBILE_BREAKPOINT
        ? { isMobileNavBar: true, navBarHeight: UNIVERSAL_NAVBAR_HEIGHTS.MOBILE }
        : { isMobileNavBar: false, navBarHeight: UNIVERSAL_NAVBAR_HEIGHTS.DESKTOP },
    [width],
  );

  return useMemo(
    () => ({
      isMobile: breakpoint === BREAKPOINT_NAMES.MOBILE,
      isTablet: breakpoint === BREAKPOINT_NAMES.TABLET,
      isDesktop: breakpoint === BREAKPOINT_NAMES.DESKTOP,
      universalNavBar,
      breakpoint,
    }),
    [breakpoint, universalNavBar],
  );
};
