import styled, { css } from "@emotion/native";

import { COLORS, borderRadius, fontSize, fontWeights, spacing } from "@bwll/bw-styles";
import { styledValue } from "@bwll/bw-utils";

import { Button } from "../../atoms/Button";
import { Body2 } from "../../atoms/Typography";

type BadgeContainerProps = { backgroundColor: string };
type BadgeTextProps = { color: string };

const displayFlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.View`
  flex-direction: "column";
  gap: ${styledValue(spacing.xs)};
`;

export const BadgeContainer = styled.View<BadgeContainerProps>`
  align-self: flex-start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${styledValue(spacing.xxxs)} ${styledValue(spacing.xxs)};
  border-radius: ${styledValue(borderRadius.default)};
  ${displayFlexRow}
  align-items: center;
  gap: ${styledValue(spacing.xxxs)};
`;

export const ContentContainer = styled.View`
  ${displayFlexRow}
  gap: ${styledValue(spacing.xs)};
  width: 100%;
`;

export const CtaButton = styled(Button)`
  width: 50%;
`;

export const CtaContainer = styled.View`
  ${displayFlexRow}
  gap: ${styledValue(spacing.xxs)};
  justify-content: center;
`;

export const TextContainer = styled.View`
  flex-shrink: 1;
`;

export const BadgeText = styled(Body2)<BadgeTextProps>`
  color: ${({ color }) => color};
  font-size: ${styledValue(fontSize.xxs)};
  font-weight: ${fontWeights.bold};
  line-height: ${styledValue(spacing.xs)};
  letter-spacing: ${styledValue(0.4)};
`;

export const Title = styled(Body2)`
  color: ${COLORS.NEUTRAL.WARM["800"]};
  font-size: ${styledValue(fontSize.m)};
  font-weight: ${fontWeights.bold};
  line-height: ${styledValue(spacing.m)};
  letter-spacing: ${styledValue(0.16)};
`;

export const Body = styled(Body2)`
  color: ${COLORS.NEUTRAL.COOL["600"]};
  font-size: ${styledValue(fontSize.xxs)};
  font-weight: ${fontWeights.semiBold};
  line-height: ${styledValue(spacing.xs)};
  letter-spacing: ${styledValue(1.12)};
`;
