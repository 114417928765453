import { ImpressionedProductVerticalCreditCard } from "../analytics/productCatalog";
import { ProductVerticalCreditCard } from "./productVerticalCreditCard";

/**
 * Key representation of credit card category.
 */
export const CREDIT_CARD_CATEGORY_KEYS = {
  TOP_PICKS: "topPicks",
  TRAVEL: "travel",
  CASH_BACK: "cashBack",
  REWARDS: "rewards",
  NO_FEE: "noFee",
  PRE_QUALIFIED_OFFERS: "preQualifiedOffers",
  OTHER: "other",
  BALANCE_TRANSFER: "balanceTransfer",
  TRENDING: "trending",
  ALL: "all",
} as const;

/**
 * Key representation of credit card category enum.
 * @see {@link CREDIT_CARD_CATEGORY_KEYS}
 */
export type CreditCardCategoryKey =
  (typeof CREDIT_CARD_CATEGORY_KEYS)[keyof typeof CREDIT_CARD_CATEGORY_KEYS];

/**
 * Key representation of credit card category in the Marketplace app.
 */
export const MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS = {
  BALANCE_TRANSFER: "Balance Transfer",
  CASH_BACK: "Cash Back",
  NO_FEE: "No Fee",
  REWARDS: "Rewards",
  TRAVEL: "Travel",
  TRENDING: "Trending",
} as const;

/**
 * Key representation of credit card category enum in the Marketplace app.
 * @see {@link CREDIT_CARD_CATEGORY_KEYS}
 */
export type MarketplaceCreditCardCategoryKey =
  (typeof MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS)[keyof typeof MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS];

/**
 * Represents a credit card category enum used in Product Verticals API.
 */
export const CREDIT_CARD_CATEGORIES = {
  [CREDIT_CARD_CATEGORY_KEYS.TOP_PICKS]: 0,
  [CREDIT_CARD_CATEGORY_KEYS.TRAVEL]: 1,
  [CREDIT_CARD_CATEGORY_KEYS.CASH_BACK]: 2,
  [CREDIT_CARD_CATEGORY_KEYS.REWARDS]: 3,
  [CREDIT_CARD_CATEGORY_KEYS.NO_FEE]: 4,
  [CREDIT_CARD_CATEGORY_KEYS.PRE_QUALIFIED_OFFERS]: 5,
  [CREDIT_CARD_CATEGORY_KEYS.OTHER]: 6,
  [CREDIT_CARD_CATEGORY_KEYS.BALANCE_TRANSFER]: 7,
  // "All" is not returned by the API, it's a computed category
  [CREDIT_CARD_CATEGORY_KEYS.ALL]: NaN,
} as const;

/**
 * Represents a credit card category enum used in Product Verticals API.
 * @see {@link CREDIT_CARD_CATEGORIES}
 */
export type CreditCardCategory = (typeof CREDIT_CARD_CATEGORIES)[keyof typeof CREDIT_CARD_CATEGORIES];

/**
 * Represents a credit card category from Product Verticals.
 * @see {@link https://bitbucket.org/bwell/bw-product-verticals-ms/src/master/server/src/Gemstone.ProductVerticals.Api/Models/ProductVerticalCreditCardCategoryViewModel.cs}
 */
export interface ProductVerticalCreditCardCategory {
  category: CreditCardCategory;
  creditCards: ProductVerticalCreditCard[];
}

/**
 * Represents a credit card category with CID and impression ID generated.
 */
export interface ImpressionedProductVerticalCreditCardCategory {
  category: CreditCardCategory;
  creditCards: ImpressionedProductVerticalCreditCard[];
}

/**
 * Represents a credit card category pathname.
 */
export const CREDIT_CARD_CATEGORY_PATHNAMES = {
  [CREDIT_CARD_CATEGORY_KEYS.TOP_PICKS]: "top-picks",
  [CREDIT_CARD_CATEGORY_KEYS.TRAVEL]: "travel",
  [CREDIT_CARD_CATEGORY_KEYS.CASH_BACK]: "cash-back",
  [CREDIT_CARD_CATEGORY_KEYS.REWARDS]: "rewards",
  [CREDIT_CARD_CATEGORY_KEYS.NO_FEE]: "no-fee",
  [CREDIT_CARD_CATEGORY_KEYS.PRE_QUALIFIED_OFFERS]: "pre-qualified-offers",
  [CREDIT_CARD_CATEGORY_KEYS.BALANCE_TRANSFER]: "balance-transfer",
  [CREDIT_CARD_CATEGORY_KEYS.TRENDING]: "trending",
  [CREDIT_CARD_CATEGORY_KEYS.OTHER]: "other",
  [CREDIT_CARD_CATEGORY_KEYS.ALL]: "all",
} as const;

/**
 * Represents a credit card category pathname.
 * @see {@link CREDIT_CARD_CATEGORIES_PATHNAME}
 */
export type CreditCardCategoryPathname =
  (typeof CREDIT_CARD_CATEGORY_PATHNAMES)[keyof typeof CREDIT_CARD_CATEGORY_PATHNAMES];

/**
 * Represents credit card categories by their card type values.
 */
export const CREDIT_CARD_CATEGORY_CARDTYPES: Record<string, CreditCardCategoryKey> = {
  Travel: CREDIT_CARD_CATEGORY_KEYS.TRAVEL,
  "Cash Back": CREDIT_CARD_CATEGORY_KEYS.CASH_BACK,
  Rewards: CREDIT_CARD_CATEGORY_KEYS.REWARDS,
  "No fee": CREDIT_CARD_CATEGORY_KEYS.NO_FEE,
  "Balance Transfer": CREDIT_CARD_CATEGORY_KEYS.BALANCE_TRANSFER,
};

/**
 * Represents a credit card category pathname in the Marketplace app.
 */
export const MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES = {
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.BALANCE_TRANSFER]: "balance-transfer",
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.CASH_BACK]: "cash-back",
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.NO_FEE]: "no-fee",
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.REWARDS]: "rewards",
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.TRAVEL]: "travel",
  [MARKETPLACE_CREDIT_CARD_CATEGORY_KEYS.TRENDING]: "trending",
} as const;

/**
 * Represents a credit card category pathname in the Marketplace app.
 * @see {@link MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES}
 */
export type MarketplaceCreditCardCategoryPathname =
  (typeof MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES)[keyof typeof MARKETPLACE_CREDIT_CARD_CATEGORY_PATHNAMES];
