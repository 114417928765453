import React, { Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animated, FlatList, LayoutChangeEvent, ListRenderItem, View } from "react-native";

import { useBreakpoints } from "@bwll/bw-hooks";
import { COLORS, fontSize, spacing } from "@bwll/bw-styles";
import { uuid } from "@bwll/bw-utils";

import { Icon } from "../../atoms/Icon";
import { Indicators } from "../../atoms/Indicators";
import { Spacer } from "../../atoms/Spacer";
import { Body2 } from "../../atoms/Typography";
import { isWeb } from "../../constants";
import {
  HORIZONTAL_CAROUSEL_BUTTON_LEFT,
  HORIZONTAL_CAROUSEL_BUTTON_RIGHT,
  HORIZONTAL_CAROUSEL_INDICATORS,
  HORIZONTAL_CAROUSEL_ITEM_SEPARATOR_WEB,
  HORIZONTAL_CAROUSEL_LABEL,
  HORIZONTAL_CAROUSEL_LIST,
  HORIZONTAL_CAROUSEL_TITLE,
  SCROLL_TO_INDEX_DELAY,
} from "./HorizontalCarousel.constants";
import * as Styled from "./HorizontalCarousel.styles";
import type { HorizontalCarouselProps } from "./HorizontalCarousel.types";
import { useWebStyles } from "./useWebStyles";

const SeparatorComponent = () => <Spacer testID={HORIZONTAL_CAROUSEL_ITEM_SEPARATOR_WEB} width={spacing.s} />;

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

let timeoutId: NodeJS.Timeout;

export const HorizontalCarousel = <T extends object>({
  data,
  renderItem: _renderItem,
  title,
  onChange,
  onPressLeft,
  onPressRight,
  iconName,
  iconColor = COLORS.PRIMARY.DEFAULT,
  iconSize = fontSize.l,
  testID,
  renderLabelText,
}: HorizontalCarouselProps<T>) => {
  const { isMobile } = useBreakpoints();

  /**
   * We need to make sure the HorizontalCarousel has its own unique ID accessor
   * in case we have two instances inside the same viewport
   */
  const idRef = useRef(`${HORIZONTAL_CAROUSEL_LIST}-${uuid()}`);

  useWebStyles(idRef.current);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [listItemWidth, setListItemWidth] = useState(0);

  const hasChangedInitialValue = useRef(false);

  const indicatorsPosition = useRef(new Animated.Value(0));
  const flatListRef = useRef<FlatList<T>>(null);

  const hasMultipleItems = data?.length > 1;

  useEffect(() => {
    if (onChange && data && !hasChangedInitialValue.current) {
      onChange(data[0]);
      hasChangedInitialValue.current = true;
    }
  }, [onChange, data]);

  useEffect(() => {
    /**
     * Fixes react-native-web issue that triggers FlatList to fire onScroll event automatically
     * https://github.com/necolas/react-native-web/issues/1292
     */
    setTimeout(() => {
      if (isWeb) {
        flatListRef.current?.scrollToIndex({ animated: false, index: 0 });
      }
    }, 100);
  }, []);

  const changePrev = useCallback(() => {
    setCurrentIndex((currentValue) => {
      clearTimeout(timeoutId);

      const prevIndex = currentValue - 1;
      const newIndex = prevIndex >= 0 ? prevIndex : data.length - 1;

      onPressLeft?.();

      timeoutId = setTimeout(() => {
        flatListRef.current?.scrollToIndex({ animated: true, index: newIndex, viewPosition: 0.5 });
        onChange?.(data[newIndex]);
      }, SCROLL_TO_INDEX_DELAY);

      return newIndex;
    });
  }, [data, onPressLeft, onChange]);

  const changeNext = useCallback(() => {
    setCurrentIndex((currentValue) => {
      clearTimeout(timeoutId);

      const nextIndex = currentValue + 1;
      const newIndex = nextIndex < data.length ? nextIndex : 0;

      onPressRight?.();

      timeoutId = setTimeout(() => {
        flatListRef.current?.scrollToIndex({ animated: true, index: newIndex, viewPosition: 0.5 });
        onChange?.(data[newIndex]);
      }, SCROLL_TO_INDEX_DELAY);

      return newIndex;
    });
  }, [data, onPressRight, onChange]);

  const getInputRange = useCallback(
    (position: number): number[] => {
      const startInterval = position * listItemWidth;

      return [startInterval - listItemWidth, startInterval, startInterval + listItemWidth];
    },
    [listItemWidth],
  );

  const keyExtractor = useCallback((_: unknown, index: number) => `${index}`, []);

  /**
   * The web implementation of the horizontal FlatList (in react-native-web) injects a <div> wrapper
   * which is not possible to style using @emotion/native. That's why we need to inherit the width of the item
   * by measuring its parent component
   */
  const measureLayout = useCallback((event: LayoutChangeEvent) => {
    if (!event || !event.nativeEvent) {
      return;
    }

    const { nativeEvent } = event;

    const containerWidth = nativeEvent.layout.width;

    setListItemWidth(containerWidth);
  }, []);

  const getItemLayout = useCallback(
    (_: ArrayLike<unknown> | undefined | null, index: number) => ({
      length: listItemWidth,
      offset: listItemWidth * index,
      index,
    }),
    [listItemWidth],
  );

  const handleWebScroll = useMemo(
    () =>
      Animated.event([{ nativeEvent: { contentOffset: { x: indicatorsPosition.current } } }], {
        useNativeDriver: false,
      }),
    [],
  );

  const handleMobileScrollFailed = useCallback(
    (info: { index: number; highestMeasuredFrameIndex: number; averageItemLength: number }) => {
      const wait = new Promise((resolve) => setTimeout(resolve, 500));

      wait.then(() => {
        flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
        setCurrentIndex(info.index);
      });
    },
    [],
  );

  const handleMobileScroll = useMemo(() => {
    return Animated.event([{ nativeEvent: { contentOffset: { x: indicatorsPosition.current } } }], {
      useNativeDriver: false,
      listener: ({
        nativeEvent: {
          contentOffset: { x },
        },
      }: {
        nativeEvent: { contentOffset: { x: number } };
      }) => {
        const index = Math.max(0, Math.round(x / listItemWidth));
        const length = data.length;

        if (length > index) {
          onChange?.(data[index]);
          setCurrentIndex(index);
        }
      },
    });
  }, [onChange, data, listItemWidth]);

  const renderItem: ListRenderItem<T> = useCallback(
    /** We must wrap the item component into container with calculated width */
    (itemData) => (
      <Styled.ItemContainer width={listItemWidth} isMobileApp={!isWeb}>
        {_renderItem(itemData)}
      </Styled.ItemContainer>
    ),
    [_renderItem, listItemWidth],
  );

  return (
    <View testID={testID}>
      <Styled.Row isMobileApp={!isWeb}>
        <Styled.TitleContainer>
          {iconName && <Icon icon={iconName} color={iconColor} size={iconSize} />}
          {title && <Styled.TitleText testID={HORIZONTAL_CAROUSEL_TITLE}>{title}</Styled.TitleText>}
        </Styled.TitleContainer>
        <View>
          {hasMultipleItems && (
            <Body2 color={COLORS.NEUTRAL.WARM["800"]} testID={HORIZONTAL_CAROUSEL_LABEL}>
              {/* The consumer is responsible for rendering text (using i18next) outside of the component */}
              {renderLabelText({ currentItem: currentIndex + 1, totalItems: data.length })}
            </Body2>
          )}
        </View>
      </Styled.Row>
      <Styled.ContentContainer>
        <AnimatedFlatList
          testID={HORIZONTAL_CAROUSEL_LIST}
          contentContainerStyle={Styled.flatListContainerStyles}
          id={idRef.current}
          style={Styled.flatListStyles}
          ref={flatListRef as Ref<FlatList<unknown>>}
          data={data}
          horizontal
          pagingEnabled
          scrollEnabled={!isWeb}
          showsHorizontalScrollIndicator={false}
          onLayout={measureLayout}
          renderItem={renderItem as ListRenderItem<unknown>}
          keyExtractor={keyExtractor}
          onScroll={isWeb ? handleWebScroll : handleMobileScroll}
          onScrollToIndexFailed={isWeb ? undefined : handleMobileScrollFailed}
          /**
           * We hide the Separator component on mobile because:
           * 1. The scroll speed is way faster which makes switching between items visually smooth
           * 2. The `pagingEnabled` with `getItemLayout` includes the separator size
           * which makes the calculation logic difficult and unstable
           */
          ItemSeparatorComponent={isWeb ? SeparatorComponent : null}
          getItemLayout={getItemLayout}
        />
      </Styled.ContentContainer>
      {hasMultipleItems && (
        <Styled.ControlsContainer justifyContent={isMobile && isWeb ? "space-between" : "center"}>
          {isWeb && (
            <Styled.ArrowButton onPress={changePrev} testID={HORIZONTAL_CAROUSEL_BUTTON_LEFT}>
              <Icon icon="back_arrow_thin" color={Styled.CONTROL_ARROW_COLOR} />
            </Styled.ArrowButton>
          )}
          <Styled.IndicatorsContainer>
            <Indicators
              testID={HORIZONTAL_CAROUSEL_INDICATORS}
              numItems={data?.length ?? 0}
              getInputRange={getInputRange}
              currentPosition={indicatorsPosition.current}
              indicatorStyle={Styled.indicatorStyles}
            />
          </Styled.IndicatorsContainer>
          {isWeb && (
            <Styled.ArrowButton onPress={changeNext} testID={HORIZONTAL_CAROUSEL_BUTTON_RIGHT}>
              <Icon icon="forward_arrow_thin" color={Styled.CONTROL_ARROW_COLOR} />
            </Styled.ArrowButton>
          )}
        </Styled.ControlsContainer>
      )}
    </View>
  );
};
