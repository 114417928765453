import { ProductVerticalBankAccount, ProductVerticalCreditCard } from "@bwll/bw-types";

import { sortByAsc, sortByDesc } from "../..";

export type CreditCardSortFunction = <TProduct extends ProductVerticalCreditCard>(
  products: TProduct[],
) => TProduct[];

export type BankAccountSortFunction = <TProduct extends ProductVerticalBankAccount>(
  products: TProduct[],
) => TProduct[];

// Bank Accounts
export const sortBankAccountsByPreferredOrder: BankAccountSortFunction = (bankAccounts) =>
  [...bankAccounts].sort((prev, next) => sortByAsc(prev.preferredOrder, next.preferredOrder));

export const sortBankAccountsByInterestRate: BankAccountSortFunction = (bankAccounts) =>
  [...bankAccounts].sort((prev, next) => sortByDesc(prev.interestRate, next.interestRate));

export const sortBankAccountsByMonthlyFee: BankAccountSortFunction = (bankAccounts) =>
  [...bankAccounts].sort((prev, next) => sortByAsc(prev.monthlyFee, next.monthlyFee));

// Credit Cards
export const sortCreditCardsByPreSelectedOrder: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByAsc(prev.preSelectedOrder, next.preSelectedOrder));

export const sortCreditCardsByLikelihoodOfApproval: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByDesc(prev.likelihoodOfApproval, next.likelihoodOfApproval));

export const sortCreditCardsByPurchaseInterestRate: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByAsc(prev.purchaseInterestRate, next.purchaseInterestRate));

export const sortCreditCardsByAnnualFee: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => {
    const prevFee = prev.isFirstYearWaived ? 0 : prev.annualFee;
    const nextFee = next.isFirstYearWaived ? 0 : next.annualFee;

    return sortByAsc(prevFee, nextFee);
  });

export const sortCreditCardsByFirstYearValue: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByDesc(prev.firstYearValueAmount, next.firstYearValueAmount));

export const sortCreditCardsByPreferredOrder: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByAsc(prev.preferredOrder, next.preferredOrder));

/**
 * Sorts an array of credit cards into a new ordered array, lowest `trendingOrder` first.
 * @param creditCards The credit cards to sort.
 * @returns A new ordered array.
 */
export const sortCreditCardsByTrendingOrder: CreditCardSortFunction = (creditCards) =>
  [...creditCards].sort((prev, next) => sortByAsc(prev.trendingOrder ?? 999, next.trendingOrder ?? 999));
